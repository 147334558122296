// const TOKEN = "";



// TODO: Add validation to validate
// Call API to Django backend
// Exports the API class making it usable in other modules
export class API {
    
    // User Section -----------------------------------------------------------
    static async checkUserExist(body) {
        try {
            const { email = '', username = '' } = body;
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/user/?email='+ email +'&username='+ username, {
                method: 'GET',
            });
            const resp = await response.json();
            return resp;
        } catch (error) {
            console.error(error);
        }
    }

    static async checkToken(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/auth/check_token/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            const resp = await response.json();
            return resp;
        } catch (error) {
            console.error(error);
        }
    }

    static async getToken(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/auth/check_token/?user_id=' + body['user_id'] , {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            })

            const resp = await response.json();
            return resp;
        } catch (error) {
            console.error(error);
        }
    }

    static async fetchToken(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/login/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'  // Specify content incoming is JSON, allow server to handle data easier
                },
                body: JSON.stringify( body )
            });

            const data = await response.json();
            return data;
        } catch (error) {
            console.error(error);
        }
    }

    static logoutUserFunction = async (userToken) => {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/logout/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${userToken}`
                }
            });
            return response;
    
        } catch (error) {
            console.log(error);
        }
    }

    static registerUser(body) {
        // Initiates HTTP requests
        return fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/auth/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'  // Specify content incoming is JSON, allow server to handle data easier
                },
                body: JSON.stringify( body )

            }).then( resp => resp.json()) // Waits for HTTP response to be receive and convert to JSON once response is received
    }

    static googleRegisterUser(body) {
        return fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/auth/googleregister', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify( body )
        }).then( resp => resp.json())
    }

    static async sendRegisterEmail(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/auth/users/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'email': body['email'],
                    'username': body['username'],
                    'password': body['password'],
                    're_password': body['re_password'],
                })
            });
           
            const resp = await response.json();
            return resp;
        } catch (error) {
            console.error(error);
        }
    }

    static async activateAccount(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/auth/users/activation/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'uid': body['uid'],
                    'token': body['token'],
                })
            });

            return response;
        } catch (error) {
            console.error(error);
        }
    }

    static async forgotPassword(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/auth/users/reset_password/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({'email': body['email']})
            });
           
            return response;
        } catch (error) {
            console.error(error);
        }
    }

    static async resetPassword(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/auth/users/reset_password_confirm/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'uid': body['uid'],
                    'token': body['token'],
                    'new_password': body['new_password'],
                    're_new_password': body['re_new_password']
                })
            });
            

            return response;
        } catch (error) {
            console.error(error);
        }
    }

    static async changePassword(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/auth/change_password/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token ' + body['token']
                },
                body: JSON.stringify( body )
            })
            const resp = await response.json();
            return resp;
        } catch (error) {
            console.error(error);
        }
    }

    static async createUser(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/user/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    'email': '',
                    'username': body['username'],
                    'password': '',
                    'first_name': '',
                    'last_name': '',
                })
            })
            const resp = await response.json();
            return resp;
        } catch (error) {
            console.error(error);
        }
    }
    // -------------------------------------------------------------------------


    // Profile Section --------------------------------------------------------
    static async fetchProfile(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/profile', {
                method: 'GET',
                headers: {
                    'Authorization': 'Token ' + body
                }
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error(error);
        }
    }

    static async addProfile(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/profile/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'user_id': body['user_id'] || null,
                    'phone_number': body['phone_number'] || null,
                    'company': body['company'] || null,
                    'company_website': body['company_website'] || null,
                    'company_industry': body['company_industry']?.value ?? null,
                    'country': body['country'] ?? null,
                    'building': body['building'] || null,
                    'related_user': body['related_user'] || null,
                })
            });

            const resp = await response.json()
            return resp;
        } catch (error) {
            console.error(error);
        }
    }

    static async updateProfile(body) {
        console.log(body);

        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/profile/'+body['id'] + '/', {
                method: 'PATCH',
                headers: {
                    'Authorization': 'Token ' + body['token'],
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });

            const resp = await response.json();
            return resp;
        } catch (error) {
            console.error(error);
        }

    }

    static uploadImage(body) { 
        return fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/profile/'+body.get('id') +'/', { 
            method: 'PATCH', 
            headers: { 
                'Authorization': 'Token ' + body.get('token'), 
            }, 
            body: body,
            redirect: 'follow'
        }).then( resp => resp.json() ) 
    }
    // -------------------------------------------------------------------------


    // Secondary Profile Section ---------------------------------------------
    static async fetchSecondaryProfile(body) {
        try {
            const response =  await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/secondary_profile/' + body['user_id'] + '/', {
                method: 'GET',
                headers: {
                    'Authorization': 'Token ' + body['token']
                }
            })

            const resp = await response.json();
            console.log(resp);
            return resp;
        } catch (error) {
            console.error(error);
        }
    }

    static async updateSecondaryProfile(body) {
        try {
            console.log('UPDATE RAN')
            const response =  await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/secondary_profile/' + body['profile_id'] + '/', {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token ' + body['token']
                },
                body: JSON.stringify({
                    'buildings': body['buildings'],
                })
            })
            const resp = await response.json();
            return resp;
        } catch (error) {
            console.error(error);
        }
    }
    // -------------------------------------------------------------------------

    // WABA Section ------------------------------------------------------------
    static wabaFunction(body, token) {
        return fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/add_waba_id/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + token  // Include the user token in the Authorization header
            },  
            body: JSON.stringify(body)
        }).then(resp => resp.json())
    }

    static getPhoneNumbers(wabaId, accessToken) {
        return fetch('https://graph.facebook.com/v19.0/' + wabaId + '/phone_numbers?access_token=' + accessToken, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(resp => resp.json())
    }

    static async getWabaId(userToken) {

        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/get_waba_ids/', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token ' + userToken  // Include the user token in the Authorization header
                }
            })
            const resp = response.json();
            return resp;

        } catch (error) {
            console.error(error); 
            throw error;
        }
    }

    static async getSecondaryWabaId(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/get_secondary_waba_ids/'+body['profile_id']+'/', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token ' + body['token']
                }
            })
            const resp = response.json();
            return resp;
        } catch (error) {
            console.error(error);
        }
    }
    // -------------------------------------------------------------------------


    // Chatflow Section --------------------------------------------------------
    static async getChatFlowList(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/chatflow', {
                method: 'GET',
                headers: {
                    'Authorization': 'Token ' + body
                }
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error(error);
        }
    }

    static async getChatFlow(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/chatflow/' + body['id'] + '/', {
                method: 'GET',
                headers: {
                    'Authorization': 'Token ' + body['token'] 
                }
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error(error);
        }
    }

    static async addChatFlow(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/chatflow/', {
                method: 'POST',
                headers: {
                    'Authorization': 'Token ' + body['token'],
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    chatflow_id: body['chatflow_id'],
                    chatflow_name: body['chatflow_name'],
                    chatflow_type: body['chatflow_type'],
                })
            })
            const resp = await response.json();
            return resp;
        } catch (error) {
            console.error(error);
        }
    }

    static async editChatFlow(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/chatflow/' + body['id'] + '/', {
                method: 'PATCH',
                headers: {
                    'Authorization': 'Token ' + body['token'],
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'chatflow_name': body['chatflow_name'],
                    'checkin_day': body['checkin_day'],
                    'checkout_day': body['checkout_day'],
                    'send_checkin_schedule': body['send_checkin_schedule'],
                    'send_checkout_schedule': body['send_checkout_schedule'],
                })
            });
            const resp = await response.json();
            console.log(resp);
            return resp;
        } catch (error) {
            console.error(error);
        }
    }

    static async updateChatFlow(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/chatflow/' + body['id'] + '/', {
                method: 'PATCH',
                headers: {
                    'Authorization': 'Token ' + body['token'],
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'chatflow_name': body['chatflow_name'],
                    'error_status': body['error_status'],
                    'publish_date': body['publish_date'],
                    'publish_state': body['publish_state'],
                    'chatflow_image': body['chatflow_image'],
                })
            });
            const resp = await response.json();
            console.log(resp);
            return resp;
        } catch (error) {
            console.error(error);
        }
    }

    static updateChatFlowImage(body) { 
        return fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/chatflow/'+body.get('id') +'/', { 
            method: 'PATCH', 
            headers: { 
                'Authorization': 'Token ' + body.get('token'), 
            }, 
            body: body,
            redirect: 'follow'
        }).then( resp => resp.json() ) 
    }

    static async deleteChatFlow(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/chatflow/' + body['id'] + '/', {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Token ' + body['token']
                }
            })
            return response;
        } catch (error) {
            console.error(error);
        }
    }

    static async massFlowAlgorithm(body) {
        try {
            console.log(body);
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/function/massflow_algorithm/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'user_id': body['user_id'],
                    'chatflow_id': body['chatflow_id'],
                    'index': body['index'],
                    'unit': body['unit'],
                    'waba_id': body['waba_id'],
                    'phone_number_id': body['phone_number_id'],
                    'to': body['to_phone_number'],
                    'type': body['type']
                })
            })
            const resp = await response.json();
            console.log(resp);
            return response;
        } catch (error) {
            console.error(error);
        }
    }

    // -------------------------------------------------------------------------


    // Node Section -----------------------------------------------------------
    static async getNodes(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/node?chatflow_id=' + body['id'], {
                method: 'GET',
                headers: {
                    'Authorization': 'Token ' + body['token'],
                }
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error(error);
        }
    }

    static async addNode(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/node/', {
                method: 'POST',
                headers: {
                    'Authorization': 'Token ' + body.get('token'),
                },
                body: body
            });
            const resp = await response.json();
            return resp;
        } catch (error) {
            console.error(error);
        }
    }
    
    static async updateNode(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/node/' + body.get('id') + '/', {
                method: 'PATCH',
                headers: {
                    'Authorization': 'Token ' + body.get('token'),
                },
                body: body
            });
            const resp = await response.json();
            return resp;
        } catch (error) {
            console.error(error);
        }
    }

    static async deleteNode(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/node/' + body['id'] + '/', {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Token ' + body['token']
                }
            })
            return response; // Delete Requests do not return JSON 
        } catch(error) {
            console.error(error);
        }
    }
    // -------------------------------------------------------------------------


    // Edge Section -----------------------------------------------------------
    static async getEdges(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/edge?chatflow_id=' + body['id'], {
                method: 'GET',
                headers: {
                    'Authorization': 'Token ' + body['token'], 
                }
            });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error(error);
        }
    }

    
    static async addEdge(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/edge/', {
                method: 'POST',
                headers: {
                    'Authorization': 'Token ' + body['token'],
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    edge_id: body['edge_id'],
                    source: body['source'],
                    target: body['target'],
                    sourceHandle: body['sourceHandle'],
                    targetHandle: body['targetHandle'],
                    chatflow_id: body['chatflow_id']
                })
            });
            const resp = await response.json();
            return resp;
        } catch(error) {
            console.error(error);
        }
    }

    static async updateEdge(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/edge/' + body['id'] + '/', {
                method: 'PATCH',
                headers: {
                    'Authorization': 'Token ' + body['token'],
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'edge_id': body['edge_id'],
                    'source': body['source'],
                    'target': body['target'],
                    'sourceHandle': body['sourceHandle'],
                    'targetHandle': body['targetHandle'],
                })
            });
            const resp = await response.json();
            return resp;
        }catch(error) {
            console.error(error)
        }
    }

    static async deleteEdge(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/edge/' + body['id'] + '/', {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Token ' + body['token']
                }
            })
            return response; // Delete Requests do not return JSON 
        } catch(error) {
            console.error(error);
        }
    }
    // -------------------------------------------------------------------------

    // Chatroom section ---------------------------------------------------------------

    static async getChatRoom(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/chatrooms/'+ body['user_id'] +',' + body['phone_number'] + ',' + body['waba_id'], {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token ' + body['token'] 
                },
            })
            const resp = await response.json();
            return resp;
        } catch (error) {
            console.error(error);
        }
    }

    static async addChatRoom(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/chatrooms/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token ' + body['token'] 
                },
                body: JSON.stringify({
                    'waba_id': body['waba_id'],
                    'client_user_id': body['client_user_id'],
                    'chatroom_type': body['chatroom_type']
                })
            })
            const resp = await response.json();
            return resp;
        } catch (error) {
            console.error(error);
        }
    }

    static async removeChatRoom(body) {
        try {
            console.log(body)
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/delete_chatroom/'+body['id']+'/', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token ' + body['token'] 
                }
            })
            return response

        } catch (error) {
            console.error(error);
        }
    }


    // ---------------------------------------------------------------------------------

    // Inbox Section -----------------------------------------------------------
    static getUsers(userToken) {
        return fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/users/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + userToken  // Include the user token in the Authorization header
            }
        }).then(resp => resp.json())
    }

    static getChatRooms(userToken, wabaId) {
        return fetch(`https://streamhost-django-80bc172b3b26.herokuapp.com/api/chatrooms2/?phoneNumber=${wabaId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + userToken  // Include the user token in the Authorization header
            }
        }).then(resp => resp.json())
        
    }

    static async getChatRooms3(userToken, wabaId, page, signal) {
        console.log(wabaId, userToken)
        return fetch(`https://streamhost-django-80bc172b3b26.herokuapp.com/api/chatrooms3/?phoneNumber=${wabaId}&page=${page}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + userToken  // Include the user token in the Authorization header
            },
            signal // Add this to enable request cancellation
        }).then(resp => resp.json())
        
    }

    

    static getProfiles(participantId, userToken) {
        return fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/profiles/' + participantId + '/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + userToken  // Include the user token in the Authorization header
            }
        }).then(resp => resp.json())
    }

    static getParticipantName = async (userToken, participantId) => {
        return fetch(`https://streamhost-django-80bc172b3b26.herokuapp.com/api/show_client_user?participant_id=${participantId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${userToken}`
            }
        }).then(resp => resp.json());
    }

    static getChatRoomMessages(chatRoomId, userToken) {
        return fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/chatroom/'+chatRoomId+'/messages/', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${userToken}`  // Include the user token in the Authorization header
            }
        }).then(resp => resp.json())
    }
    
    static getChatRoomMessagesNew(chatRoomId, userToken, page) {
        if (page == null) {
            page = 1; // Default to page 1 if page is null
        }
        return fetch(`https://streamhost-django-80bc172b3b26.herokuapp.com/api/chatroom/${chatRoomId}/messages/?page=${page}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${userToken}`  // Include the user token in the Authorization header
            }
        }).then(resp => resp.json())
    }

    static deleteChatRoom(participantId, userToken) {
        return fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/delete_chatroom/' + participantId, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${userToken}`  // Include the user token in the Authorization header
            }
        }).then(resp => {
            if (resp.ok) {
                return resp.text().then(text => text ? JSON.parse(text) : {}); // Check if the response body is empty
            } else {
                throw new Error('Network response was not ok.');
            }
        });
    }

    static async saveChatRoomMessages(body) {
        console.log(body)
        const resp = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/chatroom/' + body.chatRoomId + '/messages/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + body.userToken
            },
            body: JSON.stringify({
                'message': body.message,
                'client_id': body?.client_id,
                'activePhoneNumber': body.wabaId,
                'wamId': body.wamId,
                'type': body.type,
                'reply_to_id': body.reply_to_id
            })
        });
        
        return await resp.json();
    }

    static updateChatRoomMessages = async (messageId, wamId, userToken) => {
        return fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/chatroom/update_message_wamid/', {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${userToken}`
            },
            body: JSON.stringify({ 
                'wamId': wamId,
                'message_id': messageId
             })
        });
    }

    static isValidWhatsAppNumber = async (userToken, wabaId, phone) => {
        const response = await fetch('https://graph.facebook.com/v19.0/343339532196833/messages', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${userToken}`
            },
            body: JSON.stringify({ to: phone })
        });
    
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        console.log(data);
        return data.isValid; // Assuming the service returns { isValid: true/false }
    };

    static async sendMessageToWhatsapp(body) {
        console.log(body);
       
        const url = 'https://graph.facebook.com/v19.0/'+ body.phoneNumberId +'/messages';
        const headers = {
            'Authorization': `Bearer ${body.SystemUserToken}`,
            'Content-Type': 'application/json'
        };
        let messageBody = {
            messaging_product: 'whatsapp',
            to: body.currentParticipantPhoneNumber,
            type: 'text',
            text: {
                body: body.message
            }
        };
    
        // Add context if replyWamid is provided
        if (body.replyWamid) {
            messageBody.context = {
                message_id: body.replyWamid
            };
        }
    
        const requestBody = JSON.stringify(messageBody);
    
        try {
            const response = await fetch(url, { method: 'POST', headers, body: requestBody });
            return response.json();
        } catch (error) {
            console.error('Error from sendMessageToWhatsapp: ', error);
        }
    }
    

    static sendAudioToWhatsapp = async (body) => {
        const url = `https://graph.facebook.com/v19.0/${body.phoneNumberId}/messages`;
        const headers = {
            'Authorization': `Bearer ${body.SystemUserToken}`,
            'Content-Type': 'application/json'
        };
        let messageBody = {
            messaging_product: 'whatsapp',
            to: body.currentParticipantPhoneNumber,
            type: 'audio',
            audio: {
                id: body.mediaId
            }
        };

        // Add context if replyWamid is provided
        if (body.replyWamid) {
            messageBody.context = {
                message_id: body.replyWamid
            };
        }

        const requestBody = JSON.stringify(messageBody);

        try {
            const response = await fetch(url, { method: 'POST', headers, body: requestBody });
            const data = await response.json();
            return data;
        } catch (error) {
            console.error('Error:', error);
        }
    }

    static async sendMediaToWhatsapp({ mediaType, media, waPhoneNumberId, SystemUserToken, currentParticipantPhoneNumber, wabaId, replyWamid, caption }) {
        const url = `https://graph.facebook.com/v19.0/${waPhoneNumberId}/messages`;
        const body = JSON.stringify({
            messaging_product: "whatsapp",
            to: currentParticipantPhoneNumber,
            type: mediaType,
            [mediaType]: { 
                link: media,
                ...(caption && { caption: caption }) // Add caption if it exists
            },
            ...(replyWamid && { context: { message_id: replyWamid } })
        });
    
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${SystemUserToken}`,
                    'Content-Type': 'application/json'
                },
                body: body
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const responseBody = await response.text();
            return responseBody; // Return the raw body as a string
        } catch (error) {
            console.error(`Error in sendMediaToWhatsapp (${mediaType}):`, error);
        }
    }
    
    // Specific methods for each media type
    static sendImageToWhatsapp(params) {
        return this.sendMediaToWhatsapp({ ...params, mediaType: 'image' });
    }
    
    static sendVideoToWhatsapp(params) {
        return this.sendMediaToWhatsapp({ ...params, mediaType: 'video' });
    }
    
    static sendDocumentToWhatsapp(params) {
        return this.sendMediaToWhatsapp({ ...params, mediaType: 'document' });
    }
    

    static checkWhatsappPhoneNumber = async(body) => {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/validateWhatsappPhoneNumber/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token ' + body['token']
                }, 
                body: JSON.stringify(body)
            })

            const resp = response.json();
            return resp;
        } catch (error) {
            console.error(error);
        }
    }
    
    
    static addNewContacts = async (body) => {
        // Check if the chatroom with user already exists in the system --------------------------------
        console.time('CHECKCLIENTUSERAPI');
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/check_existing_contacts/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ` + body['token']
                },
                body: JSON.stringify({
                    phone_number: body['phone'],
                    wa_id: body['wabaId'],
                    user_id: body['userId'],
                })
            });

            const resp = await response.json();
            if (resp.message === "Found existing Chatroom with Client User already!") {
                return resp;
            }
        } catch (error) {
            console.error(error);
        }
        console.timeEnd('CHECKCLIENTUSERAPI');
        // --------------------------------------------------------------------------------------------

        // if chatroom does not exist, send message to phone number to validate phone number ------------
        console.time('VALIDATECLIENTUSER');
        return new Promise(async (resolve, reject) => {
            
            const socket = new WebSocket('https://streamhost-django-80bc172b3b26.herokuapp.com/ws/validatePhoneNumber/'+body['waPhoneNumberId']+'/');
            var output = "User Successfully Added";
            // Wait for the WebSocket connection to open
            socket.onopen = function(e) {
                console.log("[open] Connection established");
            };

            // Handle incoming WebSocket messages
            socket.onmessage = async function(event) { // Made this function async
                console.log(`[message] Data received from server: ${event.data}`);
                const data = JSON.parse(event.data);
                
                
                if (data.error === "no error") {
                    // if phone number is valid, add new contacts
                    console.log("User exists, creating contact...");
                    try {
                        const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/add_new_contacts/', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': `Token ` + body['token']
                            },
                            body: JSON.stringify({
                                phone_number: body['phone'],
                                waba_id: body['wabaId'],
                                name: body['username'],
                                email: body['email'],
                                company: body['companyName'],
                                address:body['address'],
                                user_id: body['userId'],
                                checkin_list: body['checkin_list'],
                                checkout_list: body['checkout_list'],
                                checkin_datetime: body['checkin_datetime'],
                                checkout_datetime: body['checkout_datetime'],
                                unit_no: body['unit_no'],
                                chatflow_id: body['chatflow_id'],
                                building: body['building']
                            })
                        });
                        console.log("contact body: ", body)
                        if (!response.ok) {
                            throw new Error(`HTTP error! status: ${response.status}`);
                        }
                        const jsonResponse = await response.json();
                        output = jsonResponse;
                        resolve(output);
                        // Handle jsonResponse here if needed
                    } catch (error) {
                        console.error("User not found:", error);
                    }
                } else {
                    output = "User does not exist.";
                    resolve(output);
                }
                
                // Close the WebSocket connection
                socket.close();
            };

            socket.onclose = function(event) {
                console.log('[close] Connection closed');
                console.log(output)
                resolve(output);
            };

            socket.onerror = function(error) {
                output = "Invalid phone number";
                resolve(output);
            };

            try {
                // Send the POST request
                const response = await fetch('https://graph.facebook.com/v19.0/' + body['waPhoneNumberId'] + '/messages', {
                    method: 'POST',
                    headers: {
                        'Authorization': `Bearer ${body['SystemUserToken']}`,
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        messaging_product: "whatsapp",
                        recipient_type: "individual",
                        to: body['phone'],
                        type: "text",
                        text: {
                            body: " "
                        }
                    })
                });
            
                if (!response.ok) {
                    // If the response is not ok, throw an error with the status
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                // If you need to use the response data, convert it from JSON
            } catch (error) { 
                // Log or handle errors here
                console.error("Error sending message:", error);
                output = "Invalid phone number";
                socket.close(); 
            }
            console.timeEnd('VALIDATECLIENTUSER');
        });

    }


    // Test
    static addContact = async(body) => {
        console.log(body);
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/add_contact/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token ' + body['token']
                },
                body: JSON.stringify(body)
            })

            const resp = await response.json();
            return resp;
        } catch (error) {
            console.error(error);
        }
    }

    static registerPhoneNumber = async (wabaId, phoneNumerId, SystemUserToken) => {
        console.log('Registering Phone Number:', phoneNumerId, SystemUserToken, wabaId);
        try{
            const response  = await fetch('https://graph.facebook.com/v20.0/'+ phoneNumerId + '/register', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${SystemUserToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    messaging_product: "whatsapp",
                    pin: "123456",
                    data_localization_region: "SG"
                })
            })
            if (!response.ok) {
                throw new Error(`HTTP error! status at Line 1037: ${response.status}`);
            }

            const responseData = await response.json();
            console.log('Register User Response:', responseData);

        } catch (error){
            console.error("Register User: ", error);
        }

        // Subscribe to Webhook
        try{
            const response  = await fetch('https://graph.facebook.com/v20.0/'+ wabaId + '/subscribed_apps', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${SystemUserToken}`,
                    'Content-Type': 'application/json'
                }
            })
            if (!response.ok) {
                throw new Error(`HTTP error! status at Line 1054: ${response.status}`);
            }
            const responseData = await response.json();
            console.log('Subscribe to Webhook Response:', responseData);
        } catch (error){
            console.error("Subscribe to webhook: ", error);
        }
    }
    
    // Client User Section -----------------------------------------------------
    static getClientUserList = async (userToken) => {
        const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/client_user', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${userToken}`
            }
        });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        return await response.json();
    }

    static getClientUser = async(body) => {
        const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/client_user/?phone_number='+ body['phone_number'], {
            method: 'GET',
            headers: {
                'Authorization': 'Token ' + body['token']
            }
        })
        const data = await response.json();
        return data;
    }

    static getExistsUser = async ({ token, phone_number }) => {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/client_user', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const users = await response.json();
            return users.find(user => user.phone_number === phone_number);

        } catch (error) {
            console.error('Error fetching client user:', error);
            throw error;
        }
    }
    

    static async addClientUser(body) {
        try {
            console.log('ADD CLIENT USER RAN');
            console.log(body);
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/client_user/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token `+ body['token']
                },
                body: JSON.stringify({
                    'phone_number': body['phone_number'],
                    'name': body['name'],
                    'checkin_datetime': body['checkin_datetime'],
                    'checkout_datetime': body['checkout_datetime'],
                    'unit_no': body['unit_no'],
                    'wabaId': body['wabaId'],
                    'checkin_status': body['checkin_status'],
                    'checkout_status': body['checkout_status'],
                    'chatflow_id': body['chatflow_id'],
                })
            });

            const resp = await response.json();
            return resp;
        } catch (error) {
            console.error(error);
        }
    }

    static async deleteClientUser(body) {
        try{
            const response  = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/client_user/'+body['id'] +'/', {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Token ' + body['token']
                }
            })
            return response;

        } catch (error){
            console.error(error);
        }
    }

    static async editClientUser(body) {
        try {
            console.log('API DATA CHECK')
            console.log(body);
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/client_user/' + body['id'] + '/', {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token ' + body['token']
                },
                body: JSON.stringify(body)
            });
            const resp = await response.json();
            return resp;
        } catch (error) {
            console.error(error);
        }
    }
    // -------------------------------------------------------------------------

    
    // Notification Section ----------------------------------------------------
    static getNotifications = async (userToken, wabaId) => {
        return fetch(`https://streamhost-django-80bc172b3b26.herokuapp.com/api/notifications?client_waba_id=${wabaId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${userToken}`
            }
        }).then(resp => resp.json());
    }

    static deleteNotification = async(body) => {
        try {
            return fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/notifications/' + body['id'] + '/', {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Token ' + body['token'],
                }
            })
        } catch (error) {
            console.error(error);
        }
    }

    static getNotificationsForLastMessage = async (userToken, wabaId, lastMessageId) => {
        return fetch(`https://streamhost-django-80bc172b3b26.herokuapp.com/api/notifications?client_waba_id=${wabaId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${userToken}`
            }
        }).then(resp => resp.json());
    }

    static async updateNotification(body) {
        const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/toggle_notifications/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${body.token}`
          },
          body: JSON.stringify({
            profile_id: body.profile_id,
            field: body.field
          })
        });
      
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
      
        return response.json();
      }

      static async updateAccountTodoNotifications(body) {
        const payload = body.not_allowed_task_types
            ? { 'account_id': body.account_id, 'not_allowed_task_types': body.not_allowed_task_types }
            : { 'account_id': body.account_id, 'allow_whatsapp_notifications': body.allow_whatsapp_notifications };
    
        const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/toggle_notifications/', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${body.token}`
            },
            body: JSON.stringify(payload)
        });
    
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }       
    
        return response.json();
    }

    static async sendPushNotification(subscription, data) {
        console.log("Sending push notification:", { subscription, data });
        
        // Check if subscription is a PushSubscription instance or a plain object
        const subscriptionData = subscription.toJSON ? subscription.toJSON() : subscription;
        
        const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/send-notification/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                subscription: subscriptionData,
                data: data
            })
        });
    
        if (!response.ok) {
            throw new Error('Failed to send push notification');
        }
    
        const result = await response.json();
        console.log("Push notification result:", result);
        return result;
    }

    static async sendTagNotification(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/send_tag_notification/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token ' + body['userToken']
                },
                body: JSON.stringify(body)
            })
            const resp = await response.json()
            return resp;
        } catch (error) {
            console.error(error);
        }
    }

    static async getVapidPublicKey() {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/vapidPublicKey/');
            if (!response.ok) {
                throw new Error('Failed to fetch VAPID public key');
            }
            return await response.text();
        } catch (error) {
            console.error('Error fetching VAPID public key:', error);
        }
    }

    static async subscribeToPushNotifications(subscription, profileId) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/subscribe/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ subscription: subscription, profileId: profileId })
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to subscribe to push notifications');
            }
            
            return await response.json();
        } catch (error) {
            console.error('Error subscribing to push notifications:', error);
        }
    }

    static async getNotificationSubscription(profileId) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/subscribe/?profileId='+profileId, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Failed to subscribe to push notifications');
            }
            
            return await response.json();
        } catch (error) {
            console.error('Error subscribing to push notifications:', error);
        }
    }

    static async setPushNotification(body) {
        const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/set_push_notification/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${body.userToken}`
            },
            body: JSON.stringify({ 
                profileId: body.profileId, 
                deviceId: body.deviceId, 
                subscription: body.subscription, 
                isSubscribed: body.isSubscribed 
            })
        });
    
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
    
        return response.json();
    }   

    static getLastMessage = async (chatRoomId, userToken) => {
        return fetch(`https://streamhost-django-80bc172b3b26.herokuapp.com/api/chatroom/${chatRoomId}/last_message/`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + userToken  // Include the user token in the Authorization header
            }
        }).then(resp => resp.json())
    }

    static getClientLastMessage = async(chatRoomId, userToken) => {
        try {
            const response = await fetch(`https://streamhost-django-80bc172b3b26.herokuapp.com/api/chatroom/${chatRoomId}/last_client_message/`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token ' + userToken  // Include the user token in the Authorization header
                }
            })
            const resp = await response.json();
            return resp;
        } catch (error) {
            console.error(error);
        }
    }

    static modifyErrorMessage = async (chatRoomId, userToken, error_code_message) => {
        return fetch(`https://streamhost-django-80bc172b3b26.herokuapp.com/api/chatroom/${chatRoomId}/last_message/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Token ' + userToken  // Include the user token in the Authorization header
            },
            body: JSON.stringify({ error_code_message }) 
        }).then(resp => resp.json())
    }
    // -------------------------------------------------------------------------


    // ToDoList Section --------------------------------------------------------
    static async getTasks(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/task2/', {
                method: 'GET',
                headers: {
                    'Authorization': 'Token ' + body['token']
                }
            });
            const resp = await response.json();
            
            return resp;
        } catch (error) {
            console.error(error);
        }
    }

    static async addTask(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/task/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token ' + body['token']
                },
                body: JSON.stringify({
                    'task_id': body['task_id'],
                    'task_title': body['task_title'],
                    'task_type': body['task_type']
                })
            });
            const resp = await response.json();
            return resp;
        } catch (error) {
            console.error(error);
        }
    }

    static async updateTask(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/task/' + body['id'] + '/', {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token ' + body['token']
                },
                body: JSON.stringify({
                    'complete_date': body['complete_date'],
                    'completed': body['completed']
                })
            });
            const resp = await response.json();
            return resp;
        } catch (error) {
            console.error(error);
        }
    }

    static async deleteTask(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/task/' + body['id'] + '/', {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Token ' + body['token']
                }
            });
            return response;
        } catch (error) {
            console.error(error);
        }
    }
    // ---------------------------------------------------------------

    // TaskType Section --------------------------------------------------------
    static async getTaskTypes(body) {
        const response = await fetch(`https://streamhost-django-80bc172b3b26.herokuapp.com/api/task_type/`, {
            method: 'GET',
            headers: {
                'Authorization': 'Token '+ body['token']
            }
        });
      
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
      
        return response.json();
    }

    static async addTaskType(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/task_type/', {
                method: 'POST',
                headers: {
                    'Authorization': 'Token ' + body['token'],
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })

            const resp = response.json();
            return resp;
        } catch (error) {
            console.error(error);
        }
    }

    static async editTaskType(body) {
        try {
            console.log(body);
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/task_type/1/', {
                method: 'PATCH',
                headers: {
                    'Authorization': 'Token ' + body['token'],
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            })
            const resp = response.json();
            return resp;
        } catch (error) {
            console.error(error);
        }
    }

    static async deleteTaskType(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/task_type/' + body['task_type'] + '/', {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Token ' + body['token'],
                },
            })

            const resp = response.json();
            return resp;
        } catch (error) {
            console.error(error);
        }
    }
    // -------------------------------------------------------------------------

    // TaskTypePhone Section --------------------------------------------------------
    static async getTaskTypePhone(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/task_type_phone/', {
                method: 'GET',
                headers: {
                    'Authorization': 'Token ' + body['token'],
                },
            })

            const resp = response.json();
            return resp;
        } catch (error) {
            console.error(error);
        }
    }

    static async addTaskTypePhone(body) {
        console.log(body);
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/task_type_phone/', {
                method: 'POST',
                headers: {
                    'Authorization': 'Token ' + body['token'],
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'task_type': body['task_type'],
                    'phone_number': body['phone_number']
                })
            })

            const resp = response.json();
            return resp;
        } catch (error) {
            console.error(error);
        }
    }

    static async deleteTaskTypePhone(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/task_type_phone/' + body['id'] + '/', {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Token ' + body['token'],
                }
            })
            return response;
        } catch (error) {
            console.error(error);
        }
    }

    static async editTaskTypePhone(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/task_type_phone/' + body['id'] + '/', {
                method: 'PATCH',
                headers: {
                    'Authorization': 'Token ' + body['token'],
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'template_selected': body['template_selected'],
                    'phone_number_id': body['phone_number_id']
                })
            })
            const resp = response.json();
            return resp;
        } catch (error) {
            console.error(error);
        }
    }

    // -------------------------------------------------------------------------
    
    // Dashboard Section ---------------------------------------------
    static async getMessageSentiment(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/dashboard/message_sentiment?waba_id='+body['waba_id']+'&start_date='+body['start_date']+'&end_date='+body['end_date'], {
                method: 'GET',
                headers: {
                    'Authorization': 'Token ' + body['token']
                }
            });
            const resp = await response.json()
            return resp;

        } catch (error) {
            console.error(error);
        }
    }

    static async getInquiriesCount(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/dashboard/inquiries_count?waba_id='+body['waba_id']+'&start_date='+body['start_date']+'&end_date='+body['end_date'], {
                method: 'GET',
                headers: {
                    'Authorization': 'Token ' + body['token']
                }
            });
            const resp = await response.json()
            console.log(resp);
            return resp;

        } catch (error) {
            console.error(error);
        }
    }

    static async getResponseRate(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/dashboard/response_rate?waba_id='+body['waba_id']+'&start_date='+body['start_date']+'&end_date='+body['end_date'], {
                method: 'GET',
                headers: {
                    'Authorization': 'Token ' + body['token']
                }
            });
            const resp = await response.json()
            console.log(resp);
            return resp;

        } catch (error) {
            console.error(error);
        }
    }

    static async getClientCount(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/dashboard/client_count?waba_id='+body['waba_id'], {
                method: 'GET',
                headers: {
                    'Authorization': 'Token ' + body['token']
                }
            });
            const resp = await response.json()
            return resp;

        } catch (error) {
            console.error(error);
        }
    }
    // -------------------------------------------------------------------------


    // Quick Resposne Section -------------------------------------------------
    static async addQuickResponse(body) {
        try {
            console.log(body);
            const formData = new FormData();
            formData.append('command_id', body['command_id']);
            formData.append('command', body['command']);
            formData.append('text_response', body['text_response']);
            formData.append('file_response', body['file_response']);

            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/quick_response/', {
                method: 'POST',
                headers: {
                    'Authorization': 'Token ' + body['token']
                },
                body: formData
            });
            const resp = await response.json();
            return resp;
        } catch (error) {
            console.error(error);
        }
    }

    static async fetchCommand(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/quick_response/', {
                method: 'GET',
                headers: {
                    'Authorization': 'Token ' + body['token']
                }
            })

            const resp = await response.json();
            return resp;
        } catch (error) {
            console.error(error);
        }
    }

    static async deleteCommand(body) { 
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/quick_response/' + body['id'] + '/', {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Token ' + body['token']
                }
            })

            const resp = await response.json();
            return resp;
        } catch(error) {
            console.error(error);
        }
    }
    // -------------------------------------------------------------------------

    // Stripe Payment Section -------------------------------------------------
    static async addPaymentSession(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/payment/session/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'session_id': body['session_id'],
                    'user_id': body['user_id']
                })
            })

            const resp = await response.json();
            return resp;
        } catch (error) {
            console.error(error);
        }
    }

    static async cancelSubscription(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/stripe/cancel_subscription/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Token ' + body['token']
                },body: JSON.stringify({
                    'plan_type': body['plan_type'],
                })
            })
        } catch (error) {
            console.error(error);
        }
    }

    // -------------------------------------------------------------------------

    // Inquiries Section   ---------------------------------------------
    static async addInquiries(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/inquiries/', {
                method:'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'inquiries_id': body['inquiries_id'],
                    'first_name': body['first_name'],
                    'last_name': body['last_name'],
                    'email': body['email'],
                    'message': body['message']

                })
            });
            const resp = await response;
            return resp;
        } catch (error) {
            console.error(error);
        }
    }

    // -------------------------------------------------------------------------

    // Subscription Section -------------------------------------------------
    static async getSubscription(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/subscription/', {
                method: 'GET',
                headers: {
                    'Authorization': 'Token ' + body['token']
                }
            })
            const resp = await response.json();
            return resp;
        } catch (error) {
            console.error(error);
        }
    }
    // -------------------------------------------------------------------------


    // WABA Section ------------------------------------------------------------
    static async resumableUpload(body) {
        try {
            const url = "https://streamhost-django-80bc172b3b26.herokuapp.com/api/whatsapp/resumable_upload/"
            const uploadResponse = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'file_name': body['file_name'],
                    'file_length': body['file_length'],
                    'file_type': body['file_type'],
                    'token': body['token']
                })
            });

            const uploadData = await uploadResponse.json();
            return uploadData;
        } catch (error) {
            console.error(error);
        }
    }

    static async whatsappUploadHandle(body) {
        try {
            const url = "https://streamhost-django-80bc172b3b26.herokuapp.com/api/whatsapp/upload_handle/"
            const uploadResponse = await fetch(url, {
                method: 'POST',
                body: body
            });

            const uploadData = await uploadResponse.json();
            return uploadData;
        } catch (error) {
            console.error(error);
        }
    }

    static async whatsappUpdateProfilePic(body) {
        try {
            const url = "https://streamhost-django-80bc172b3b26.herokuapp.com/api/whatsapp/upload_profile_pic/"
            const uploadResponse = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'phone_number_id': body['phone_number_id'],
                    'waba_access_token': body['waba_access_token'],
                    'handle': body['handle']
                })
            });

            const uploadData = await uploadResponse.json();
            return uploadData;
        } catch (error) {
            console.error(error);
        }
    }

    static async uploadWhatsappProfile(body) {
        try {
            console.log(body);
            const url = "https://streamhost-django-80bc172b3b26.herokuapp.com/api/whatsapp/update_profile/"
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            });
            const resp = await response.json();
            console.log(resp);
        } catch (error) {
            console.error(error);
        }
    }

    static async getUserSubscription(userToken) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/subscription/', {
                method: 'GET',
                headers: {
                    'Authorization': 'Token ' + userToken
                }
            });
            const resp = await response.json();
            return resp;
        } catch (error) {
            console.error('Error fetching user subscription:', error);
        }
    }


    // Chatbot section ---------------------------------------------------------------------------------------------------
    static async getChatbot(profileId) {
        try {
            const response = await fetch(`https://streamhost-django-80bc172b3b26.herokuapp.com/api/chatbot/?profileId=${profileId}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            const resp = await response.json();
            return resp;
        } catch (error) {
            console.error('Error fetching chatbot state:', error);
        }
    }

    static async modifyChatbot(body) {
        try {
            console.log('Body:', body);
            const response = await fetch(`https://streamhost-django-80bc172b3b26.herokuapp.com/api/chatbot/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'chatBotId': body['chatbot_id'],
                    'chatbot_name': body['chatbot_name'],
                    'chatbot_role': body['chatbot_role'],
                    'chatbot_persona': body['chatbot_persona'],
                    'chatbot_qna': body['chatbot_qna'],
                    'profileId': body['profileId']
                })
            });
            const resp = await response.json();
            console.log(resp);
            return resp;
        } catch (error) {
            console.error('Error fetching chatbot state:', error);
        }
    }

    static async toggleChatbot(activeChatRoomDetails) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/toggle_chatbot/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'chatroomId': activeChatRoomDetails,
                })
            });
            const resp = await response.json();
            return resp;
        } catch (error) {
            console.error('Error fetching chatbot:', error);
        }
    }

    static async homeChatbot(body) {

        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/homegpt/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            });
            const resp = await response.json();
            return resp;
        } catch (error) {
            console.error(error);
        }
    }

    static async getAiAssist(body) {
        try {
            console.log(body);
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/aiassist/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(body)
            });
            const resp = await response.json();
            return resp;
        } catch (error) {
            console.error(error);
        }
    }
    // --------------------------------------------------------------------------------------------------------------



    // Direct Link Section ------------------------------------------------------------------------------------------
    static async getDirectLinkUser(body) {
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/direct_link/?direct_link=' + body['direct_link'], {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });
            const resp = await response.json();
            console.log('RESP', resp);
            return resp;
        } catch (error) {
            console.error(error);
        }
    }
}
