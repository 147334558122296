import React, { useState, useContext, useEffect, useRef } from "react";
import {Helmet} from 'react-helmet';
import { DndContext, KeyboardSensor, PointerSensor, TouchSensor, closestCorners, useSensors, useSensor, closestCenter } from '@dnd-kit/core';
import { RiAddLine } from "react-icons/ri";
import { MdDelete } from "react-icons/md";
import { arrayMove, sortableKeyboardCoordinates, SortableContext, useSortable, rectSortingStrategy } from '@dnd-kit/sortable';
import { TaskHolder, Task } from "../components/DragAndDrop";
import Sidenav2 from "../components/Sidenav";
import { parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import { API } from '../api-service.js';
import { UserContext } from '../user-context.js';
import HelpButton from '../components/HelpButton.js';
import { Bounce, ToastContainer, toast} from 'react-toastify';
import './css/ToDoList.css';
import 'react-toastify/dist/ReactToastify.css';
import * as XLSX from 'xlsx';

function TaskTypeCard({ type, selectedTaskType, handleTaskTypeClick, taskCount, startDrag, cancelDrag }) {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: type });

    const style = {
        transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
        transition: transition || 'none',
    };

    const handleClick = () => {
        // Always trigger task selection when clicking anywhere on the card, ignore the badge click
        handleTaskTypeClick(type);
    };

    return (
        <div ref={setNodeRef} style={style} className={`task-type-card ${selectedTaskType === type ? 'selected' : ''}`} onClick={handleClick} onMouseDown={() => startDrag(type)} onMouseUp={cancelDrag} onTouchStart={() => startDrag(type)} onTouchEnd={cancelDrag} {...attributes} {...listeners}>
            <span className="task-type-card-content">{type}</span>
            {taskCount > 0 && (
                <div className="task-count-wrapper" onClick={(e) => e.stopPropagation()}>
                    <span className="task-count-badge">
                        {taskCount} 
                    </span>
                </div>
            )}
        </div>
    );
}


function ToDoList() {

    const { userToken, updateTaskCounts, waPhoneNumberId, wabaId, SystemUserToken } = useContext(UserContext);

    // Reservation tasks
    const [activeReservationBtn, setActiveReservationBtn] = useState('');
    const [activeReservationList, setActiveReservationList] = useState([]);
    const [checkInList, setCheckInList] = useState([]);
    const [checkOutList, setCheckOutList] = useState([]);
    const [currentlyHostingList, setCurrentlyHostingList] = useState([]);
    const [upcomingList, setUpcomingList] = useState([]);
    const [isAddingTaskType, setIsAddingTaskType] = useState(false);
    const [taskType, setTaskType] = useState('');

    // ToDoList tasks
    const [taskTypes, setTaskTypes] = useState(['No Whatsapp']);
    const [customColors, setCustomColors] = useState({'No Whatsapp' : '#653239'});
    const [tasks, setTasks] = useState([]);
    const [taskCounts, setTaskCounts] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [isDragging, setIsDragging] = useState(false);
    const dragTimeout = useRef(null);

    // Phone number 
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneNumberList, setPhoneNumberList] = useState([]);
    const [selectedPhoneNumberList, setSelectedPhoneNumberList] = useState([]);
    const [whatsappTemplateList, setWhatsappTemplateList] = useState([]);
    const [phoneError, setPhoneError] = useState('');

    const [selectedTaskType, setSelectedTaskType] = useState('No Whatsapp');
    const [hasUndoneAbove, setHasUndoneAbove] = useState(false);
    const [hasUndoneBelow, setHasUndoneBelow] = useState(false);
    const gridRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showDeleteTaskTypeConfirmation, setShowDeleteTaskTypeConfirmation] = useState(false);

    // Enable scroll horizontally
    useEffect(() => {
        const reservationBody = document.querySelector('.reservation-body');
        if (reservationBody) {
            reservationBody.addEventListener('wheel', (event) => {
                if (event.deltaY !== 0) {
                    event.preventDefault();
                    reservationBody.scrollLeft += event.deltaY;
                }
            });
        }
    }, []);
    
    // Load all the user task data from database
    useEffect(() => {

        const getWhatsAppTemplate = async () => {
            try {
                const response = await fetch('https://graph.facebook.com/v20.0/' + wabaId + '/message_templates?limit=250&fields=name&status=APPROVED', {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + SystemUserToken,
                    }
                });
                const resp = await response.json();
                const templateList = resp.data?.map((template) => ({
                    'value': template.name,
                    'label': template.name,
                }));
                setWhatsappTemplateList(templateList);
            } catch (error) {
                console.error('Error fetching templates:', error);
            }
        };

        const getTaskTypePhone = async() => {
            try {
                const response = await API.getTaskTypePhone({'token': userToken});
                setPhoneNumberList(response);
            } catch (error) {
                console.error('Error fetching templates:', error);
            }
        }

        getTaskTypePhone();
        getWhatsAppTemplate();
        setTaskTypes(['No Whatsapp']);
        setTasks([]);
        loadTasks(userToken);
        getTaskTypes();
        loadReservations(userToken);
        setActiveReservationBtn('Checking in');
    }, [userToken])

    useEffect(() => {
        const counts = taskTypes.reduce((acc, type) => {
        acc[type] = tasks.filter(task => task.task_type === type).length;
        return acc;
        }, {});

        if (window.updateSidenavTaskCounts) {
            window.updateSidenavTaskCounts(counts);
        }
        setTaskCounts(counts);
    }, [tasks, taskTypes]);

    const handleTaskTypeClick = (type) => {
        if (!isDragging) {

            setSelectedPhoneNumberList(phoneNumberList?.filter(phone => phone.task_type === type));
            setSelectedTaskType(type);
        }
    };
    
    const startDrag = (e, type) => {
        // Only start drag after 300ms click-and-hold
        dragTimeout.current = setTimeout(() => {
        setIsDragging(true);  // Activate drag mode
        }, 300);
    };

    const cancelDrag = (e) => {
    // Clear drag timeout
        if (dragTimeout.current) {
            clearTimeout(dragTimeout.current);
        }

        // If the drag wasn't triggered, treat it as a click
        if (!isDragging) {
            let text;
            if (e.target.tagName === 'SPAN') {
                if (e.target.className==='task-count-badge') {
                    text = e.target.offsetParent.firstChild.textContent;
                }else {
                    text = e.target.textContent;
                }
            } else if (e.target.tagName === 'DIV') {
                text = e.target.querySelector('span').textContent;
            }

            console.log(text);
            handleTaskTypeClick(text);
        }

        // Cancel dragging mode
        setTimeout(() => {
            setIsDragging(false);
        }, 0);
    };

    // Separate function to load tasks
    const loadTasks = async (token) => {
        if (token) {
            setIsLoading(true);
            try {
                const taskData = await API.getTasks({'token': token});
                const incompleteTasks = taskData.filter(task => !task.completed);
                
                console.log(taskData);
                setTasks(incompleteTasks);

                const taskCounts = incompleteTasks.reduce((acc, task) => {
                    acc[task.task_type] = (acc[task.task_type] || 0) + 1;
                    return acc;
                }, {});
    
                updateTaskCounts(taskCounts);
            } catch (error) {
                console.error('Error loading tasks:', error);
            } finally {
                setIsLoading(false);
            }
        }
    }

    // Separate function to load reservations
    const loadReservations = async (token) => {
        if (!token) return;

        try {
            const today = new Date();
            const clientUserData = await API.getClientUserList(token);
            
            // Get all unique chatflow IDs first
            const allChatflowIds = new Set();
            clientUserData.forEach(user => {
                const chatflowIds = JSON.parse(user.chatflow_id?.replace(/'/g, '"') || '[]');
                chatflowIds.forEach(id => allChatflowIds.add(id));
            });

            // Fetch all chatflow data at once
            const chatflowMap = new Map();
            await Promise.all(Array.from(allChatflowIds).map(async (id) => {
                const chatflow = await API.getChatFlow({'token': token, 'id': id});
                chatflowMap.set(id, chatflow.chatflow_name);
            }));

            // Process check-in list
            const processReservations = (user, filterFn) => {
                const checkin_datetime_list = JSON.parse(user.checkin_datetime?.replace(/'/g, '"') || '[]');
                const checkout_datetime_list = JSON.parse(user.checkout_datetime?.replace(/'/g, '"') || '[]');
                const building_list = JSON.parse(user.chatflow_id?.replace(/'/g, '"') || '[]');
                const unit_list = JSON.parse(user.unit_no?.replace(/'/g, '"') || '[]');
                
                const matchingIndices = [];
                for (let i = 0; i < checkin_datetime_list.length; i++) {
                    if (filterFn(new Date(checkin_datetime_list[i]), new Date(checkout_datetime_list[i]))) {
                        matchingIndices.push(i);
                    }
                }

                return {
                    name: user.name,
                    phone_number: user.phone_number,
                    checkin_datetime: matchingIndices.map(i => formatDateToDDMM(checkin_datetime_list[i])),
                    checkout_datetime: matchingIndices.map(i => formatDateToDDMM(checkout_datetime_list[i])),
                    building: matchingIndices.map(i => chatflowMap.get(building_list[i])),
                    unitName: matchingIndices.map(i => unit_list[i])
                };
            };

            // Process all lists with their respective filters
            const checkInList = clientUserData
                .map(user => processReservations(user, (checkin, _) => 
                    isSameDay(checkin, today)))
                .filter(reservation => reservation.checkin_datetime.length > 0);

            const checkOutList = clientUserData
                .map(user => processReservations(user, (_, checkout) => 
                    isSameDay(checkout, today)))
                .filter(reservation => reservation.checkin_datetime.length > 0);

            const hostingList = clientUserData
                .map(user => processReservations(user, (checkin, checkout) => 
                    checkin <= today && checkout >= today))
                .filter(reservation => reservation.checkin_datetime.length > 0);

            const upcomingList = clientUserData
                .map(user => processReservations(user, (checkin, _) => 
                    checkin > today))
                .filter(reservation => reservation.checkin_datetime.length > 0);

            setCheckInList(checkInList);
            setCheckOutList(checkOutList);
            setCurrentlyHostingList(hostingList);
            setUpcomingList(upcomingList);
            setActiveReservationList(checkInList);

        } catch (error) {
            console.error('Error loading reservations:', error);
        }
    };

    // Helper function to check if two dates are the same day
    const isSameDay = (date1, date2) => {
        return date1.getFullYear() === date2.getFullYear() &&
            date1.getMonth() === date2.getMonth() &&
            date1.getDate() === date2.getDate();
    };

    // Finds the index of element with given id
    const getTaskPos = (id) => {
        return tasks.findIndex(task => task.id === id);
    } 

    // Remove task from task button
    const removeTask = (id) => {
        setTasks((tasks) => tasks.filter(task => task.id !== id));

        // Update task status
        const now = new Date();
        let nowISOString = now.toISOString();
        let nowFormatted = nowISOString.replace('T', ' ').replace('Z', '+00:00');
        const data = {
            'token': userToken,
            'id': id,
            'complete_date': nowFormatted,
            'completed': true,
        }
        updateTask(data);
    }

    // Add task from addTask button
    const addTask = (taskTitle, taskType) => {        
        const taskId = 'TASK-' + Date.now();
        const data = {
            'token': userToken,
            'task_id': taskId,
            'task_title': taskTitle,
            'task_type': taskType,
        };
        createTask(data);
    }

    // ------------------------------------------------------------------------

    // API Get, Fetch and Patch function ---------------------------------------------

    // Create tasks
    const createTask = async (data) => {
        try {
            const taskData = await API.addTask(data);
            setTasks(prevTask => [...prevTask, taskData]);
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }

    const updateTask = async (data) => {
        try {
            const taskData = await API.updateTask(data);
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    }
    // ------------------------------------------------------------------------

    // API for task typs -----------------------------------------------------
    const getTaskTypes = async () => {
        if (userToken) {
            try {
                const taskTypeData = await API.getTaskTypes({'token': userToken});
                if (taskTypeData.length === 0) {
                    return;
                }
                // Reformat data
                taskTypeData.map(type => {
                    setTaskTypes(prevTypes => [...prevTypes, type.task_type]);
                    if (type.task_color) {
                        setCustomColors(prevColors => ({
                            ...prevColors,
                            [type.task_type]: type.task_color
                        }));
                    }
                });
            } catch (error) {
                console.error('Error:', error);
                throw error;
            }
        }
    }

    const deleteTaskType = async(task_type) => {
        try {
            setTaskTypes((tasks) => tasks.filter(task => task !== task_type));
            const response = await API.deleteTaskType({'token': userToken, 'task_type': task_type})
            console.log(response);
        } catch (error) {
            console.error(error);
        }
    }
    // ------------------------------------------------------------------------

    // API for task type phone -----------------------------------------------------
    const addTaskTypePhone = async () => {

        if (phoneNumber === '') {
            toast.error('Phone number cannot be empty');
            return;
        }

        if (phoneError) {
            toast.error('Invalid phone number');
            return;
        }

        if (selectedPhoneNumberList.find(phone => phone.phone_number === phoneNumber)) {
            toast.error('Phone number already exists');
            return;
        }

        // Check if phone number has whatsapp
        try {
            const response = await API.checkWhatsappPhoneNumber({'token': userToken, 'phone_number': phoneNumber.replace(/\s+/g, "").replace("+", ""), 'phone_number_id': waPhoneNumberId, 'waba_id': wabaId, 'system_token': SystemUserToken});
            if (response?.valid === true) {
                const data = await API.addTaskTypePhone({'token': userToken, 'phone_number': phoneNumber.replace(/\s+/g, "").replace("+", ""), 'task_type': selectedTaskType});
                setPhoneNumberList(prev => [...prev, data]);
                setSelectedPhoneNumberList(prev => [...prev, data]);
                toast.success('Whatsapp forward phone has been added successfully');
            } else {
                toast.error('Phone number is not a valid Whatsapp number');
            }

            setPhoneNumber('');
        } catch(error) {
            console.error('Error:', error);
        }

    }

    const deleteTaskTypePhone = async (id) => {
        try {
            const response = await API.deleteTaskTypePhone({'token': userToken, 'id': id});
            setPhoneNumberList((phones) => phones.filter(phone => phone.id !== id));
            setSelectedPhoneNumberList((phones) => phones.filter(phone => phone.id !== id));
            toast.success('Phone number has been deleted successfully');
            console.log(response);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const handleTaskTypePhoneChange = async(data) => {
        const phone_number = selectedPhoneNumberList.find(phone => phone.phone_number === data.phone_number.phone_number);
        if (phone_number) {
            // Create updated phone number object
            const updatedPhoneNumber = {...phone_number, selected_template: data.template};
            
            // Update list with new value while keeping others unchanged
            setSelectedPhoneNumberList(prevList => 
                prevList.map(phone => 
                    phone.phone_number === phoneNumber ? updatedPhoneNumber : phone
                )
            );
            const json = {
                'token': userToken,
                'phone_number_id': data.phone_number.id,
                'template_selected': data.template
            }
            try {
                const response = await API.editTaskTypePhone(json);
                toast.success('Template has been updated successfully');
                console.log(response);
            } catch (error) {
                console.error('Error:', error);
            }
        }
    }
    // ------------------------------------------------------------------------


    // Reservation Section logic -----------------------------------------------
    const handleReservationClicked = (type) => {
        const listDict = {
            'Checking in': checkInList,
            'Checking out': checkOutList,
            'Currently hosting': currentlyHostingList,
            'Upcoming': upcomingList
        }
        setActiveReservationBtn(type);
        setActiveReservationList(listDict[type]);
    }
    // ------------------------------------------------------------------------
    


    // DND Logic --------------------------------------------------------------
    // UX: Support pointer, touch, and keyboard movements
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(TouchSensor),
        useSensor(KeyboardSensor, {coordinateGetter: sortableKeyboardCoordinates })
    )

    // Telling dnd what to do when an element is dropped
    const handleDragEnd = (event) => {
        // active is the element currently being dragged
        // over is the element replaced once the element is being let go. Eg: if 1 place on 2, over will return 2
        const {active, over} = event;
        if (active.id === over.id) return; // This means that nothing have changed.

        setTasks((tasks) => {
            // Get position of element before it is dragged
            const originalPos = getTaskPos(active.id);
            const newPos = getTaskPos(over.id);
            // Updates the array based on the position moved
            return arrayMove(tasks, originalPos, newPos);

        })
    }
    // ------------------------------------------------------------------------

    //Add New Task Type -----------------------------------------------------------
    const addNewTaskType = async(newType) => {

        // Task type validation
        if (newType === '') {
            toast.error('Task type cannot be empty');
            return;
        }

        if (taskTypes.includes(newType)) {
            toast.error('Task type already exists');
            return;
        }

        // Add task type to database
        const response = await API.addTaskType({'token': userToken, 'task_type': newType})
        setTaskTypes(prevTypes => [...prevTypes, newType]);
        setIsAddingTaskType(false);
    }

    // Regex function
    function getUserName(input) {
        // Regular expression to match the last number in the string
        const regex = /.*\d+/;
        const match = input?.match(regex);
        
        if (match) {
            // Get the index of the last number
            const lastNumberIndex = match[0].length;
            // Return the substring after the last number
            return input.substring(lastNumberIndex).trim();
        }
        
        // Return the original string if no number is found
        return input;
    }

    function formatDateToDDMM(dateString) {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        return `${day}/${month}`;
    }

    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };
    
    const checkUndoneTasksVisibility = () => {
        if (!gridRef.current) return;

        const grid = gridRef.current;
        const cards = grid.querySelectorAll('.task-type-card');
        let foundUndoneAbove = false;
        let foundUndoneBelow = false;
        const gridTop = grid.scrollTop;
        const gridBottom = gridTop + grid.clientHeight;

        cards.forEach((card) => {
            const cardTop = card.offsetTop;
            const cardBottom = cardTop + card.clientHeight;
            const taskCount = parseInt(card.querySelector('.task-count-badge')?.textContent || '0');

            if (taskCount > 0) {
                if (cardBottom < gridTop) foundUndoneAbove = true;
                if (cardTop > gridBottom) foundUndoneBelow = true;
            }
        });

        setHasUndoneAbove(foundUndoneAbove);
        setHasUndoneBelow(foundUndoneBelow);
    };

    useEffect(() => {
        const grid = gridRef.current;
        if (grid) {
            grid.addEventListener('scroll', checkUndoneTasksVisibility);
            window.addEventListener('resize', checkUndoneTasksVisibility);
            checkUndoneTasksVisibility();
        }

        return () => {
            if (grid) {
                grid.removeEventListener('scroll', checkUndoneTasksVisibility);
                window.removeEventListener('resize', checkUndoneTasksVisibility);
            }
        };
    }, [taskTypes, taskCounts]);

    const filteredTaskTypes = [...taskTypes]
    .filter((type) => type.toLowerCase().includes(searchQuery.toLowerCase()))
    .sort((a, b) => (taskCounts[b] || 0) - (taskCounts[a] || 0)); 


    // Download excel function
    const downloadReservationsExcel = () => {
        // Combine all reservation data
        const allReservations = [
            ...checkInList.map(r => ({...r, type: 'Checking In'})),
            ...checkOutList.map(r => ({...r, type: 'Checking Out'})),
            ...currentlyHostingList.map(r => ({...r, type: 'Currently Hosting'})),
            ...upcomingList.map(r => ({...r, type: 'Upcoming'}))
        ];

        // Flatten the data structure
        const flattenedData = allReservations.flatMap(reservation => 
            reservation.checkin_datetime.map((_, index) => ({
                Type: reservation.type,
                Name: reservation.name,
                'Phone Number': reservation.phone_number,
                'Check In': reservation.checkin_datetime[index],
                'Check Out': reservation.checkout_datetime[index],
                Building: reservation.building[index],
                Unit: reservation.unitName[index]
            }))
        );

        // Create a new workbook and add the data
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(flattenedData);
        XLSX.utils.book_append_sheet(wb, ws, "Reservations");

        // Generate and download the Excel file
        XLSX.writeFile(wb, "Reservations.xlsx");
    };

    const handlePhoneChange = (e) => {
        let input = e.target.value;
        if (input.length === 1 && input !== '+') {
            input = '+' + input;
        }
        setPhoneNumber(input);

        try {
            if (input) {
                const parsedNumber = parsePhoneNumber(input);
                if (isValidPhoneNumber(input)) {
                    setPhoneNumber(parsedNumber.formatInternational());
                    setPhoneError('');
                } else {
                    setPhoneError('Invalid phone number');
                }
            } else {
                setPhoneError('');
            }
        } catch (error) {
            setPhoneError('Invalid phone number');
        }
    };

    // Actual HTML
    return (
        <React.Fragment>

            <Helmet>
                <title>StreamHost | To-Do List</title>
            </Helmet>

            <Sidenav2/>
            <div className='todolist-container'>
                <h1>To-Do List</h1>

                {/* Task Types */}
                <div className='task-container'>
                    <div className='my-list'>
                    <h2>My List</h2>
                    <div className='input-container'>
                        <input type="text" id='taskType' placeholder="Search tasks" value={searchQuery} onChange={handleSearchInputChange} />
                        <label htmlFor='taskType'>Search tasks</label>
                    </div>
                    <div className='task-layout'>
                        {isLoading ? (
                        <div className="loader-container">
                            <div className="loader"></div>
                        </div>
                        ) : (
                        <div>
                            <div className="task-type-grid" ref={gridRef}>
                                {hasUndoneAbove && <div className="undone-indicator top" />}
                                {filteredTaskTypes.map((type) => (
                                    <TaskTypeCard key={type} type={type} selectedTaskType={selectedTaskType} handleTaskTypeClick={handleTaskTypeClick} taskCount={taskCounts[type] || 0} startDrag={startDrag} cancelDrag={cancelDrag}/>
                                ))}
                                <div className="task-type-card add-task-type-card" onClick={() => setIsAddingTaskType(true)}>
                                    <RiAddLine size={24} />
                                </div>
                                {hasUndoneBelow && <div className="undone-indicator bottom" />}
                            </div>
                        </div>
                        )}
                        </div>
                    </div>

                    {/* Tasks */}
                    <div className='task-list-details'>
                        <div className='task-list-header'>
                            <h2>{`${selectedTaskType}'s task`}</h2>
                            <button onClick={() => setShowDeleteTaskTypeConfirmation(true)} className='delete-task-type-btn'>
                                <MdDelete color="red" />
                            </button>
                        </div>

                        {showDeleteTaskTypeConfirmation && (
                            <div className="delete-confirmation-modal">
                                <div className="delete-task-modal">
                                    <h3>Are you sure you want to delete "{selectedTaskType}" task type?</h3>
                                    <div className="delete-modal-buttons">
                                        <button className="delete-yes" onClick={() => {deleteTaskType(selectedTaskType);
                                        setShowDeleteTaskTypeConfirmation(false); }}>
                                            Yes
                                        </button>
                                        <button className="delete-no" onClick={() => setShowDeleteTaskTypeConfirmation(false)}>No</button>
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className='task-details'>
                            {tasks.filter(task => task.task_type === selectedTaskType).length === 0 && (
                                <p className="no-tasks-message">Please add a new task</p>
                            )}
                            <DndContext sensors={sensors} onDragEnd={handleDragEnd} collisionDetection={closestCorners}>
                                <TaskHolder tasks={tasks.filter(task => task.task_type === selectedTaskType)} removeTask={removeTask} addTask={addTask} taskType={selectedTaskType} customColors={customColors} setCustomColors={setCustomColors}/>
                            </DndContext>
                        </div>
                    </div>
                </div>
                {isAddingTaskType && (
                    <div className='add-task-type-modal'>
                        <h3>Add new task type</h3>
                        <input type='text' placeholder='Task title' maxLength={40} value={taskType} onChange={(evt) => setTaskType(evt.target.value)}/>
                        <div className='add-task-type-btn-container'>
                            <button onClick={() => setIsAddingTaskType(false)}>Cancel</button>
                            <button onClick={() => addNewTaskType(taskType)}>Confirm</button>
                        </div>
                    </div>
                )}
            </div>
                

            {/* Reservation part */}
            <div className='reservation-container'>
                <h2>Your reservations</h2>
                <div className='reservation-header'>
                    <button onClick={() => handleReservationClicked('Checking in')} style={{'border': activeReservationBtn === 'Checking in' ? 'solid 1.6px #002244' : undefined}}>Checking in ({checkInList.length})</button>
                    <button onClick={() => handleReservationClicked('Checking out')} style={{'border': activeReservationBtn === 'Checking out' ? 'solid 1.6px #002244' : undefined}}>Checking out ({checkOutList.length})</button>
                    <button onClick={() => handleReservationClicked('Currently hosting')} style={{'border': activeReservationBtn === 'Currently hosting' ? 'solid 1.6px #002244' : undefined}}>Currently hosting ({currentlyHostingList.length})</button>
                    <button onClick={() => handleReservationClicked('Upcoming')} style={{'border': activeReservationBtn === 'Upcoming' ? 'solid 1.6px #002244' : undefined}}>Upcoming ({upcomingList.length})</button>
                    <button onClick={downloadReservationsExcel} style={{backgroundColor:'#002244', color: '#FFF'}}>Download excel</button>
                </div>

                <div className='reservation-body'>
                    {activeReservationList.length > 0 ? (
                        activeReservationList.map((reservation, index) => {
                            return (
                                <div className='reservation-card' key={reservation.id || index}>
                                    <div className='reservation-name-section'>
                                        <h3>{getUserName(reservation.name)}</h3>
                                        <p>+{reservation.phone_number}</p>
                                    </div>
                                    <hr style={{ borderColor: 'rgba(0, 0, 0, 0.3)', borderWidth: '0.5px' }} />
                                    <div className='reservation-building-section'>
                                        {reservation.checkin_datetime && reservation.checkout_datetime && reservation.building && reservation.unitName ? (
                                            reservation.checkin_datetime.length > 0 ? (
                                                reservation.checkin_datetime.map((checkin, idx) => (
                                                    <div className='reservation-building' key={`${reservation.id}-${idx}`}>
                                                        <h3>{checkin} - {reservation.checkout_datetime[idx]}</h3>
                                                        <p>{reservation.building[idx]}: {reservation.unitName[idx]}</p>
                                                    </div>
                                                ))
                                            ) : (
                                                <p>No check-in data available</p>
                                            )
                                        ) : (
                                            <p>Loading...</p>
                                        )}
                                    </div>
                                </div>
                            );
                        })
                    ) : (
                        <div className='reservation-card empty'>
                            <p>You're all done for today</p>
                        </div>
                    )}
                    </div>
                </div>
            
            <ToastContainer position='bottom-center' autoClose={5000} hideProgressBar={false} closeOnClick pauseOnFocusLoss draggable rtl={false} pauseOnHover theme='colored' transition={Bounce} />
            <HelpButton />
        </React.Fragment>
    );
};

export default ToDoList;
