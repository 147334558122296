import React, { useEffect, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import './css/SidenavSettings.css';
import { UserContext } from '../user-context';

function SidenavSettings({ onSelect }) {

    const { userType, chatGPTFunction } = useContext(UserContext);

    useEffect(() => {
        // Highlight specific tab if tab is active
        let currentPage = window.location.hash.split('#')[1];
        const navLinks = document.querySelectorAll('.settings-navlink');
        // Loop through each nav link
        navLinks.forEach(link => {

            // Check if the to attribute matches the current page
            if (link.getAttribute('href').split('#')[1] === currentPage) {
                // If yes, add the 'active' className to highlight the icon
                link.classList.add('settings-active');
        }
        });

    }, []);



    return (
        <React.Fragment>
            <nav class="settings-nav">
                <ul class="settings-navbar">
                    <h1>SETTINGS</h1>
                    <h2>Your preferences</h2>
                    <li class="settings-navitem" style={{'display': userType==='secondary' ? 'none' : 'flex'}}>
                        <Link to='/main/settings/general' className="settings-navlink">General</Link>
                    </li>
                    <li class="settings-navitem">
                        <Link to='/main/settings/notification-account-settings' className="settings-navlink">Notification & Account</Link>
                    </li>
                    <li class="settings-navitem quick-response-nav">
                        <Link to='/main/settings/quick-response' className="settings-navlink">Quick Response</Link>
                    </li>
                    <li class="settings-navitem whatsapp-template-nav">
                        <Link to='/main/settings/whatsapp-template' className="settings-navlink">WhatsApp Template</Link>
                    </li>
                    <li class="settings-navitem chatgpt-nav" style={{'display': chatGPTFunction ? 'flex' : 'none', 'display': userType==='secondary' ? 'none' : 'flex'}} >
                        <Link to='/main/settings/chatbot' className="settings-navlink">AI Prompt</Link>
                    </li>
                    <hr style={{width: '80%'}}/>
                    <h2>Security</h2>
                    <li class="settings-navitem">
                        <Link to='/main/settings/password-and-security' className="settings-navlink">Passwords & Security</Link>
                    </li>
                    <hr style={{width: '80%'}}/>
                    <h2 style={{'display': userType==='secondary' ? 'none' : 'flex'}}>Pricing</h2>
                    <li class="settings-navitem" style={{'display': userType==='secondary' ? 'none' : 'flex'}}>
                        <Link to='/main/settings/plans-and-billing' className="settings-navlink">Plans and billing</Link>
                    </li>
                </ul>
            </nav>
        </React.Fragment>
    )
}

export default SidenavSettings;