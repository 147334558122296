import React, { useEffect, useState, useContext, useRef } from 'react';
import { UserContext } from "../user-context.js";
import axios from 'axios';
import { API } from "../api-service.js";
import './css/ChatArea.css';
import { Globe, Play, Pause, MessageCircle, Loader, Download } from 'lucide-react';
import { Bounce, ToastContainer, toast } from 'react-toastify';
import { IoMdChatboxes } from 'react-icons/io';
import UserImage from '../assets/user.png';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

// ChatBubble Component
function ChatBubble({ message, isRightAligned, formattedTime, onReply, onContextMenu, showContextMenu, contextMenuPosition, user}) {
    const { imageUrl, translateFunction, userName } = useContext(UserContext);
    const [translatedText, setTranslatedText] = useState('');
    const [currentLanguage, setCurrentLanguage] = useState(null);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const isTextMessage = message.media_type === 'text';
    const [isMobile, setIsMobile] = useState(false);
    const bubbleRef = useRef(null);
    const [progress, setProgress] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(null);
    const [audioMimeType, setAudioMimeType] = useState(null);
    const [playbackError, setPlaybackError] = useState(null);
    const [isImagePreviewOpen, setIsImagePreviewOpen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 867);
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        document.addEventListener('click', handleClickOutside);
        window.addEventListener('scroll', handleClickOutside, true);

        return () => {
            window.removeEventListener('resize', handleResize);
            document.removeEventListener('click', handleClickOutside);
            window.removeEventListener('scroll', handleClickOutside, true);
        };
    }, []);

    useEffect(() => {
        if (playbackError) {
            toast.error(`Unable to play audio: ${playbackError}`, {
                toastId: 'audio-error',
            });
        }
    }, [playbackError]);

    const renderTextWithLineBreaks = (text) => {
        const formatters = [
            {
                pattern: /\*(.*?)\*/g,
                render: (content, key) => <strong key={key}>{content}</strong>
            },
            {
                pattern: /_(.*?)_/g,
                render: (content, key) => <em key={key}>{content}</em>
            },
            {
                pattern: /~(.*?)~/g,
                render: (content, key) => <del key={key}>{content}</del>
            },
            {
                pattern: /```(.*?)```/g,
                render: (content, key) => <code key={key}>{content}</code>
            },
            {
                pattern: /__(.*?)__/g,
                render: (content, key) => <u key={key}>{content}</u>
            }
        ];
    
        const processText = (text) => {
            let segments = [text];
            formatters.forEach(({ pattern, render }) => {
                segments = segments.flatMap((segment, index) => {
                    if (typeof segment === 'string') {
                        const parts = segment.split(pattern);
                        return parts.map((part, i) => {
                            if (i % 2 === 1) { // matched content
                                return render(part, `${index}-${i}`);
                            }
                            return part;
                        });
                    }
                    return segment;
                });
            });
            return segments;
        };
    
        return text.split('\n').map((line, index) => (
            <React.Fragment key={index}>
                {processText(line)}
                {index < text.split('\n').length - 1 && <br />}
            </React.Fragment>
        ));
    };

    // Modify the getInitial function to use getUserName
    const getUserName = (input) => {
        try {
            const regex = /.*\d+/;
            const match = input.match(regex);

            if (match) {
                const lastNumberIndex = match[0].length;
                return input.substring(lastNumberIndex).trim();
            }
            return input;
        } catch (error) {
            console.error(error);
        }
    };

    const getInitial = (name) => {

        if (name) {
            const actualName = getUserName(name);
            return actualName?.charAt(0).toUpperCase();
        } else {
            return '?'
        };
    };

    const translateText = async (text, targetLang) => {
        const apiKey = 'AIzaSyAP854a-9kEJG5PvxKd2N7czNWXNH6y76o';
        const translateUrl = `https://translation.googleapis.com/language/translate/v2?key=${apiKey}`;

        try {
            const translateResponse = await axios.post(translateUrl, {
                q: text,
                target: targetLang,
            });
            return translateResponse.data.data.translations[0].translatedText;
        } catch (error) {
            console.error('Error translating text:', error);
            return 'Translation error';
        }
    };

    const handleClick = (e) => {
        if (isMobile) {
            e.preventDefault();
            if (!translateFunction) return;
            const rect = e.target.getBoundingClientRect();
            onContextMenu({ x: rect.left, y: rect.bottom }, message);
        }
    };

    const handleContextMenu = (e) => {
        if (!isMobile) {
            e.preventDefault();
            if (!translateFunction) return;
            onContextMenu({ x: e.clientX, y: e.clientY }, message);
        }
    };

    const handleTranslate = async (targetLang) => {
        if (currentLanguage !== targetLang) {
            const textToTranslate = message.text || message.image;
            const translated = await translateText(textToTranslate, targetLang);
            setTranslatedText(translated);
            setCurrentLanguage(targetLang);
        } else {
            setTranslatedText(message.text || message.image);
            setCurrentLanguage(null);
        }
        onContextMenu(null, null);
    };

    const handleClickOutside = (event) => {
        if (bubbleRef.current && !bubbleRef.current.contains(event.target)) {
            onContextMenu(null, null);
        }
    };

    const updateProgress = () => {
        if (audioRef.current) {
            const { currentTime, duration } = audioRef.current;
            const progressPercent = (currentTime / duration) * 100;
            setProgress(progressPercent);
        }
    };

    const seekAudio = (e) => {
        if (audioRef.current) {
            const progressBar = e.currentTarget;
            const clickPosition = (e.pageX - progressBar.offsetLeft) / progressBar.offsetWidth;
            audioRef.current.currentTime = clickPosition * audioRef.current.duration;
        }
    };

    const handleImagePreview = () => {
        if (message.media_type === 'image') {
            setIsImagePreviewOpen(true);
        }
    };

    const closeImagePreview = () => {
        setIsImagePreviewOpen(false);
    };

    const downloadImage = (imageUrl) => {
        const link = document.createElement('a');
        link.href = imageUrl;
        link.target = "_blank"; 
        link.rel = "noopener noreferrer"; 
        link.download = ''; 
        link.click();
    };

    const formatTime = (seconds) => {
        if (!seconds) return "0:00";
        const totalSeconds = Math.floor(seconds);
        const minutes = Math.floor(totalSeconds / 60);
        const remainingSeconds = totalSeconds % 60;
        return `${minutes > 0 ? minutes + ':' : '0:'}${remainingSeconds.toString().padStart(2, '0')}`;
    };

    const getRemainingTime = (currentTime, duration) => {
        if (!duration) return "0:00";
        const remainingSeconds = duration - currentTime;
        return formatTime(remainingSeconds);
    };

    const renderContent = () => {
        switch (message.media_type) {
            case 'image': {
                const imageUrl = String(message.image);
                const imageExtension = imageUrl.split('.').pop().toLowerCase();
                return ['jpg', 'jpeg', 'png', 'gif'].includes(imageExtension) ? (
                    <>
                        <img src={message.image} alt="file" className="chat-bubble-image" onClick={handleImagePreview}  onContextMenu={(e) => { e.preventDefault(); onContextMenu({ x: e.clientX, y: e.clientY }, message);}} />
                        {(message.caption || message.text) && (
                            <p className="chat-bubble-caption">{message.caption || message.text}</p>
                        )}
                        {isImagePreviewOpen && (
                            <div className="image-preview-overlay" onClick={closeImagePreview}>
                                <div className="image-preview-container">
                                    <img src={message.image} alt="Preview" className="image-preview"/>
                                </div>
                            </div>
                        )}
                    </>
                ) : (
                    <p>{imageUrl}</p>
                );
            }

            case 'video': {
                const videoUrl = String(message.image);
                const videoExtension = videoUrl.split('.').pop().toLowerCase();
                return ['mp4', 'webm', 'ogg'].includes(videoExtension) ? (
                    <>
                        <video controls className="chat-bubble-video">
                            <source src={videoUrl} />
                            Your browser does not support the video tag.
                        </video>
                        {(message.caption || message.text) && (
                            <p className="chat-bubble-caption">{message.caption || message.text}</p>
                        )}
                    </>
                ) : (
                    <p>Unsupported video type</p>
                );
            }

            case 'document': {
                const documentUrl = String(message.image);
                const documentName = documentUrl.split('/').pop();
                
                const handleDocumentClick = (e) => {
                    e.preventDefault();
                    // Check if URL is valid
                    if (documentUrl) {
                        // Open in new tab
                        window.open(documentUrl, '_blank', 'noopener,noreferrer');
                    } else {
                        toast.error('Document URL is not available');
                    }
                };
            
                return (
                    <>
                        <div onClick={handleDocumentClick} className="chat-bubble-document" style={{ cursor: 'pointer' }}>
                            <i className="document-icon"></i>
                            <span>{documentName}</span>
                        </div>
                        {(message.caption || message.text) && (
                            <p className="chat-bubble-caption">
                                {message.caption || message.text}
                            </p>
                        )}
                    </>
                );
            }

            case 'audio':
                return (
                    <div>
                        {isMobile ? (
                            <div className="chat-bubble-audio-mobile">
                                <audio src={message.audio || message.image} controls /> 
                            </div>
                        ) : (
                            <div className="audio-bubble">
                                <audio ref={audioRef} src={message.audio || message.image} onTimeUpdate={updateProgress} onLoadedMetadata={updateProgress} onEnded={() => setIsPlaying(false)}/>
                                <button onClick={toggleAudio} className="play-button">
                                    {isPlaying ? <Pause size={20} /> : <Play size={20} />}
                                </button>
                                <div className="progress-wrapper">
                                    <div className="progress-bar-container" onClick={seekAudio}>
                                        <div className="progress-bar" style={{ width: `${progress}%`}}></div>
                                    </div>
                                    <div className="time-display">
                                        {/* Changed to show remaining time */}
                                        <span className="current-time"> {getRemainingTime( audioRef.current?.currentTime || 0, audioRef.current?.duration || 0)}</span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                );

            case 'text':
            default:
                return (
                    <p className="chat-bubble-message">
                        {renderTextWithLineBreaks(currentLanguage ? translatedText : message.text)}
                    </p>
                );
        }
        
    };

    const toggleAudio = async () => {
        try {
            if (audioRef.current) {
                if (isPlaying) {
                    await audioRef.current.pause();
                } else {
                    await audioRef.current.play();
                }
                setIsPlaying(!isPlaying);
            }
        } catch (error) {
            console.error('Error toggling audio:', error);
            toast.error(`Error playing audio: ${error.message}`);
        }
    };

    const renderReplyPreview = () => {
        if (message.reply_to) {
            const replyText = message.reply_to.text ||
                (message.reply_to.media_type === 'image' ? 'Image' :
                    message.reply_to.media_type === 'video' ? 'Video' :
                        message.reply_to.media_type === 'audio' ? 'Audio' :
                            message.reply_to.media_type === 'document' ? 'Document' : 'Message');

            return (
                <div className="chatbubble-reply-preview">
                    <div className="chatbubble-reply-content">
                        <IoMdChatboxes className="chatbubble-reply-icon" />
                        <div className="chatbubble-reply-text">{replyText}</div>
                    </div>
                </div>
            );
        }
        return null;
    };

    const replyMessage = () => {
        if (message.wamid) {
            let replyText = '';
            switch (message.media_type) {
                case 'text':
                    replyText = message.text;
                    break;
                case 'image':
                    replyText = 'Image';
                    break;
                case 'video':
                    replyText = 'Video';
                    break;
                case 'audio':
                    replyText = 'Audio';
                    break;
                case 'document':
                    replyText = 'Document';
                    break;
                default:
                    replyText = 'Message';
            }
            const truncatedText = replyText.length > 50 ? replyText.substring(0, 47) + '...' : replyText;
            onReply(message);
        }
        onContextMenu(null, null);
    };

    return (
        <div ref={bubbleRef} className={`chat-bubble ${ user === 'clientUser' ? (!isRightAligned ? 'chat-bubble-right' : 'chat-bubble-left') : (isRightAligned ? 'chat-bubble-right' : 'chat-bubble-left') }`} onContextMenu={handleContextMenu} onClick={handleClick}>
            <div className={`chat-bubble-container ${ user === 'clientUser' ? (!isRightAligned ? 'flex-row-reverse' : 'flex-row') : (isRightAligned ? 'flex-row-reverse' : 'flex-row') }`}>
                <div className="chat-bubble-avatar">
                    <img src={message.sender?.waba_id ? imageUrl : ''} alt="Profile" className="chat-bubble-avatar-img" style={{display: message.sender?.waba_id ? 'block' : 'none'}}/>
                    <div className='guest-avatar' style={{display: message.sender?.waba_id ? 'none' : 'flex'}}>
                        <p>{getInitial(message.sender?.name)}</p>
                    </div>
                </div>

                <div className="chat-bubble-wrapper">
                    {message.error && (
                        <div className="chat-bubble-error" onMouseEnter={() => setShowErrorMessage(true)} onMouseLeave={() => setShowErrorMessage(false)}>
                            <span>!</span>
                            {showErrorMessage && (
                                <div className="error-tooltip">
                                    {message.error}
                                </div>
                            )}
                        </div>
                    )}
                    <div className="chat-bubble-content-wrapper">
                        {renderReplyPreview()}
                        <div className={`chat-bubble-content ${isTextMessage ? 'text' : 'media'}`} style={{'backgroundColor': message.message_type === 'private' ? '#FFBF00' : ''}}>
                            <div className = "chat-bubble-content-content">
                                {renderContent()}
                                <div className="chat-bubble-time" style={{'color': message.message_type === 'private' ? 'black' : ''}}>
                                    {(user === 'clientUser' ? !isRightAligned : isRightAligned) && (
                                        <div className="chat-bubble-send-by">
                                            Send by: {userName}
                                        </div>
                                    )}
                                    {formattedTime}
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
                {showContextMenu && (
                    <div
                        className="context-menu"
                        style={{
                            position: 'fixed',
                            top: `${contextMenuPosition.y}px`,
                            left: `${contextMenuPosition.x}px`,
                            zIndex: 1000
                        }}
                    >
                        {message.media_type === 'image' && (
                            <button 
                                onClick={() => {
                                    downloadImage(message.image);
                                    onContextMenu(null, null);
                                }} 
                                className="translate-button"
                            >
                                <Download size={16} />
                                <span>Download Image</span>
                            </button>
                        )}
                        {!currentLanguage && (
                            <>
                                <button onClick={() => handleTranslate('en')} className="translate-button">
                                    <Globe size={16} />
                                    <span>Translate to English</span>
                                </button>
                                <button onClick={() => handleTranslate('id')} className="translate-button">
                                    <Globe size={16} />
                                    <span>Translate to Indonesian</span>
                                </button>
                                <button onClick={() => handleTranslate('ms')} className="translate-button">
                                    <Globe size={16} />
                                    <span>Translate to Malay</span>
                                </button>
                                <button onClick={replyMessage} className="translate-button">
                                    <MessageCircle size={16} />
                                    <span>Reply</span>
                                </button>
                            </>
                        )}
                        {currentLanguage && (
                            <button onClick={() => handleTranslate(currentLanguage)} className="translate-button">
                                <Globe size={16} />
                                <span>Show Original</span>
                            </button>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

function ChatArea({ chatRoomId, MessageToChatArea, ImageToChatArea, AudioToChatArea, isSendingFile, setIsSendingFile, setSelectedFile, setPreviewUrl, onReply, replyText, setCaptionInput, captionInput, user }) {

    const socketRef = useRef();
    const { wabaId, waPhoneNumberId, SystemUserToken, userToken } = useContext(UserContext);
    const [chatRoomDetails, setChatRoomDetails] = useState([]);
    const [chatRoomDetails2, setChatRoomDetails2] = useState([]); //dummy state to force re-render one time
    const [currentParticipantPhoneNumber, setCurrentParticipantPhoneNumber] = useState();
    const [currentMessages, setCurrentMessages] = useState([]);
    const chatAreaRef = useRef(null);
    const heartbeatIntervalRef = useRef();
    const [showRefreshMessage, setShowRefreshMessage] = useState(false);

    const [hasMore, setHasMore] = useState();
    const [page, setPage] = useState(1);
    const [isConnectionLost, setIsConnectionLost] = useState(false);

    const [fetchedMessages, setFetchedMessages] = useState([]); // New state to hold fetched messages

    const [showContextMenu, setShowContextMenu] = useState(false);
    const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });
    const [activeMessage, setActiveMessage] = useState(null);

    const [isLoadingMore, setIsLoadingMore] = useState(false);

    // Add this ref to track initial load
    const isInitialLoadRef = useRef(true);

    const fetchChatRoomMessages = async(chatRoomId, userToken) => {
        const startTime = performance.now();
        try {
            console.time('📨 Fetching Messages');
            const response = await API.getChatRoomMessagesNew(chatRoomId, userToken, 1);
            console.log(response);
            setChatRoomDetails(response.messages ?? []);
            setChatRoomDetails2(response.messages ?? []); 
            setHasMore(response.next);
            console.log(response.next);
            setCurrentParticipantPhoneNumber(response.participant_phone_numbers);

            const endTime = performance.now();
            console.timeEnd('📨 Fetching Messages');
            console.log(`✨ Loaded ${response.messages?.length || 0} messages in ${(endTime - startTime).toFixed(2)}ms`);
        } catch (error) {
            console.error('❌ Error loading messages:', error);
        }
    }

    // Add this function to detect iOS PWA
    const isIOSPWA = () => {
        return (
            ['iPhone', 'iPad', 'iPod'].includes(navigator.platform) && 
            window.navigator.standalone === true
        );
    };
    
    const handleScroll = async () => {

        if (isIOSPWA()) {
            const currentScrollTop = chatAreaRef.current.scrollTop;
            
            // Check if we're near the top (within 50px)
            if (currentScrollTop < 50 && hasMore && !isLoadingMore) {
                setIsLoadingMore(true);
                const scrollHeightBefore = chatAreaRef.current.scrollHeight;
                
                try {
                    const response = await fetch(hasMore, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Token ${userToken}`
                        }
                    });
                    const data = await response.json();
                    
                    setHasMore(data.next);
                    
                    setChatRoomDetails(prevMessages => {
                        const existingIds = new Set(prevMessages.map(msg => msg.id));
                        const newMessages = data.messages.filter(msg => !existingIds.has(msg.id));
                        const updatedMessages = [...newMessages, ...prevMessages];
                        
                        setTimeout(() => {
                            if (chatAreaRef.current) {
                                const newScrollHeight = chatAreaRef.current.scrollHeight;
                                const scrollOffset = newScrollHeight - scrollHeightBefore;
                                chatAreaRef.current.scrollTop = scrollOffset;
                            }
                        }, 100);
                        
                        return updatedMessages;
                    });
                } catch (error) {
                    console.error('❌ Error loading more messages:', error);
                } finally {
                    setIsLoadingMore(false);
                }
                return;
            }
        }

        if (chatAreaRef.current.scrollTop === 0 && hasMore && !isLoadingMore) {
            setIsLoadingMore(true);
            
            const scrollHeightBefore = chatAreaRef.current.scrollHeight;
            
            try {
                // Make sure hasMore URL starts with https://
                const secureUrl = hasMore.replace('http://', 'https://');
                const response = await fetch(secureUrl, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${userToken}`
                    }
                });
                const data = await response.json();
                
                setHasMore(data.next);
                
                // Update messages with detailed logging
                setChatRoomDetails(prevMessages => {
                    const existingIds = new Set(prevMessages.map(msg => msg.id));
                    const newMessages = data.messages.filter(msg => !existingIds.has(msg.id));
                    
                    
                    
                    const updatedMessages = [...newMessages, ...prevMessages];
                    
                    // Use Performance API to measure timing
                    performance.mark('beforeScroll');
                    
                    requestAnimationFrame(() => {
                        const newScrollHeight = chatAreaRef.current.scrollHeight;
                        const scrollOffset = newScrollHeight - scrollHeightBefore;
                        
                    
                        
                        chatAreaRef.current.scrollTop = scrollOffset;
                        
                        performance.mark('afterScroll');
                        performance.measure('scrollAdjustment', 'beforeScroll', 'afterScroll');
                    });
                    
                    return updatedMessages;
                });

            } catch (error) {
                console.error('❌ Error loading more messages:', error);
            } finally {
                setIsLoadingMore(false);
            }
        }
    };

    useEffect(() => {
        if (fetchedMessages.length > 0) {
            console.time('🔄 Updating Messages');
            const updateStart = performance.now();
            
            setChatRoomDetails(prevMessages => {
                const updatedMessages = [...prevMessages, ...fetchedMessages];
                const updateEnd = performance.now();
                console.timeEnd('🔄 Updating Messages');
                console.log(`✨ Updated ${fetchedMessages.length} messages in ${(updateEnd - updateStart).toFixed(2)}ms`);
                return updatedMessages;
            });
            setFetchedMessages([]);
        }
    }, [hasMore]);

    useEffect(() => {
        if (AudioToChatArea) {
            const newMessage = {
                audio: AudioToChatArea.url,
                senderId: AudioToChatArea.senderId,
                timestamp: AudioToChatArea?.timestamp,
                media_type: 'audio',
                wamId: AudioToChatArea.wamId,
                reply_to: replyText ? {
                    text: replyText,
                    media_type: 'audio',
                    wamid: replyText.wamid
                } : null
            };


            if (onReply) {
                onReply(null);
            }
            setCurrentMessages((prevMessages) => [...prevMessages, newMessage]);
        }
    }, [AudioToChatArea]);


    useEffect(() => {
        setCurrentMessages([]); 
        let timeoutId;
        setShowRefreshMessage(false);
        setHasMore(false);
        setPage(1);
        

        console.time('🔌 WebSocket Connection');
        const connectStart = performance.now();
        
        socketRef.current = new WebSocket(`wss://streamhost-django-80bc172b3b26.herokuapp.com/ws/chat/${chatRoomId}/`);
        
        socketRef.current.onopen = () => {
            const connectEnd = performance.now();
            console.timeEnd('🔌 WebSocket Connection');
            console.log(`✨ WebSocket connected in ${(connectEnd - connectStart).toFixed(2)}ms`);
                        
            // Store the interval reference
            heartbeatIntervalRef.current = setInterval(() => {
                if (socketRef.current.readyState === WebSocket.OPEN) {
                    socketRef.current.send(JSON.stringify({ 
                        message: 'heartbeat', 
                        senderId: wabaId, 
                        chatRoomId: chatRoomId, 
                        message_type: "heartbeat"
                    }));
                }
            }, 120000);
        };

        fetchChatRoomMessages(chatRoomId, userToken);
        
        socketRef.current.onerror = (event) => {
            console.error('WebSocket error:', event);
        };
        

        socketRef.current.onclose = function(event) {
            if (event.wasClean) {
              console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
              timeoutId = setTimeout(() => {
                    setShowRefreshMessage(true);
                }, 10000);
            } else {
              // e.g. server process killed or network down
              // event.code is usually 1006 in this case
              console.log('[close] Connection died');
              timeoutId = setTimeout(() => {
                    setShowRefreshMessage(true);
                }, 10000);
            }
          };
          

        // Clean up the WebSocket connection when the component unmounts or the chat room ID changes
        return () => {
            console.log('🔌 Closing WebSocket connection');
            // Clear the heartbeat interval
            if (heartbeatIntervalRef.current) {
                clearInterval(heartbeatIntervalRef.current);
            }
            socketRef.current.close();
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
            
    }, [chatRoomId, userToken, wabaId]);

    useEffect(() => {
        
        if (socketRef.current) {
            socketRef.current.onmessage = (event) => {
                const message = JSON.parse(event.data);
                const currentTime = new Date();
                const baseUrl = "https://streamhost-media.s3.ap-southeast-2.amazonaws.com/";
                const formattedCurrentTime = currentTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
               
                
                // Check message type and prepend URL if it's a file
                let messageType = message.message_type;

                let messageContent = message.message;
                // Process message content if it contains a file extension
                if (typeof messageContent === 'string') {
                    const lastDotIndex = messageContent.lastIndexOf('.');
                    if (lastDotIndex !== -1 && messageContent.charAt(lastDotIndex - 1) === '=') {
                        // Remove the '=' before the extension
                        messageContent = messageContent.slice(0, lastDotIndex - 1) + messageContent.slice(lastDotIndex);
                    }
                }

                if (message.message === 'heartbeat') {
                    console.log('💓 Heartbeat received');
                    return;
                }
                
                if (messageType === 'error_message' ) {
                    let error_code_message = messageContent;
                    setCurrentMessages((prevMessages) => {
                        const updatedMessages = [...prevMessages];

                        if (messageType === 'error_message' && updatedMessages.length > 0) {
                            // Modify the last message
                            updatedMessages[updatedMessages.length - 1] = {
                                ...updatedMessages[updatedMessages.length - 1],
                                error: error_code_message
                            };
                        }
                        // Add the new message
                        return [...updatedMessages];
                    });
                    API.modifyErrorMessage(chatRoomId, userToken, error_code_message);
                }
                else if (messageType === 'text') {
                    
                    const newMessage = {
                        text: messageContent,
                        senderId: String(message.senderId),
                        timestamp: formattedCurrentTime,
                        media_type: messageType,
                        wamid: message.wamid,
                        reply_to: message.reply_to ? {
                            text: message.reply_to.text,
                            media_type: message.reply_to.media_type,
                            wamid: message.reply_to.wamid
                        } : null
                    };
                    setCurrentMessages((prevMessages) => [...prevMessages, newMessage]);
                }
                else {
                    const newMessage = {
                        text: null,
                        senderId: String(message.senderId),
                        timestamp: formattedCurrentTime,
                        media_type: messageType,
                        image: baseUrl + messageContent,
                        wamid: message.wamid,
                        reply_to: message.reply_to ? {
                            text: message.reply_to.text,
                            media_type: message.reply_to.media_type,
                            wamid: message.reply_to.wamid
                        } : null,
                        caption: message.caption
                    };

                    setCurrentMessages((prevMessages) => [...prevMessages, newMessage]);
                }

                if (onReply) {
                    onReply(null);
                }
              
            };
        }
    }, [currentParticipantPhoneNumber, chatRoomId, userToken]);

    useEffect(() => {
        // Attach the scroll event listener
        const chatAreaElement = chatAreaRef.current;
        if (chatAreaElement) {
            chatAreaElement.addEventListener('scroll', handleScroll);
        }

        // Cleanup the event listener
        return () => {
            if (chatAreaElement) {
                chatAreaElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, [handleScroll]); // Empty dependency array means this effect runs once on mount

    useEffect(() => {
        // Only scroll to bottom for new messages (currentMessages change)
        // Don't scroll when chatRoomDetails2 changes (loading older messages)
        if (chatAreaRef.current && currentMessages.length > 0) {
            chatAreaRef.current.scrollTop = chatAreaRef.current.scrollHeight;
        }
    }, [currentMessages]); // Remove chatRoomDetails2 from dependencies

    useEffect(() => {
        if (MessageToChatArea) {
            
            const currentTime = new Date();
            const formattedCurrentTime = currentTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
            const newMessage = {
                text: MessageToChatArea.text,
                senderId: wabaId,
                timestamp: formattedCurrentTime,
                sender: {waba_id: wabaId},
                media_type: 'text',
                wamid: MessageToChatArea.wamid,
                message_type:MessageToChatArea.type,
                reply_to: replyText ? {
                    text: replyText,
                    media_type: 'text',
                    wamid: replyText.wamid
                } : null
                
            };
            
            
            setCurrentMessages((prevMessages) => [...prevMessages, newMessage]);

            if (onReply) {
                onReply(null);
            }
            
        }
    }, [MessageToChatArea, wabaId]);

   

    useEffect(() => {
        const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

        const checkImageUrl = async (url, maxAttempts = 7, delayMs = 2000) => {
            for (let attempt = 1; attempt <= maxAttempts; attempt++) {
                try {
                    const response = await fetch(url, { method: 'HEAD' });
                    if (response.ok) {
                        console.log(`Attempt ${attempt}: URL check successful`);
                        return true;
                    }
                    console.log(`Attempt ${attempt}: URL not ready (status: ${response.status})`);
                } catch (error) {
                    console.error(`Attempt ${attempt}: Error checking media URL:`, error);
                }
                
                if (attempt < maxAttempts) {
                    console.log(`Retrying in ${delayMs / 500} seconds...`);
                    await delay(delayMs);
                }
            }
            console.error(`Failed to validate URL after ${maxAttempts} attempts`);
            return false;
        };

      
    
        const sendMediaToWhatsApp = async () => {
            if (!ImageToChatArea) return;


            // Extract the file extension
            const fileExtension = ImageToChatArea.media.split('.').pop().toLowerCase();
            const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
            const videoExtensions = ['mp4', 'avi', 'mov', 'wmv'];
            const docExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx'];
    
            let mediaType = '';
    
    
            try {
                // Determine the media type based on the file extension
                if (imageExtensions.includes(fileExtension)) {
                    mediaType = 'image';
                } else if (videoExtensions.includes(fileExtension)) {
                    mediaType = 'video';
                } else if (docExtensions.includes(fileExtension)) {
                    mediaType = 'document';
                } else {
                    console.error('Unsupported file extension:', fileExtension);
                    return; // Exit if the file extension is not supported
                }
    
                const currentTime = new Date();
                const formattedCurrentTime = currentTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
                
                const newMessage = {
                    senderId: wabaId,
                    timestamp: formattedCurrentTime,
                    media_type: mediaType,
                    sender: {waba_id: wabaId},
                    image: ImageToChatArea.media, // Dynamically set the media property
                    wamid: ImageToChatArea.wamid,
                    reply_to: replyText ? {
                        text: replyText,
                        media_type: 'text',
                        wamid: replyText.wamid
                    } : null,
                    caption: captionInput || null
                };


                if (onReply) {
                    onReply(null);
                }

                setCurrentMessages((prevMessages) => [...prevMessages, newMessage]);
            } catch (error) {
                console.error('Error sending media to WhatsApp:', error);
            } finally {

                setIsSendingFile(false); // Re-enable the send file button
                setSelectedFile(null); // Clear the selected file
                setPreviewUrl('');  // Re-enable the send file button
                setCaptionInput('');
            }
        };
    
        if (isSendingFile) {
            sendMediaToWhatsApp();
        }
    
    }, [ImageToChatArea, SystemUserToken, currentParticipantPhoneNumber, wabaId, waPhoneNumberId, setIsSendingFile, setSelectedFile, setPreviewUrl, setCaptionInput]);

    const handleContextMenu = (position, message) => {
        if (position === null && message === null) {
            // Close the context menu
            setShowContextMenu(false);
            setActiveMessage(null);
        } else {
            // Open the context menu
            setShowContextMenu(true);
            setContextMenuPosition(position);
            setActiveMessage(message);
        }
    };

    const closeContextMenu = () => {
        setShowContextMenu(false);
        setActiveMessage(null);
    };

    useEffect(() => {
        document.addEventListener('click', closeContextMenu);
        return () => {
            document.removeEventListener('click', closeContextMenu);
        };
    }, []);

    // Add a new useEffect to handle initial scroll position when chatRoomDetails changes
    useEffect(() => {
        if (chatAreaRef.current && chatRoomDetails.length > 0 && isInitialLoadRef.current) {
            console.log("Scrolling to bottom on initial load");
            requestAnimationFrame(() => {
                chatAreaRef.current.scrollTop = chatAreaRef.current.scrollHeight;
            });
            isInitialLoadRef.current = false;
        }
    }, [chatRoomDetails]); // This will trigger when the initial messages are loaded

    // Reset the initial load flag when chatroom changes
    useEffect(() => {
        isInitialLoadRef.current = true;
        
        // ... rest of your chatroom initialization code ...
        
        return () => {
            isInitialLoadRef.current = true;
        };
    }, [chatRoomId]);

    return (
        
        <div className='chat-area-container'>
            {isConnectionLost && (
                <div className="connection-lost-banner">
                    <div className="connection-lost-content">
                        <Loader className="animate-spin" size={16} />
                        <span>Connection lost. {showRefreshMessage && "Please refresh the page."}</span>
                    </div>
                </div>
            )}
            
            {isLoadingMore && (
                <div className="loading-indicator">
                    <Loader className="animate-spin" size={16} />
                    <span>Loading messages...</span>
                </div>
            )}
            <div className='chat-area' ref={chatAreaRef} onScroll={handleScroll}>
                <div className='chat-area-section'>
                    {chatRoomDetails?.sort((a, b) => new Date(a?.timestamp) - new Date(b?.timestamp)).map((message, index) => {
                        const timestamp = new Date(message?.timestamp);
                        const formattedTime = timestamp?.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
                        return <ChatBubble 
                            key={index}  
                            message={message} 
                            isRightAligned={!message.sender?.phone_number} 
                            formattedTime={formattedTime} 
                            onReply={onReply}
                            onContextMenu={handleContextMenu}
                            showContextMenu={showContextMenu && activeMessage === message}
                            contextMenuPosition={contextMenuPosition}
                            user={user}
                        />;
                    })}
                    {currentMessages.map((message, index) => {
                        return <ChatBubble 
                            key={`current-${index}`} 
                            message={message} 
                            isRightAligned={message.senderId === wabaId} 
                            formattedTime={message?.timestamp} 
                            onReply={onReply}
                            onContextMenu={handleContextMenu}
                            showContextMenu={showContextMenu && activeMessage === message}
                            contextMenuPosition={contextMenuPosition}
                            captionInput={captionInput}
                            user={user}
                        />;
                    })}
                </div>
            </div>
        </div>
    );
}
    

export default ChatArea;
