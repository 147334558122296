import React, { useState, useEffect, useContext, useRef, useCallback, memo, useMemo } from "react";
import {Helmet} from 'react-helmet';
import { useWebSocketContext } from './Websocket.js';
import { UserContext } from "../user-context.js";
import Sidenav from "../components/Sidenav.js";
import { API } from "../api-service.js";
import { useParams } from 'react-router-dom';
import './css/Inbox.css';
import Select from 'react-select';
import ChatArea from '../components/ChatArea';
import { useAddContact, AddContactButton, EditModal } from '../components/AddContactButton';
import ChatRoomList from '../components/ChatRoomList';
import { FaImage, FaFile, FaFileWord, FaFilePdf, FaFileExcel, FaFilePowerpoint, FaWhatsapp } from 'react-icons/fa';
import { MdFlightLand, MdFlightTakeoff } from "react-icons/md";
import { IoMdPersonAdd, IoIosArrowBack, IoIosChatboxes} from "react-icons/io";
import { IoVideocam, IoDocuments, IoHomeSharp, IoClose, IoCheckmarkDoneCircle } from "react-icons/io5";
import { LuDoorClosed, LuBuilding, LuPhone } from "react-icons/lu";
import { BsThreeDotsVertical } from "react-icons/bs";
import { PiLinkBreakBold } from "react-icons/pi";
import { FaPlus, FaRobot } from "react-icons/fa6";
import { LuListTodo } from "react-icons/lu";
import { Bounce, ToastContainer, toast} from 'react-toastify';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import { parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import { MdDelete, MdGTranslate, MdAssistantPhoto  } from "react-icons/md";
import MobileImageSelector from '../components/MobileImageSelector';

import 'react-phone-input-2/lib/material.css';
import 'react-phone-input-2/lib/bootstrap.css';
import { FaMicrophone, FaStop } from 'react-icons/fa';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile } from '@ffmpeg/util';
import { v4 as uuidv4 } from 'uuid';

import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { FaRegSmile } from 'react-icons/fa';
import { FiFilter } from 'react-icons/fi';
import { act } from "react";

const StyledDateTimePicker = styled(DatePicker)`
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: none;
    background-color: white;

    /* Remove browser autocomplete styling */
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
        -webkit-text-fill-color: inherit !important;
        transition: background-color 5000s ease-in-out 0s;
    }

    &:focus {
        margin: 0;
        outline: none;
    }
`;

// Memoize chat room click handler
const ChatAreaSection = memo(({ activeChatRoomDetails, MessageToChatArea, ImageToChatArea, AudioToChatArea, socketRef, replyText, isSendingFile, setIsSendingFile, setSelectedFile, setPreviewUrl, onReply, setCaptionInput, captionInput})=> {
    if (!activeChatRoomDetails) return null;

    return (
        <ChatArea 
            chatRoomId={activeChatRoomDetails} 
            MessageToChatArea={MessageToChatArea} 
            ImageToChatArea={ImageToChatArea} 
            AudioToChatArea={AudioToChatArea} 
            socketRef={socketRef} 
            replyText={replyText}
            isSendingFile={isSendingFile} 
            setIsSendingFile={setIsSendingFile} 
            setSelectedFile={setSelectedFile} 
            setPreviewUrl={setPreviewUrl}
            onReply={onReply}
            setCaptionInput={setCaptionInput}
            captionInput={captionInput}
        />
    );
});

function Inbox({ taskCounts = {} }) {

    // User information
    const SystemUserToken = "EAANbHtHZC5BQBOZCHluZAO43Kru1a9sEgxZCxEy0iuLi7ZBMoG2Fl8j8xdXjsHFR1Rwx9CeHdjd4qwEEeroywc6TZA51UkQmcDjL91kIfTLHaeZACq77PiTKUbiL2FcaZATKnf0zYww3BmZAOEQgaNBj1aZAvT8VyPXYgNLQK8DtfbnxDLZBFHtMHYhLj9FZBlSx86WhtbZAZBznbLrzi3";
    const { userToken, profileId, setProfileId, userId, setUserId, setContextUserName, setContextFirstName, setContextLastName, userType, setUserType, 
            setCountry, setTimeZone, imageUrl, setImageUrl, setWabaId, wabaId, waPhoneNumber, setWaPhoneNumber, setWabaAccessToken, waPhoneNumberId, setWaPhoneNumberId, setContextAbout, subscriptionPlan, setSubscriptionPlan, 
            translateFunction, setTranslateFunction, chatGPTFunction, setChatGPTFunction, setNotAllowTaskTypeNotification, setWhatsappNotification} = useContext(UserContext);

    const { updateActivePhoneNumber, toggleConnection } = useWebSocketContext();
    const [messageInput, setMessageInput] = React.useState('');
    const [captionInput, setCaptionInput] = React.useState('');
    const { phone_number } = useParams();
    const [users, setUsers] = useState([]);
    const [activeChatRoomDetails, setActiveChatRoomDetails] = useState(null);
    const [currentParticipant, setCurrentParticipant] = useState({});
    const [currentParticipantName, setCurrentParticipantName] = useState([]);
    const [currentParticipantPhoneNumber, setCurrentParticipantPhoneNumber] = useState();
    const [clientUserLength, setClientUserLength] = useState(0);
    const [activePhoneId, setActivePhoneId] = useState(localStorage.getItem('waba_id') || null);
    // TODO: Update this with alvin and add Addon amount from user
    const planContactLimit = {
        'Free': 10,
        'Small': 200,
        'Medium': 990,
        'Large': Infinity
    }
    
    // Socket connection
    const socketRef = useRef(null);

    // Option menu component states
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null); 
    const [showTranslateDropdown, setShowTranslateDropdown] = useState(false);

    // Command component states
    const commandRef = useRef(null);
    const [command, setCommand] = useState([]);
    const [commandWindow, setCommandWindow] = useState(false);
    const [filterCommand, setFilterCommand] = useState([]);

    //File component states
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState('');
    const fileInputRef = useRef(null);

    // ToDoList component states
    const [todolistModal, setTodolistModal] = useState(false);
    const [todolistPhone, setTodolistPhone] = useState('');
    const [todolistOption, setTodolistOption] = useState([]);
    const [selectedTag, setSelectedTag] = useState('');
    const [todolistDescription, setTodolistDescription] = useState('');

    // Whatsapp template component
    const [whatsappTemplateModal, setWhatsappTemplateModal] = useState(false)
    const [isTemplateButtonDisabled, setIsTemplateButtonDisabled] = useState(false);

    // Text area component states
    const [textAreaHeight, setTextAreaHeight] = useState('auto');
    const textAreaRef = useRef(null);
    const [MessageToChatArea, setMessageToChatArea] = useState(null);
    const [ImageToChatArea, setImageToChatArea] = useState('');
    const [AudioToChatArea, setAudioToChatArea] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 867);
    const [activeView, setActiveView] = useState('inbox');

    // Loading component states for add contact
    const [massFlowOption, setMassFlowOption] = useState([])
    const [massFlowData, setMassFlowData] = useState([]);
    const [unitOption, setUnitOption] = useState({});
    const [unitList, setUnitList] = useState({});
    const [contactName, setContactName] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [buildings, setBuildings] = useState([]);

    // Components for edit contact
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editBuildings, setEditBuildings] = useState([]);
    const [editClientUserId, setEditClientUserId] = useState('');
    const [editContactName, setEditContactName] = useState('');
    const [editSelectedTemplate, setEditSelectedTemplate] = useState({});

    const [isChatbotActive, setIsChatbotActive] = useState(false);
    const [isActive, setIsActive] = useState(false);
    const [isTextAreaDisabled, setIsTextAreaDisabled] = useState(false); 

    // WhatsApp Template States
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [parameters, setParameters] = useState({ header: [], body: [], button: [] }); // Ensuring it's an object with default empty arrays
    const [whatsappImageUrl, setWhatsappImageUrl] = useState(null);

    // Task data
    const [taskData, setTaskData] = useState([]);
    const [clientRelatedTask, setClientRelatedTask] = useState([]);

    // Add these state variables inside the Inbox component
    const [isRecording, setIsRecording] = useState(false);
    const [audioBlob, setAudioBlob] = useState(null);
    const mediaRecorderRef = useRef(null);
    const [audioPreviewUrl, setAudioPreviewUrl] = useState(null);
    const audioChunksRef = useRef([]);
    const ffmpeg = new FFmpeg();
    const deviceId = localStorage.getItem('deviceId');
    const [isSendingFile, setIsSendingFile] = useState(false);
    const [audioContext, setAudioContext] = useState(null);
    const [selectedChatRoom, setSelectedChatRoom] = useState(null);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const emojiPickerRef = useRef(null);

    // Secondary functions
    const [secondaryAccountList, setSecondaryAccountList] = useState([]);
    const [filterAccountList, setFilterAccountList] = useState([]);
    const [tagWindow, setTagWindow] = useState(false);

    //Testing new
    const [replyInfo, setReplyInfo] = useState(null);
    const [replyText, setReplyText] = useState(null);

    const [showFilters, setShowFilters] = useState(false);
    const [selectedBuildingFilters, setSelectedBuildingFilters] = useState([]);
    const [selectedUnitFilters, setSelectedUnitFilters] = useState([]);
    const [checkInStartDate, setCheckInStartDate] = useState(null);
    const [checkInEndDate, setCheckInEndDate] = useState(null);
    const [selectedChannel, setSelectedChannel] = useState('whatsapp');
    const [editContactDetails, setEditContactDetails] = useState(null);
    const [existingContact, setExistingContact] = useState(null);
    const [existingContactData, setExistingContactData] = useState([]);
    const [checkin, setCheckin] = useState([]);
    const [checkout, setCheckout] = useState([]);
    const [unit, setUnit] = useState([]);
    const [isGenerating, setIsGenerating] = useState(false);

    // Add cache constants
    const CACHE_KEYS = {
        PROFILE: 'inbox_profile_data',
        TEMPLATES: 'whatsapp_templates',
        BUILDINGS: 'building_options',
        UNITS: 'unit_options',
        TASKS: 'client_tasks',
        SELECTED_ROOM: 'selected_chatroom'
    };
    const CACHE_EXPIRY = 5 * 60 * 1000; // 5 minutes

    // Cache utility functions
    const cacheData = useCallback((key, data) => {
        try {
            const cacheItem = {
                data,
                timestamp: Date.now()
            };
            localStorage.setItem(key, JSON.stringify(cacheItem));
        } catch (error) {
            console.error('Cache save error:', error);
        }
    }, []);

    const loadFromCache = useCallback((key) => {
        try {
            const cached = localStorage.getItem(key);
            if (cached) {
                const { data, timestamp } = JSON.parse(cached);
                const isExpired = Date.now() - timestamp > CACHE_EXPIRY;
                
                if (!isExpired) {
                    return data;
                }
                localStorage.removeItem(key);
            }
            return null;
        } catch (error) {
            console.error('Cache load error:', error);
            return null;
        }
    }, []);

    // Cache selected chatroom
    useEffect(() => {
        if (selectedChatRoom) {
            cacheData(CACHE_KEYS.SELECTED_ROOM, selectedChatRoom);
        }
    }, [selectedChatRoom, cacheData]);

    // Load cached selected room on mount
    useEffect(() => {
        const cachedRoom = loadFromCache(CACHE_KEYS.SELECTED_ROOM);
        if (cachedRoom) {
            setSelectedChatRoom(cachedRoom);
            setActiveChatRoomDetails(cachedRoom.id);
        }
    }, [loadFromCache]);

    // Cache tasks data
    useEffect(() => {
        if (clientRelatedTask.length > 0) {
            cacheData(CACHE_KEYS.TASKS, clientRelatedTask);
        }
    }, [clientRelatedTask, cacheData]);

    const handleReply = (message) => {
        if (message === null) {
            setReplyText(null);
            setReplyInfo(null);
        } else {
            setReplyInfo(message);
            setReplyText(message.text || message.media_type);
        }
    };

    const setupPushNotification = async (userToken, profileId, isSubscribed) => {
		try {
			const registration = await navigator.serviceWorker.ready;
			const sub = await registration.pushManager.getSubscription();
			if (sub) {
				const response = await API.setPushNotification({
                    'userToken': userToken,
                    'profileId': profileId,
                    'deviceId': localStorage.getItem('deviceId'),
                    'subscription': sub,
                    'isSubscribed': isSubscribed
                });
				return response;
				
			}
		} catch (error) {
			console.error('Error setting push notification:', error);
		}
	};
    function formatDate(date) {
        const day = String(date?.getDate()).padStart(2, '0');
        const month = String(date?.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = date?.getFullYear();
    
        return `${day}/${month}/${year}`;
    }


    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        checkMobile();
        window.addEventListener('resize', checkMobile);

        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    useEffect(() => {
        const AudioContext = window.AudioContext || window.webkitAudioContext;
        setAudioContext(new AudioContext());
    
        return () => {
            if (audioContext) {
                audioContext.close();
            }
        };
    }, []);



    // UseEffect to fetch WhatsApp templates and their parameters
    useEffect(() => {

        //set active phone number id
        const setActivePhone = async (waba_id, waPhoneNumberId, waPhoneNumber)  => {
            setWabaId(waba_id);
            localStorage.setItem('waba_id', waba_id);
            setWaPhoneNumberId(waPhoneNumberId);
            localStorage.setItem('waPhoneNumberId', waPhoneNumberId);
            setWaPhoneNumber(waPhoneNumber);
            localStorage.setItem('waPhoneNumber', waPhoneNumber);
            updateActivePhoneNumber(waba_id);
        };

        // Fetch phone numbers
        const fetchWABADetails = async () => {
            const wabaId = await API.getWabaId(userToken);
            if (wabaId && wabaId.length > 0) {
                setWabaAccessToken(wabaId.map((item) => item.access_token));
                setWaPhoneNumberId(wabaId.map((item) => item.phone_number_id));
                localStorage.setItem('wabaAccessToken', wabaId.map((item) => item.access_token));
                localStorage.setItem('waPhoneNumberId', wabaId.map((item) => item.phone_number_id));

                // Set default active phone number
                const defaultWaba = wabaId[0];
                setActivePhone(defaultWaba.waba_id, defaultWaba.phone_number_id, defaultWaba.display_phone_number)
            }
        };

        const fetchSecondaryWABADetails = async (profileId) => {
            const wabaId = await API.getSecondaryWabaId({'profile_id': profileId, 'token': userToken});
            if (wabaId && wabaId.length > 0) {
                setWabaAccessToken(wabaId.map((item) => item.access_token));
                setWaPhoneNumberId(wabaId.map((item) => item.phone_number_id));
                localStorage.setItem('wabaAccessToken', wabaId.map((item) => item.access_token));
                localStorage.setItem('waPhoneNumberId', wabaId.map((item) => item.phone_number_id));
                localStorage.setItem('relatedUserProfileId', wabaId[0].profile);

                // Set default active phone number
                if (!activePhoneId) {
					const defaultWaba = wabaId[0];
					setActivePhone(defaultWaba.waba_id, defaultWaba.phone_number_id, defaultWaba.display_phone_number)
				}
            }
        }

        const getTaskTypes = async () => {
            if (userToken) {
                try {
                    const taskTypeData = await API.getTaskTypes({'token': userToken});
                    if (taskTypeData.length === 0) {
                        return;
                    }
                    // Reformat data
                    let option = []
                    taskTypeData.map(type => {
                        option.push(type.task_type)
                    });

                    setTodolistOption(option);
                } catch (error) {
                    console.error('Error:', error);
                }
            }
        }

        const getWhatsAppTemplate = async () => {
            try {
                const response = await fetch('https://graph.facebook.com/v20.0/' + wabaId + '/message_templates?limit=250&fields=name&status=APPROVED', {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + SystemUserToken,
                    }
                });
                const resp = await response.json();
                const templateList = resp.data?.map((template) => ({
                    'value': template.name,
                    'label': template.name,
                }));
                setTemplates(templateList);
            } catch (error) {
                console.error('Error fetching templates:', error);
            }
        };


        // after userToken is set, fetch user profile
        const fetchProfile = async () => {
            try {
                // Fetch user profile
                
                if (userToken) {
                    const data = await API.fetchProfile(userToken);
                    setProfileId(data['id']);
                    setUserId(data['user']['id']);
                    setContextUserName(data['user']['username']);
                    setContextFirstName(data['user']['first_name']);
                    setContextLastName(data['user']['last_name']);
                    setImageUrl(data['image']);
                    setNotAllowTaskTypeNotification(data['not_allowed_todo_notifications']);
                    setWhatsappNotification(data['allow_whatsapp_notifications']);
                    let condUserType = '';
                    if (data['related_user'] === 'main' || data['related_user'] === null) {
                        condUserType = 'main';
                        setUserType('main');
                        fetchWABADetails();
                    } else {
                        condUserType = 'secondary';
                        setUserType('secondary');
                        fetchSecondaryWABADetails(data['id']);
                    }
                    // Save user profile data in local storage
                    localStorage.setItem('profileId', data['id']);
                    localStorage.setItem('userId', data['user']['id']);
                    localStorage.setItem('userName', data['user']['username']);
                    localStorage.setItem('firstName', data['user']['first_name']);
                    localStorage.setItem('lastName', data['user']['last_name']);
                    localStorage.setItem('userType', condUserType);
                    localStorage.setItem('imageUrl', data['image']);
                    localStorage.setItem('notAllowTaskTypeNotification', data['not_allowed_todo_notifications']);
                    localStorage.setItem('whatsappNotification', data['allow_whatsapp_notifications']);
                } else {
                    console.error('User token not found');
                }
            } catch (error) {
                console.error('An error occurred while loading user profile:', error);
            }
        };

        const fetchSubscription = async() => {
            try {
                if (userToken) {
                    let data = await API.getSubscription({'token': userToken})
                    data = data.sort((a, b) => a.id - b.id);

                    // Iterate through the sorted data to check for additional plans
                    data.forEach((subscription, index) => {
                        if (subscription['status'] === 'True') {
                            const planType = subscription['plan_type']['plan_type'];
                            if (planType === 'Translate') {
                                setTranslateFunction(true);
                                localStorage.setItem('translateFunction', true);
                            } else if (planType === 'ChatGPT') {
                                setChatGPTFunction(true);
                                localStorage.setItem('chatGPTFunction', true);
                            } else {
                                setSubscriptionPlan(planType);
                                localStorage.setItem('subscriptionPlan', planType);
                            }
                        }
                    });
                }
            } catch (error) {
                console.error(error);
            }
        }

        // Fetch created user
        if (userId) {
            const fetchSecondaryProfile = async() => {
                try {
                    const response = await API.fetchSecondaryProfile({'user_id': userId, 'token': userToken});
                const formatData = response?.map((data) => ({
                    'id': data.id,
                    'username': data.user.username,
                    'email': data.user.email,
                    'building': data.building,
                    'not_allowed_todo_notifications': data.not_allowed_todo_notifications,
                    'allow_whatsapp_notifications': data.allow_whatsapp_notifications
                }));

                setSecondaryAccountList(formatData);
                } catch(error) {
                    console.error(error);
                }
            }
            fetchSecondaryProfile();
        }

        if (userToken) {
            const getTask = async() => {
                try {
                    const taskData = await API.getTasks({'token': userToken});
                const incompleteTasks = taskData?.filter(task => task.completed === false);
                setTaskData(incompleteTasks);
            } catch (error) {
                    console.error(error);
                }
            }
            getTask();
        }
        
        
        fetchProfile();
        setWabaId(localStorage.getItem('waba_id'));
        setWaPhoneNumber(localStorage.getItem('waPhoneNumber'));
        getWhatsAppTemplate();
        getTaskTypes();
        updateActivePhoneNumber(wabaId);
        fetchSubscription();
        
    }, [userToken]);



    useEffect(() => {
        // Get chatroom ids
        if (phone_number) {
            // TODO: Change this to async function
            console.log('Fetching chatrooms for phone number:', phone_number);
            API.getChatRooms(userToken, wabaId).then(data => {
                let found = false;
                data.forEach(chatRoom => {
                    if (chatRoom.participants[0]?.phone_number === phone_number) {
                        setActiveChatRoomDetails(chatRoom.id);
                        setSelectedChatRoom(chatRoom);
                        found = true;
                        if (isMobile) {
                            setActiveView('chat');
                        }
                    }
                });
                if (!found) {
                    toast.error('No chat room found with the provided phone number.');
                }
            });
        }
    }, [userToken, wabaId]);

    useEffect(() => {
        if (userToken && profileId) {
            setupPushNotification(userToken, profileId, true);
        }
    }, [userToken, profileId]);


    const handleMobileFileSelect = (file, type) => {
        handleFileChange({ target: { files: [file] } });
        setShowDropdown(false);
    };

    
    // Fetch parameters including image for the selected template
    const fetchTemplateParameters = async (templateName) => {
        try {
            const response = await fetch(`https://graph.facebook.com/v20.0/${wabaId}/message_templates?name=${templateName}`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + SystemUserToken,
                },
            });
            const resp = await response.json();
            const components = resp.data[0]?.components || [];

            // Split components into header, body, and button types
            const parameterList = {
                header: components.filter(comp => comp.type === "HEADER").map(component => {
                    if (component.format === 'IMAGE') {
                        setWhatsappImageUrl(component.example?.header_handle[0] || null); // Store the image URL if present
                        return []; // No text parameters for image header
                    }
                    return component.text?.match(/{{(.*?)}}/g)?.map(param => ({
                        param: param.replace(/[{}]/g, ''),
                        text: component.text,
                    })) || [];
                }).flat(),
                body: components.filter(comp => comp.type === "BODY").map(component =>
                    component.text.match(/{{(.*?)}}/g)?.map(param => ({
                        param: param.replace(/[{}]/g, ''),
                        text: component.text,
                    })) || []
                ).flat(),
                button: components.filter(comp => comp.type === "BUTTON").map(component =>
                    component.parameters?.map(param => ({
                        param: param.type, // Could be 'payload' for quick reply buttons
                        text: param.payload, // or it could be button payload text
                    })) || []
                ).flat(),
            };

            setParameters(parameterList);
        } catch (error) {
            console.error('Error fetching template parameters:', error);
        }
    }; 



    const convertToAAC = async (audioBlob) => {
        if (!ffmpeg.loaded) {
            await ffmpeg.load();
        }
    
        await ffmpeg.writeFile('audio.webm', await fetchFile(audioBlob));
    
        // Convert WebM to AAC
        await ffmpeg.exec(['-i', 'audio.webm', '-c:a', 'aac', '-b:a', '64k', 'output.aac']);
    
        const data = await ffmpeg.readFile('output.aac');
        return new Blob([data.buffer], { type: 'audio/aac' });
    };

    const convertToMobileCompatibleMP3 = async (audioBlob) => {
        if (!ffmpeg.loaded) {
            await ffmpeg.load();
        }
    
        await ffmpeg.writeFile('audio.webm', await fetchFile(audioBlob));
    
        // Convert WebM to a mobile-compatible MP3
        await ffmpeg.exec([
            '-i', 'audio.webm',
            '-c:a', 'libmp3lame',
            '-b:a', '64k',
            '-ar', '44100',
            '-ac', '1',
            '-f', 'mp3',
            'output.mp3'
        ]);
    
        const data = await ffmpeg.readFile('output.mp3');
        const mp3Blob = new Blob([data.buffer], { type: 'audio/mpeg' });
        
    
        return mp3Blob;
    };

    const startRecording = async () => {
        try {
            console.log('Attempting to access microphone...');
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            console.log('Microphone access granted');
    
            // Try different MIME types in order of preference
            const mimeTypes = [
                'audio/webm',
                'audio/webm;codecs=opus',
                'audio/ogg;codecs=opus',
                'audio/mp4',
                'audio/mpeg',
            ];
    
            let selectedMimeType = null;    
            for (const mimeType of mimeTypes) {
                if (MediaRecorder.isTypeSupported(mimeType)) {
                    selectedMimeType = mimeType;
                    break;
                }
            }
    
            if (!selectedMimeType) {
                throw new Error('No supported MIME type found for this browser');
            }
    
            console.log(`Using MIME type: ${selectedMimeType}`);
            
            const mediaRecorder = new MediaRecorder(stream, { mimeType: selectedMimeType });
            console.log('MediaRecorder created');
            
            mediaRecorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    audioChunksRef.current.push(event.data);
                    console.log(`Audio chunk received, size: ${event.data.size} bytes`);
                }
            };
    
            mediaRecorder.onstop = () => {
                console.log('Recording stopped');
                const audioBlob = new Blob(audioChunksRef.current, { type: selectedMimeType });
                console.log(`Audio Blob created, size: ${audioBlob.size} bytes, type: ${audioBlob.type}`);
                setAudioBlob(audioBlob);
                setAudioPreviewUrl(URL.createObjectURL(audioBlob));
                audioChunksRef.current = [];
            };
    
            mediaRecorder.start();
            console.log('Recording started');
            setIsRecording(true);
            mediaRecorderRef.current = mediaRecorder;
        } catch (error) {
            console.error('Error accessing microphone:', error);
            toast.error('Unable to access microphone: ' + error.message);
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current && isRecording) {
            mediaRecorderRef.current.stop();
            setIsRecording(false);
            
            mediaRecorderRef.current.onstop = async () => {
                console.log('Recording stopped');
                const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
                console.log(`Audio Blob created, size: ${audioBlob.size} bytes`);
                
                // Create a preview URL for the audio
                const audioUrl = URL.createObjectURL(audioBlob);
                setAudioPreviewUrl(audioUrl);
                setAudioBlob(audioBlob);
                
                audioChunksRef.current = [];
            };
        }
    };

    const uploadAudioToWhatsApp = async (audioBlob) => {
        console.log('Starting upload to WhatsApp...', {
            size: audioBlob.size,
            type: audioBlob.type
        });
    
        try {
            // Attempt to convert WebM to mobile-compatible MP3
            let convertedBlob;
            try {
                convertedBlob = await convertToMobileCompatibleMP3(audioBlob);
                console.log('Converted to MP3, new size:', convertedBlob.size, 'bytes');
            } catch (conversionError) {
                console.warn('Conversion failed, using original audio:', conversionError);
                convertedBlob = audioBlob; // Use original if conversion fails
            }
    
            const formData = new FormData();
            formData.append('file', convertedBlob, 'audio.mp3');
            formData.append('type', 'audio/mpeg');
            formData.append('messaging_product', 'whatsapp');
    
            const response = await fetch(`https://graph.facebook.com/v19.0/${waPhoneNumberId}/media`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${SystemUserToken}`
                },
                body: formData
            });
            
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            
            const data = await response.json();
            console.log('Media ID:', data.id);
    
            // Send the audio message using the media ID
            const sendResult = await API.sendAudioToWhatsapp({
                mediaId: data.id,
                phoneNumberId: waPhoneNumberId,
                SystemUserToken: SystemUserToken,
                currentParticipantPhoneNumber: currentParticipantPhoneNumber,
                activePhoneNumber: wabaId,
                replyWamid: replyInfo ? replyInfo.wamid : null 
            });
            console.log('Audio message sent successfully:', sendResult);
            return sendResult;
        } catch (error) {

            console.error('Error uploading or sending audio:', error);
            toast.error('Failed to upload or send audio');
            return false;
        }
    };


    
    const sendVoiceMessage = async () => {
        if (!audioBlob) return;
        // upload audio to whatsapp and get media id
        setReplyInfo(null);
        const whatsappResponse = await uploadAudioToWhatsApp(audioBlob);
        if (whatsappResponse) {
            // Create a blob URL for the audio
            const wamId = whatsappResponse.messages[0].id;
            
            const audioUrl = URL.createObjectURL(audioBlob);
                
            // Send the audio to ChatArea
            setAudioToChatArea({
                url: audioUrl,
                senderId: wabaId,
                timestamp: new Date().toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
                media_type: 'audio',
                wamid: wamId,
            });

            // Convert WebM to mobile-compatible MP3
            const mp3Blob = await convertToMobileCompatibleMP3(audioBlob);
            console.log('Converted to MP3, new size:', mp3Blob.size, 'bytes');

            const formData = new FormData();
            formData.append('file', mp3Blob, 'audio.mp3');
            formData.append('content_type', 'audio/mpeg');
            formData.append('messaging_product', 'whatsapp');
            formData.append('reply_to_id', replyInfo ? replyInfo.id : null);

            formData.append('activePhoneNumber', wabaId);

            try {
                const response = await fetch(`https://streamhost-django-80bc172b3b26.herokuapp.com/api/chatroom/${activeChatRoomDetails}/messages/`, {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Token ' + userToken
                    },
                    body: formData
                });

                if (response.ok) {
                    const result = await response.json();
                    console.log('Voice message sent successfully:', result);
                    setAudioBlob(null);
                    setAudioPreviewUrl(null);
                } else {
                    console.error('Failed to send voice message:', response.statusText);
                }
            } catch (error) {
                toast.error('Error sending voice message');
            }
        }
    };


    
    // Handle template change
    const handleTemplateChange2 = (evt) => {
        setSelectedTemplate(evt.target.value);
        setWhatsappImageUrl(null);  // Reset image URL in case the new template doesn't have an image
        fetchTemplateParameters(evt.target.value); // Fetch parameters when template is selected
    };

    // Function to send WhatsApp message including image if present
    const sendWhatsAppTemplate = async () => {
        if (editBuildings.length === 0) {
            toast.error('Please add details for the user');
            return
        }

        if (!selectedTemplate) {
            toast.error('Please select a template.');
            return;
        }
        setIsTemplateButtonDisabled(true);

        const chatRoomId = activeChatRoomDetails;
        const templateMessage = {
            wabaId: wabaId,
            waPhoneNumberId: waPhoneNumberId,
            SystemUserToken: SystemUserToken,
            toWaNumber: currentParticipantPhoneNumber,
            templateName: selectedTemplate,
            imageUrl: whatsappImageUrl || null,
            chatRoomId: chatRoomId
        };

    
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/send-whatsapp-template/', {
                method: 'POST',
                headers: {
                    'Authorization': 'Token ' + userToken,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(templateMessage),
            });
    
            const data = await response.json();
            if (response.ok) {
                console.log('Message sent successfully:', data);    
                
                // Update chat area with the template message
                setMessageToChatArea({
                    text: data.message.text,
                    media_type: data.message.media_type,
                    wamid: data.whatsapp_response.messages[0].id,
                    timestamp: data.message?.timestamp
                });

                // Reset all parameters
                setSelectedTemplate('');
                setWhatsappImageUrl(null);
                
                toast.success('Message sent successfully!');
                setIsTemplateButtonDisabled(false);
                setWhatsappTemplateModal(false); // Close the modal after sending
            } else {
                toast.error(`Failed to send message: ${data.error}`);
                setIsTemplateButtonDisabled(false);
            }
    
        } catch (error) {
            console.error('Error sending WhatsApp template message:', error);
            toast.error('Failed to send message');
            setIsTemplateButtonDisabled(false);
        }
    };
    
    


    useEffect(() => {

        if (activeChatRoomDetails) {
            const fetchLastMessage = async () => { // {{ edit_1 }}
                try {
                    const lastMessage = await API.getClientLastMessage(activeChatRoomDetails, userToken);

                if (lastMessage?.error === 'No messages in this chatroom') {
                    setIsTextAreaDisabled(true);
                    return; 
                }
                
                const lastMessageTime = new Date(lastMessage?.timestamp);
                const currentTime = new Date();
                const timeDifference = currentTime - lastMessageTime; // Difference in milliseconds
                
                setIsTextAreaDisabled(timeDifference > 24 * 60 * 60 * 1000);  
                
                } catch (error) {
                    console.error('Error fetching last message for chatRoomId:', activeChatRoomDetails, error);
                    setIsTextAreaDisabled(false); 
                }
            }; // {{ edit_2 }}

            fetchLastMessage();
        }

    
    }, [currentParticipantPhoneNumber]);

    

  
    // UseEffect hooks -----------------------------------------------------------------------------------------
    useEffect(() => {
        

        if(userToken) {
            // Get data for contact edit and add contact
            const fetchBuildingAndUnit = async (user) => {
                // Add null check
                if (!user) {
                    console.warn('User object is null');
                    return;
                }

                // Add null check for phoneNumber
                if (!user.phoneNumber) {
                    console.warn('Phone number is missing');
                    return;
                }

                try {
                    const data = await API.getChatFlowList(userToken);
                    // Massflow data
                    const filteredData = data.filter(chatflow => chatflow.type === 'massflow' && chatflow.error_status===true).map(chatflow => ({value: chatflow.id, label: chatflow.chatflow_name}))
                    setMassFlowData(data.filter(chatflow => chatflow.type === 'massflow'));
                    setMassFlowOption(filteredData);
            
                    // Unit data
                    let mappedUnit = {};
                    let oriUnitList = {};
            
                    const fetchUnitNodes = async (chatflow) => {
                        try {
                            const response = await API.getNodes({ 'token': userToken, 'id': chatflow.value });
                            const unitNodes = response?.filter(node => node.node_type === 'unitNode');
                            
                            // Combine the text fields into a single array
                            const unitNodeComplete = unitNodes.reduce((acc, node) => {
                                const nodeTextArray = JSON.parse(node.text);
                                return [...acc, ...nodeTextArray];
                            }, []);
                            
                            oriUnitList[chatflow.label] = unitNodeComplete;
                            mappedUnit[chatflow.label] = unitNodeComplete.map(node => ({ value: node, label: node }));
                        } catch (error) {
                            console.error(error);
                        }
                    };
            
                    await Promise.all(filteredData.map(chatflow => fetchUnitNodes(chatflow)));
                    setUnitOption(mappedUnit);
                    setUnitList(oriUnitList);
            
                    // Fetch existing contact details
                    const existingContactDetails = await API.getExistingContactDetails({
                        'token': userToken,
                        'phone': editContactDetails.phoneNumber
                    });
                    setEditContactDetails({
                        phoneNumber: existingContactDetails.phone,
                        contactName: existingContactDetails.username,
                        buildings: existingContactDetails.buildings
                    });
                } catch(error) {
                    console.error(error);
                }
            }

            // Get contact length for limit purpose
            const getContactLength = async() => {
                try {
                    const data = await API.getClientUserList(userToken);
                    setClientUserLength(data.length);
                } catch (error) {
                    console.error(error);
                }
            }

            fetchBuildingAndUnit();
            getContactLength();
        }
    }, []);
  
    // This useEffect hook is use to handle dropdown menu, when user click outside the dropdown, the dropdown will be hidden
    useEffect(() => {
        function handleClickOutside(event) {
            // If the click is outside the dropdown, hide it
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
                setShowTranslateDropdown(false);
                setIsActive(false); 
            }
    
            if (commandRef.current && !commandRef.current.contains(event.target)) {
                setCommandWindow(false);
            }
    
            // Add emoji picker handling
            if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
                setShowEmojiPicker(false);
            }
        }
    
        // Add the event listener
        document.addEventListener('mousedown', handleClickOutside);
    
        // Cleanup function to remove the event listener
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleEmojiSelect = (emoji) => {
        const cursorPosition = textAreaRef?.current?.selectionStart || messageInput.length;
        const updatedText = 
            messageInput.slice(0, cursorPosition) + 
            emoji.native + 
            messageInput.slice(cursorPosition);
        
        setMessageInput(updatedText);
        setShowEmojiPicker(false);
    };

    useEffect(() => {
        if(editSelectedTemplate.value) {
            const fetchUnitNodes = async() => {
                try {
                    const response = await API.getNodes({'token': userToken, 'id': editSelectedTemplate.value});
                    const unitNodes = response.filter(node => node.node_type === 'unitNode');
                    // Create a Set to track unique labels
                    const uniqueLabels = new Set();
                    const uniqueUnitNodes = unitNodes.filter(node => {
                        const isUnique = !uniqueLabels.has(node.text);
                        uniqueLabels.add(node.text);
                        return isUnique;
                    });
                    setUnitOption(uniqueUnitNodes.map(node => ({value: node.id, label: node.text})));
                } catch (error) {
                    console.error(error);
                }
            }
            fetchUnitNodes();
        }
    }, [editSelectedTemplate, setEditSelectedTemplate]);


    // this useEffect is use to set the wabaid and phone number when userToken changes
    useEffect(() => {
        setWabaId(localStorage.getItem('waba_id'));
		setWaPhoneNumberId(localStorage.getItem('waPhoneNumberId'));
        // Inbox page will have error when refresh cuz usertoken not properly
        API.getUsers(userToken)
            .then(fetchedUsers => {
                setUsers(fetchedUsers);
            })
            .catch(error => {
                console.error('Error:', error);
            });
        

        const fetchCommands = async () => {
            try {
                const commands = await API.fetchCommand({'token': userToken});
                setCommand(commands);
            } catch (error) {
                console.error('Error fetching commands:', error);
            }
        }
        fetchCommands();

    }, [userToken]);


    // this use effect is use to set the current participant name and phone number when active chat room details changes
    useEffect(() => {
        if (activeChatRoomDetails) {        
            API.getChatRoomMessages(activeChatRoomDetails, userToken)
                .then(details => {
                setCurrentParticipantName(details.participant_name);
                setCurrentParticipantPhoneNumber(details.participant_phone_numbers); 
                setIsChatbotActive(details.chatbot_state);
            })
            .catch(error => {
                console.error('Error:', error);
            });
        }
        setImageToChatArea('');
    }, [activeChatRoomDetails]);  

    // useEffect whenever current participant changes
    useEffect(() => {

        // Regex function
        const getUserName = input => {
            // Regular expression to match the last number in the string
            const regex = /.*\d+/;
            const match = input?.match(regex);
            
            if (match) {
                // Get the index of the last number
                const lastNumberIndex = match[0].length;
                // Return the substring after the last number
                return input.substring(lastNumberIndex).trim();
            }
            
            // Return the original string if no number is found
            return input;
        }

        // Get ClientUser
        const setEditData = async (phone_number) => {
            try {
                // Prepare the data for edit
                const data = await API.getClientUser({'phone_number': phone_number, 'token': userToken});
                setCurrentParticipant(data);
                setEditClientUserId(data.id);
                setEditContactName(getUserName(data.name));
                const chatflow_list = data.chatflow_id ? JSON.parse(data.chatflow_id.replace(/'/g, '"')) : [];
                const checkin_list = data.checkin_datetime ? JSON.parse(data.checkin_datetime.replace(/'/g, '"')) : [];
                const checkout_list = data.checkout_datetime ? JSON.parse(data.checkout_datetime.replace(/'/g, '"')) : [];
                const unit_list = data.unit_no ? JSON.parse(data.unit_no.replace(/'/g, '"')) : [];
                let building_list = [];
                for (let i = 0; i < chatflow_list.length; i++) {
                    const checkin_date = checkin_list[i];
                    const checkout_date = checkout_list[i];
                    const unit = unit_list[i];
                    const chatflow = await API.getChatFlow({'token': userToken, 'id': chatflow_list[i]});
                    building_list.push({
                        'id': Date.now(),
                        'visible': true,
                        'checkIn': new Date(checkin_date),
                        'checkOut': new Date(checkout_date),
                        'unit': unit,
                        'sendCheckInAgain': false,
                        'sendCheckOutAgain': false,
                        'sendCheckIn': false,
                        'sendCheckOut': false,
                        'template': {'label': chatflow.chatflow_name, 'value': chatflow.id},
                    });
                }
                setEditBuildings(building_list);
                setClientRelatedTask(taskData?.filter((task) => task.task_title.includes(phone_number)));
            } catch (error) {
                console.error(error);
            }
        }
        if (currentParticipantPhoneNumber) {
            setEditData(currentParticipantPhoneNumber);
        }
    }, [currentParticipantPhoneNumber]);
    // ----------------------------------------------------------------------------------------------------------------
    

    // Modify the getInitial function to use getUserName
    const getUserName = (input) => {
        try {
            const regex = /.*\d+/;
            const match = input?.match(regex);

            if (match) {
                const lastNumberIndex = match[0].length;
                return input?.substring(lastNumberIndex).trim();
            }
            return input;
        } catch (error) {
            console.error(error);
        }
    };

    const getInitial = (name) => {

        if (name) {
            const actualName = getUserName(currentParticipantName[0]);
            return actualName?.charAt(0).toUpperCase();
        } else {
            return '?'
        };
    };

    // Button Functions ----------------------------------------------------------------------------------------------
    const handleChatRoomClick = useCallback((chatRoomId, chatRoom) => {
        console.log("Chatroom clicked: ", chatRoomId, chatRoom)
        setActiveChatRoomDetails(chatRoomId);
        setSelectedChatRoom(chatRoom);
        cacheData(CACHE_KEYS.SELECTED_ROOM, chatRoom);
        setMessageInput('');
        setMessageToChatArea(null);
        if (isMobile) {
            setActiveView('chat');
        }
    }, [isMobile, cacheData]);
  
    const handleBackClick = () => {
        if (activeView === 'profile') {
          setActiveView('chat');
        } else {
          setActiveView('inbox');
          setActiveChatRoomDetails(null);
        }
      };

      const handleProfileClick = () => {
        if (isMobile) {
          setActiveView('profile');
        } else {
          setIsColumn3Visible(!isColumn3Visible);
        }
      };

    
    const translateText = async (text, targetLang) => {
        const apiKey = 'AIzaSyAP854a-9kEJG5PvxKd2N7czNWXNH6y76o'; // Replace with your API key
        const detectUrl = `https://translation.googleapis.com/language/translate/v2/detect?key=${apiKey}`;
        const translateUrl = `https://translation.googleapis.com/language/translate/v2?key=${apiKey}`;
        
        try {
            // Detect the language
            const detectResponse = await axios.post(detectUrl, { q: text }, {
                headers: { 'Content-Type': 'application/json' }
            });
            
            const detectedLanguage = detectResponse.data.data.detections[0][0].language;
            
            // Translate the text
            const translateResponse = await axios.post(translateUrl, {
                q: text,
                target: targetLang,
                source: detectedLanguage
            }, {
                headers: { 'Content-Type': 'application/json' }
            });
    
            return translateResponse.data.data.translations[0].translatedText;
        } catch (error) {
            console.error('Error translating text:', error);
            toast.error('Translation error');
            return text;
        }
    };
    // ----------------------------------------------------------------------------------------------------------------


    //Function to handle file change, file button
    const handleFileChange = async (event) => {
        setShowDropdown(false);
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            
            // Create a preview URL for the file
            const fileReader = new FileReader();
            fileReader.onload = (e) => {
                setPreviewUrl(e.target.result);
            };
            fileReader.readAsDataURL(file);
        }
    };

    const sendMediaToWhatsApp = async (updatedMedia, messageId) => {
        if (!selectedFile) return;
    
        const media = updatedMedia;
        const fileExtension = media?.split('.').pop().toLowerCase();
        const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
        const videoExtensions = ['mp4', 'avi', 'mov', 'wmv'];
        const docExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx'];
        const audioExtensions = ['mp3', 'wav', 'ogg'];
    
        try {   
            let whatsappResponseBody;
            let mediaType;
    
            if (imageExtensions.includes(fileExtension)) {
                whatsappResponseBody = await API.sendImageToWhatsapp({
                    media: media,
                    waPhoneNumberId: waPhoneNumberId,
                    SystemUserToken: SystemUserToken,
                    currentParticipantPhoneNumber: currentParticipantPhoneNumber,
                    wabaId: wabaId,
                    replyWamid: replyInfo ? replyInfo.wamid : null,
                    caption: captionInput || null
                });
                mediaType = 'image';
            } else if (videoExtensions.includes(fileExtension)) {
                whatsappResponseBody = await API.sendVideoToWhatsapp({
                    media: media,
                    waPhoneNumberId: waPhoneNumberId,
                    SystemUserToken: SystemUserToken,
                    currentParticipantPhoneNumber: currentParticipantPhoneNumber,
                    wabaId: wabaId,
                    replyWamid: replyInfo ? replyInfo.wamid : null,
                    caption: captionInput || null
                });
                mediaType = 'video';
            } else if (docExtensions.includes(fileExtension)) {
                whatsappResponseBody = await API.sendDocumentToWhatsapp({
                    media: media,
                    waPhoneNumberId: waPhoneNumberId,
                    SystemUserToken: SystemUserToken,
                    currentParticipantPhoneNumber: currentParticipantPhoneNumber,
                    wabaId: wabaId,
                    replyWamid: replyInfo ? replyInfo.wamid : null,
                    caption: captionInput || null
                });
                mediaType = 'document';
            } else if (audioExtensions.includes(fileExtension)) {
                whatsappResponseBody = await API.sendAudioToWhatsapp({
                    media: media,
                    waPhoneNumberId: waPhoneNumberId,
                    SystemUserToken: SystemUserToken,
                    currentParticipantPhoneNumber: currentParticipantPhoneNumber,
                    wabaId: wabaId,
                    replyWamid: replyInfo ? replyInfo.wamid : null,
                    caption: captionInput || null
                });
                mediaType = 'audio';
            } else {
                console.error('Unsupported file extension:', fileExtension);
                return;
            }
    
            
            // Parse the response body
            let whatsappResponse;
            try {
                whatsappResponse = JSON.parse(whatsappResponseBody);
            } catch (parseError) {
                console.error('Error parsing WhatsApp response:', parseError);
                throw new Error('Invalid WhatsApp response: Unable to parse JSON');
            }
    
            if (whatsappResponse && whatsappResponse.messages && whatsappResponse.messages.length > 0) {
                const wamId = whatsappResponse.messages[0].id;
                const response = await API.updateChatRoomMessages(messageId, wamId, userToken);
                return wamId;
            } else {
                console.error('Invalid or empty WhatsApp response:', whatsappResponse);
                throw new Error('Invalid WhatsApp response: messages array is empty or undefined');
            }
        } catch (error) {
            console.error('Error sending media to WhatsApp:', error);
            toast.error('Failed to send media to WhatsApp: ' + error.message);
        }

        
    };

    //Funciton to handle send file
    const handleSendFile = async () => {

        if (selectedFile && !isSendingFile ) {

            setIsSendingFile(true);
            setReplyInfo(null);

            const fileExtension = selectedFile.name?.split('.').pop();
            const uniqueFileName = `${uuidv4()}.${fileExtension}`;

            // Create a new File object with the unique name
            const uniqueFile = new File([selectedFile], uniqueFileName, { type: selectedFile.type });

            const formData = new FormData();
            formData.append('image', uniqueFile);
            formData.append('message', captionInput);
            formData.append('activePhoneNumber', wabaId);
            formData.append('reply_to_id', replyInfo ? replyInfo.id : null);
            const baseUrl = "https://streamhost-media.s3.ap-southeast-2.amazonaws.com/chat_images/";
            
            
            const updatedImage = baseUrl + uniqueFileName;
            
            try { 
                const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/chatroom/' + activeChatRoomDetails + '/messages/', {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Token ' + userToken  
                    },
                    body: formData
                });

                if (response.ok) {
                    const result = await response.json();
                    console.log('File uploaded successfully:', result);
                    const wamid = await sendMediaToWhatsApp(updatedImage, result.message_id);
                    setImageToChatArea({
                        media: baseUrl + uniqueFileName, 
                        wamid: wamid
                    });
                    // Clear the preview after sending
                    setPreviewUrl('');
                    setSelectedFile(null);
                } else {
                    console.error('Failed to upload file:', response.statusText);
                    setIsSendingFile(false);
                }
            } catch (error) {
                setIsSendingFile(false);
                console.error('Error uploading file:', error);
            }
        }
    };


    //Function to handle the input change for the chat-box
    const handleInputChange = useCallback((event, type) => {
        // Add command function here
        if (type === 'whatsapp') {
            const text = event.target.value;
            if (text.startsWith('/')) {
    
                if (text === '/') {
                    setFilterCommand(command);
                } else {
                    setFilterCommand(command.filter(command => command.command.startsWith(text.slice(1))));
                }
                setCommandWindow(true);
            } else {
                setCommandWindow(false);
            }
        }

        if (type === 'private') {
            const text = event.target.value;
            if (text.includes('@')) {
    
                if (text === '@') {
                    setTagWindow(true);
                    setFilterAccountList(secondaryAccountList)
                } else {
                    const regex = /@(\w+)/g;
                    const match = text.match(regex);
                    const matchedText = match ? match[0] : ''
                    setFilterAccountList(secondaryAccountList.filter(user => user.username.startsWith(matchedText?.slice(1))));
                }
                setTagWindow(true);
            } else {
                setTagWindow(false);
            }
        }
        setMessageInput(event.target.value);
    });

   

    //declare new pop up window function for add contact
    const [isModalOpen, setIsModalOpen] = useState(false);

    const closeModal = () => {
        setIsModalOpen(false);
    };

  // Function to fetch building and unit data
    const fetchBuildingAndUnit = async () => {
        try {
        const data = await API.getChatFlowList(userToken);
        // Massflow data
        const filteredData = data
            .filter(chatflow => chatflow.type === 'massflow' && chatflow.error_status === true)
            .map(chatflow => ({
            value: chatflow.id,
            label: chatflow.chatflow_name
            }));
        
        setMassFlowData(data.filter(chatflow => chatflow.type === 'massflow'));
        setMassFlowOption(filteredData);

        // Unit data
        let mappedUnit = {};
        let oriUnitList = {};

        const fetchUnitNodes = async (chatflow) => {
            try {
            const response = await API.getNodes({ 'token': userToken, 'id': chatflow.value });
            const unitNodes = response?.filter(node => node.node_type === 'unitNode');
            
            const unitNodeComplete = unitNodes.reduce((acc, node) => {
                const nodeTextArray = JSON.parse(node.text);
                return [...acc, ...nodeTextArray];
            }, []);
            
            oriUnitList[chatflow.label] = unitNodeComplete;
            mappedUnit[chatflow.label] = unitNodeComplete.map(node => ({ 
                value: node, 
                label: node 
            }));
            } catch (error) {
            console.error('Error fetching unit nodes:', error);
            }
        };

        await Promise.all(filteredData.map(chatflow => fetchUnitNodes(chatflow)));
        setUnitOption(mappedUnit);
        setUnitList(oriUnitList);
        } catch (error) {
        console.error('Error fetching building data:', error);
        }
    };

    // Load data when component mounts
    useEffect(() => {
        if (userToken) {
        fetchBuildingAndUnit();
        }
    }, [userToken]);

    const handleOpenAdd = async () => {
        try {
        const response = await API.fetchProfile(userToken);
        const contactUsed = response.contact_used;
        if (contactUsed >= planContactLimit[subscriptionPlan]) {
            toast.error('You have reached the maximum number of contacts this month! Please consider add-ons or wait next month');
            return;
        }
        } catch (error) {
        console.error('Error checking contact limit:', error);
        }
        
        setIsModalOpen(true);
    };
    
    //Function to show the menu for file section
    const handleButtonClick = (e) => {
        e.preventDefault(); // Prevent form submission
        setIsActive(prevState => !prevState); // Toggle active state for the button
        setShowDropdown(prevState => !prevState); // Toggle dropdown visibility
    };    

    //Function to show the menu for file section
    const handleSelection = (type) => {
        const MAX_VIDEO_SIZE = 16 * 1024 * 1024;
        if (!isMobile) {
            // Your existing logic for desktop
            let acceptType = '';
            switch (type) {
                case 'image':
                    acceptType = 'image/*';
                    break;
                case 'video':
                    acceptType = 'video/*';
                    fileInputRef.current.onchange = (e) => {
                        const file = e.target.files[0];
                        if (file && file.size > MAX_VIDEO_SIZE) {
                            toast.error('Video file size must not exceed 16MB');
                            // Clear the file input
                            e.target.value = '';
                            return;
                        }
                    };
                    break;
                case 'document':
                    acceptType = '.pdf,.doc,.docx,.xls,.xlsx';
                    break;
                default:
                    acceptType = '';
            }
            fileInputRef.current.accept = acceptType;
            fileInputRef.current.click();
        }
        // For mobile, we'll let the MobileImageSelector handle the file selection
    };

    //Function to send message to whatsapp and save the message
    const sendMessageToWhatsappAndSave = async (message, phoneNumberId, SystemUserToken, currentParticipantPhoneNumber, wabaId, activeChatRoomDetails, userToken) => {
        try {
            setMessageInput('');
            setReplyInfo(null);
            const whatsappResponse = await API.sendMessageToWhatsapp({
                message: message,
                phoneNumberId: phoneNumberId,
                SystemUserToken: SystemUserToken,
                currentParticipantPhoneNumber: currentParticipantPhoneNumber,
                replyWamid: replyInfo ? replyInfo.wamid : null 
            });
                
            // Extract the message ID from the response
            const wamId = whatsappResponse.messages[0].id;

            setMessageToChatArea({
                text: message,
                media_type: 'text',
                wamid: wamId,
                reply_to_id: replyInfo ? replyInfo.id : null
            });

            await API.saveChatRoomMessages({
                message: message,
                chatRoomId: activeChatRoomDetails,
                userToken: userToken,
                wabaId: wabaId,
                wamId: wamId,
                type: 'whatsapp',
                reply_to_id: replyInfo ? replyInfo.id : null
            });

            
            
        } catch (error) {
            console.error("Error sending message or saving chat room messages:", error);
        }
    }

    // Send private message for internal team
    const sendPrivateMessage = async (message, wabaId, activeChatRoomDetails, userToken) => {
        setMessageToChatArea({
            text: message,
            media_type: 'text',
            type: 'private',
            wamid: null,
            reply_to_id: null
        });

        await API.saveChatRoomMessages({
            message: message,
            chatRoomId: activeChatRoomDetails,
            userToken: userToken,
            wabaId: wabaId,
            wamId: null,
            type: 'private',
            reply_to_id: null
        });
    }

    // Set streamhost message to China Streamhost user 
    const sendStreamHostMessage = async (message, wabaId, activeChatRoomDetails, userToken) => {
        await API.saveChatRoomMessages({
            message: message,
            chatRoomId: activeChatRoomDetails,
            userToken: userToken,
            wabaId: wabaId,
            wamId: null,
            type: 'streamhost',
            reply_to_id: null
        });
    }

    //This function triggers when user deletes a chatroom
    function handleChatRoomDelete() {
        setActiveChatRoomDetails(null); 
        setSelectedChatRoom(null);
    }

    // Rerender function
    const [forceUpdate, setForceUpdate] = useState(0);

    const triggerReRender = () => {
        setForceUpdate(prev => prev + 1); // Incrementing the counter to change state
    };

    //preview file icon
    const getFileIcon = (fileType) => {
        switch (fileType) {
            case 'application/pdf':
                return <FaFilePdf />;
            case 'application/msword':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return <FaFileWord />;
            case 'application/vnd.ms-excel':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                return <FaFileExcel />;
            case 'application/vnd.ms-powerpoint':
            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                return <FaFilePowerpoint />;
            default:
                return <FaFile />;
        }
    };

    // Function for edit contact
    const handleEditInbox = async() => {
        if(userToken) {
            // Get data for contact edit and add contact
            const fetchBuildingAndUnit = async() => {
                try {
                    const data= await API.getChatFlowList(userToken);
                    // Massflow data
                    const filteredData = data.filter(chatflow => chatflow.type === 'massflow' && chatflow.error_status===true).map(chatflow => ({value: chatflow.id, label: chatflow.chatflow_name}))
                    setMassFlowData(data.filter(chatflow => chatflow.type === 'massflow'));
                    setMassFlowOption(filteredData);

                    // Unit data
                    let mappedUnit = {};
                    let oriUnitList = {};

                    const fetchUnitNodes = async (chatflow) => {
                        try {
                            const response = await API.getNodes({ 'token': userToken, 'id': chatflow.value });
                            const unitNodes = response?.filter(node => node.node_type === 'unitNode');

                            // Combine the text fields into a single array
                            const unitNodeComplete = unitNodes.reduce((acc, node) => {
                                const nodeTextArray = JSON.parse(node.text);
                                return [...acc, ...nodeTextArray];
                            }, []);

                            oriUnitList[chatflow.label] = unitNodeComplete;
                            mappedUnit[chatflow.label] = unitNodeComplete.map(node => ({ value: node, label: node }));
                        } catch (error) {
                            console.error(error);
                        }
                    };

                    await Promise.all(filteredData.map(chatflow => fetchUnitNodes(chatflow)));
                    setUnitOption(mappedUnit);
                    setUnitList(oriUnitList);
                } catch(error) {
                    console.error(error);
                }
            }
            fetchBuildingAndUnit();
        }
        setIsEditModalOpen(true);
    }

    const addContactButton = useAddContact({ userToken, contactName, phoneNumber, phoneError, buildings, waPhoneNumberId, userId, SystemUserToken, wabaId, waPhoneNumber, setIsButtonDisabled, isButtonDisabled, setEditContactName, setEditBuildings, setExistingContactData, setIsModalOpen, setIsEditModalOpen });

    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [showDeleteContactConfirmation, setShowDeleteContactConfirmation] = useState(false);
    const [contactToDelete, setContactToDelete] = useState(null);

    const handleDeleteInbox = async () => {
        try {
            if (!contactToDelete) {
                console.error('No contact selected for deletion');
                return;
            }
    
            const clientId = contactToDelete.id; 
    
            if (!clientId) {
                console.error('Client user ID is undefined:', contactToDelete);
                return;
            }
    
            console.log('Deleting client user with ID:', clientId);
            try {
                await API.deleteClientUser({ token: userToken, id: clientId });
            } catch (error) {
                console.error('Error deleting client user:', error);
                return;
            }
    
            try {
                await API.removeChatRoom({ token: userToken, id: clientId });
            } catch (error) {
                console.error('Error removing chat room:', error);
                return;
            }
    
            setSelectedChatRoom(null);
            setActiveChatRoomDetails(null); 
            setShowDeleteConfirmation(false);
            console.log('Contact deleted successfully');
        } catch (error) {
            console.error('Error deleting contact:', error);
        }
    };
    
    const handleDeleteButton = () => {
        setShowDeleteContactConfirmation(true);
    };
    
    const handleDeleteConfirm = async () => {
        try {
            const participantId = selectedChatRoom?.participants[0]?.id;
            
            if (!participantId) {
                throw new Error('No participant ID found');
            }
            
            await API.deleteChatRoom(participantId, userToken);
            
            
            setSelectedChatRoom(null);
            setActiveChatRoomDetails(null);
            setShowDeleteContactConfirmation(false);
            
            toast.success("Contact deleted", {position: 'bottom-center'});
            
            
            window.location.reload();
    
        } catch (error) {
            toast.error("Failed to delete chatroom");
            console.error('Error:', error);
        }
    };

    //Function to handle translate click
    const handleTranslateClick = async (lang) => {
        if (messageInput) {
            const translated = await translateText(messageInput, lang);
            setMessageInput(translated);
        }
    
        setShowTranslateDropdown(false);
    };

    //Function to handle scrolling
    const handleInput = (e) => {
        let target = e.target;
        target.style.height = 'auto';
        target.style.height = (target.scrollHeight) + 'px';
    };

    // Add todolist from plus icon
    const openTodolist = () => {
        setSelectedTag(todolistOption[0]);
        setTodolistModal(true);
    };
    const handleCloseToDoList = () => {
        setTodolistModal(false);
    };

    const openWhatsappTemplate = () => {
        setWhatsappTemplateModal(true);
    };
    const handleCloseWhatsappTemplate = () => {
        setWhatsappTemplateModal(false);
    };
    
    const addTodolist = async() => {
        if (todolistDescription === '' || selectedTag === ''){
            toast.error('Please fill in all the fields');
            return;
        }
        if (phoneError) {
            toast.error('Invalid phone number');
            return;
        }

        let title='';
        if (todolistPhone) {
            title = 'Phone: ' + todolistPhone.replace(/^\+/, '').replace(/\s+/g, '') + '\n\n' + 'Task: ' + todolistDescription;
        } else {
            title = 'Task: ' + todolistDescription;
        }

        try {
            const response = await API.addTask({
                'token': userToken,
                'task_id': 'TASK-' + Date.now(),
                'task_type': selectedTag,
                'task_title': title,
            });
            toast.success('Task added successfully');
        } catch (error) {
            toast.error('Error adding task:', error);
        }
    }

    const handlePhoneChange2 = (e) => {
        let input = e.target.value;
        if (input.length === 1 && input !== '+') {
            input = '+' + input;
        }
        setTodolistPhone(input);

        try {
            if (input) {
                const parsedNumber = parsePhoneNumber(input);
                if (isValidPhoneNumber(input)) {
                    setTodolistPhone(parsedNumber.formatInternational());
                    setPhoneError('');
                } else {
                    setPhoneError('Invalid phone number');
                }
            } else {
                setPhoneError('');
            }
        } catch (error) {
            setPhoneError('Invalid phone number');
        }
    };

    //Function to handle chatbot
    // State for toggle
    // Toggle handler
    const handleToggleChatbot = () => {
        setIsChatbotActive(!isChatbotActive);
        API.toggleChatbot(activeChatRoomDetails)
            .then((response) => {
                console.log(response);
            }).catch((error) => {
                toast.error('Error toggling chatbot:');
                console.error(error);
            });
    };

    const generateReply = async() => {
        try {
            setIsGenerating(true);
            console.log(activeChatRoomDetails);
            const chatMessageList = await API.getChatRoomMessagesNew(activeChatRoomDetails, userToken, 1);
            const guestMessageList = chatMessageList.messages.filter(message => message.sender.phone_number);

            let latestText = ''
            if (guestMessageList) {
                latestText = guestMessageList[0].text
            } else {
                latestText = "Guest hasn't reply anything yet, generate a text that prompts user to reply"
            }

            const response = await API.getAiAssist({
                'text': latestText,
                'profile_id': profileId,
                'client_phone': currentParticipantPhoneNumber[0],
                'waba_id': wabaId
            })

            console.log(response);
            setMessageInput(response.generated_message);
        } catch (error) {
            console.error(error);
        } finally {
            setIsGenerating(false); // Add this line
        }
    }

    // Function for add contact state handling ---------------------------------  
    const deleteBuilding = (index, id) => {
        console.log(buildings);
        setBuildings(prevBuildings => 
            prevBuildings.map(building => {
                console.log(building.id, id);
                return building.id === id ? { ...building, visible: false } : building;
            })
        );
        setTimeout(() => {
            setBuildings(prevBuildings => prevBuildings.filter(building => building.id !== id));
        }, 300); // This should match your transition duration
    }

    const handleAddBuilding = () => {
        setBuildings(prevBuildings => [...prevBuildings, { id: Date.now(), visible: false}]);
        setTimeout(() => {
            setBuildings(prevBuildings => 
                prevBuildings.map(building => 
                    building.visible ? building : { ...building, visible: true, sendCheckIn: false, sendCheckOut: false }
                )
            );
        }, 1);
    }

    const handlePhoneChange = (e) => {
        let input = e.target.value;
        if (input.length === 1 && input !== '+') {
            input = '+' + input;
        }
        setPhoneNumber(input);

        try {
            if (input) {
                const parsedNumber = parsePhoneNumber(input);
                if (isValidPhoneNumber(input)) {
                    setPhoneNumber(parsedNumber.formatInternational());
                    setPhoneError('');
                } else {
                    setPhoneError('Invalid phone number');
                }
            } else {
                setPhoneError('');
            }
        } catch (error) {
            setPhoneError('Invalid phone number');
        }
    };

    const handleTemplateChange = (index, selectedOption) => {
        setBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                template: selectedOption
            };
            return updatedBuildings;
        });
    };

    const handleUnitChange = (index, selectedOption) => {
        setBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                unit: selectedOption.label
            };
            return updatedBuildings;
        });
    };

    const handleCheckInDateChange = (index, date) => {
        setBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                checkIn: date
            };
            return updatedBuildings;
        });
    };

    const handleCheckOutDateChange = (index, date) => {
        setBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                checkOut: date
            };
            return updatedBuildings;
        });
    };

    const handleSendCheckIn = (index, selectedOption) => {
        setBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                sendCheckIn: selectedOption.target.checked
            };
            return updatedBuildings;
        });
    }

    const handleSendCheckOut = (index, selectedOption) => {
        setBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                sendCheckOut: selectedOption.target.checked
            };
            return updatedBuildings;
        });
    }


    
    // --------------------------------------------------------------------------


    // Function for edit contact state handling ---------------------------------
    const deleteEditBuilding = (index, id) => {
        setEditBuildings(prevBuildings => 
            prevBuildings.map(building => {
                console.log(building.id, id);
                return building.id === id ? { ...building, visible: false } : building;
            })
        );
        setTimeout(() => {
            setEditBuildings(prevBuildings => prevBuildings.filter(building => building.id !== id));
        }, 300); // This should match your transition duration
    }

    const handleEditAddBuilding = () => {
        setEditBuildings(prevBuildings => [...prevBuildings, { id: Date.now(), visible: false, sendCheckInAgain: false, sendCheckOutAgain: false, sendCheckIn: false, sendCheckOut: false}]);
        setTimeout(() => {
            setEditBuildings(prevBuildings => 
                prevBuildings.map(building => 
                    building.visible ? building : { ...building, visible: true }
                )
            );
        }, 1);
    }

    const handleEditTemplateChange = (index, selectedOption) => {
        setEditBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                template: selectedOption
            };
            return updatedBuildings;
        });
    };

    const handleEditUnitChange = (index, selectedOption) => {
        setEditBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                unit: selectedOption.label
            };
            return updatedBuildings;
        });
    };

    const handleEditCheckInDateChange = (index, date) => {
        setEditBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                checkIn: date
            };
            return updatedBuildings;
        });
    };

    const handleEditCheckOutDateChange = (index, date) => {
        setEditBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                checkOut: date
            };
            return updatedBuildings;
        });
    };

    const handleEditSendCheckIn = (index, selectedOption) => {
        setEditBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                sendCheckIn: selectedOption.target.checked
            };
            return updatedBuildings;
        });
    }

    const handleEditSendCheckOut = (index, selectedOption) => {
        setEditBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                sendCheckOut: selectedOption.target.checked
            };
            return updatedBuildings;
        }); 
    }

    const handleEditSendCheckInAgain = (index, selectedOption) => {
        setEditBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                sendCheckInAgain: selectedOption.target.checked
            };
            return updatedBuildings;
        });
    }

    const handleEditSendCheckOutAgain = (index, selectedOption) => {
        setEditBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                sendCheckOutAgain: selectedOption.target.checked
            };
            return updatedBuildings;
        }); 
    }

    const editContact = async() => {

        // Validations
        if (editBuildings.length === 0) {
            toast.error('Please add at least one building');
            return;
        }
        

        // Prepare data to send to API while validating form
        let checkin = [];
        let checkout = [];
        let unit = [];
        let templateLabel = [];
        let templateValue = [];
        let checkInBoolean = [];
        let checkOutBoolean = [];
        let massflowCheckInBoolean = [];
        let massflowCheckOutBoolean = [];
        let checkInList = [];
        let checkOutList = [];
        let status = true;
        editBuildings.map(building => {

            // Validations
            if (!building.template || !building.unit || !building.checkIn || !building.checkOut) {
                toast.error('Please fill in all the fields');
                status = false;
                return;
            }
            
            if (!unitList[building.template.label]?.includes(building.unit)) {
                status = false;
                toast.error(`Please update unit for ${building.template.label}`);
                return;
            }

            // Check date validation
            const checkIn = new Date(building.checkIn);
            const checkOut = new Date(building.checkOut);
            if (checkIn >= checkOut) {
                status = false;
                toast.error('Check-out date must be after check-in date');
                return;
            }

            // If data no problem, append to list for API data preparation
            checkin.push(checkIn);
            checkout.push(checkOut);
            unit.push(building.unit);
            templateLabel.push(building.template.label);
            templateValue.push(building.template.value);
            checkInBoolean.push(building.sendCheckIn || !building.sendCheckInAgain);
            console.log(!building.sendCheckInAgain);
            checkOutBoolean.push(building.sendCheckOut || !building.sendCheckOutAgain);
            massflowCheckInBoolean.push(building.sendCheckIn);
            massflowCheckOutBoolean.push(building.sendCheckOut);
            checkInList.push('False');
            checkOutList.push('False');
        });

        // If everything goes well and pass the validation, then only edit contact
        if (!status) {
            return;
        }
        // Set contact Name
        // Initialize the formatted contact name
        let formattedContactName = "";
        // Iterate over the arrays and format the contact name
        for (let i = 0; i < templateLabel.length; i++) {
            if (i > 0) {
                formattedContactName += ", ";
            }
                // Reformat date and add user into contact
            let checkInDate = checkin[i];
            let checkOutDate = checkout[i];
            let checkInDay = checkInDate.getDate().toString().padStart(2, '0');
            const checkInMonth = (checkInDate.getMonth() + 1).toString().padStart(2, '0'); 
            const formattedCheckInDate = `${checkInDay}/${checkInMonth}`;
            let checkOutDay = checkOutDate.getDate().toString().padStart(2, '0');
            const checkOutMonth = (checkOutDate.getMonth() + 1).toString().padStart(2, '0'); 
            const formattedCheckOutDate = `${checkOutDay}/${checkOutMonth}`;

            formattedContactName += `${templateLabel[i]} ${unit[i]} ${formattedCheckInDate}-${formattedCheckOutDate}`;
        }

        // Append the contact name
        formattedContactName += ` ${editContactName}`;

        // Call API to edit contact
        try {
            const response = await API.editClientUser({
                'token': userToken,
                'id': editClientUserId,
                'name': formattedContactName,
                'unit_no': unit,
                'checkin_datetime': checkin,
                'checkout_datetime': checkout,
                'checkin_status': false,
                'checkout_status': false,
                'checkin_list': checkInBoolean,
                'checkout_list': checkOutBoolean,
                'chatflow_id': templateValue,
            });
            setCurrentParticipantName([formattedContactName]);
            setIsEditModalOpen(false);
            // Send message immediately if slide button is checked
            // 1. Check in list
            massflowCheckInBoolean.map(async(checkIn, index) => {
                if (checkIn) {
                    // Send checkin massflow message;
                    const response = await API.massFlowAlgorithm({
                        'user_id': userId,
                        'index': index,
                        'chatflow_id': templateValue[index],
                        'unit': unit[index],
                        'waba_id': wabaId,
                        'phone_number_id': waPhoneNumberId,
                        'to_phone_number': currentParticipantPhoneNumber[0].replace(/\s+/g, '').replace('+', ''),
                        'type': 'checkInNode'
                    })
                }
            })

            // 2. Check out list
            massflowCheckOutBoolean.map(async(checkOut, index) => {
                if (checkOut) {
                    // Send checkout massflow message
                    const response = await API.massFlowAlgorithm({
                        'user_id': userId,
                        'index': index,
                        'chatflow_id': templateValue[index],
                        'unit': unit[index],
                        'waba_id': wabaId,
                        'phone_number_id': waPhoneNumberId,
                        'to_phone_number': currentParticipantPhoneNumber[0].replace(/\s+/g, '').replace('+', ''),
                        'from_phone_number': waPhoneNumber,
                        'type': 'checkOutNode'
                    })
                }
            })

            toast.success('Contact updated successfuly');
        } catch (error) {
            console.error(error);
            toast.error('Something wrong happened')
        }
    }
    // --------------------------------------------------------------------------

    // Search function------------------------------------------------------------
    const [searchQuery, setSearchQuery] = useState('');

    const placeholderText = isTextAreaDisabled 
        ? "More than 24 hours have passed since the recipient last replied to you." 
        : "Shift + Enter for new line. Start with '/' to reply with Quick Response";

    const [activeTab, setActiveTab] = useState('reply');
    // ----------------------------------------------------------------------------


    // Task function --------------------------------------------------------------
    const updateTask = async (data) => {
        try {
            const taskData = await API.updateTask(data);
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const removeTask = async(id) => {
        setClientRelatedTask((tasks) => tasks.filter(task => task.id !== id));

        // Update task status
        const now = new Date();
        let nowISOString = now.toISOString();
        let nowFormatted = nowISOString.replace('T', ' ').replace('Z', '+00:00');
        const data = {
            'token': userToken,
            'id': id,
            'complete_date': nowFormatted,
            'completed': true,
        }
        updateTask(data);
    }

    const [isColumn3Visible, setIsColumn3Visible] = useState(true);
    

    // Send Tag Notification Function --------------------------------------------
    const sendTagNotification = async(taggedProfile) => {
        try {
            const resp = await API.sendTagNotification({
                'userToken': userToken,
                'tagFromId': profileId,
                'taggedId': taggedProfile.id,
                'client_username': currentParticipantName,
                'client_phone_num': currentParticipantPhoneNumber[0],
            });
            console.log(resp);
        } catch (error) {
            console.error(error);
        }
    }

    const handleBuildingFilterChange = (selected) => {
        setSelectedBuildingFilters(selected);
        setSelectedUnitFilters([]); // Reset unit selection when buildings change
    };
    
    const handleUnitFilterChange = (selected) => {
        setSelectedUnitFilters(selected);
    };
    
    const handleApplyFilters = useCallback(() => {
        triggerReRender();
    }, []);
    
    const handleClearFilters = () => {
        setSelectedBuildingFilters([]);
        setSelectedUnitFilters([]);
        setCheckInStartDate(null);
        setCheckInEndDate(null);
        triggerReRender();
    };

    const handleChannelChange = (e) => {
        const value = e.target.value;
        if (value === 'whatsapp' || value === 'streamhost') {
            console.log('Selected channel:', value);
        }
    };

    // Clear cache on logout or component unmount
    useEffect(() => {
        return () => {
            Object.values(CACHE_KEYS).forEach(key => {
                localStorage.removeItem(key);
            });
        };
    }, []);

    // Add this to your existing options
    const filterOptions = [
        { value: 'new', label: 'New Contacts' },
        ...massFlowOption  // Your existing building options
    ];


    const memoHandleReply = useCallback((text) => {
        handleReply(text);
    }, []);
    const memoizedProps = useMemo(() => ({
        MessageToChatArea,
        ImageToChatArea,
        AudioToChatArea,
        socketRef,
        replyText,
        isSendingFile,
        setIsSendingFile,
        setSelectedFile,
        setPreviewUrl,
        onReply: memoHandleReply,
        setCaptionInput,
        captionInput
    }), [
        MessageToChatArea,
        ImageToChatArea,
        AudioToChatArea,
        socketRef,
        replyText,
        isSendingFile,
        setIsSendingFile,
        setSelectedFile,
        setPreviewUrl,
        handleReply,
        setCaptionInput,
        captionInput
    ]);

    

    // Modify your ChatRoomList component usage to pass the filter
    <ChatRoomList 
        filters={{
            buildings: selectedBuildingFilters.filter(f => f.value !== 'new'),
            units: selectedUnitFilters,
            showNewContacts: selectedBuildingFilters.some(f => f.value === 'new')
        }}
    />

    return (
        <React.Fragment>

            <Helmet>
                <title>StreamHost | Inbox Page</title>
            </Helmet>

            {!isMobile || activeView === 'inbox' ? <Sidenav taskCounts={taskCounts}/> : null}
            <ToastContainer style={{zIndex: '100000001'}} position='bottom-center' autoClose={5000} hideProgreeBar={false} closeOnClick pauseOnFocusLoss draggable rtl={false} pauseOnHover theme='colored' transition={Bounce} /> 
            <main className="inbox-main">
                {wabaId && (
                    <div class="inbox-main-div" >
                        <div className={`inbox-column1 ${activeView === 'inbox' ? 'active' : ''}`}>
                            <div class="inbox-header">
                                <h2>Inbox</h2>
                                <div className="channel-select">
                                <select onChange={handleChannelChange} defaultValue="whatsapp">
                                    <option value="all-option">All</option>
                                    <option value="whatsapp"><FaWhatsapp />WhatsApp</option>
                                    <option value="email" disabled>
                                        Email
                                    </option>
                                    <option value="instagram" disabled>
                                        Instagram
                                    </option>
                                    <option value="messenger" disabled>
                                        Messenger
                                    </option>
                                    <option value="streamhost" disabled>StreamHost</option>
                                </select>
                            </div>
                            </div>

                            <div className="search-bar">
                                <div className="input-container">
                                <input type="text" id="searchInput" placeholder="Search in rooms" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)}/>
                                <label for="searchInput">Search in rooms</label>
                                </div>
                                <IoMdPersonAdd onClick={handleOpenAdd} className='inbox-add-contact' style={{fontSize: '1.7rem'}}/>
                            </div>

                            <div className="filter-container">
                                <div className="filter-wrapper">
                                    <div className="filter-group">
                                        <div className="filter-select-wrapper">
                                            <Select 
                                                className="filter-select"
                                                placeholder="Select building..."
                                                options={filterOptions} 
                                                isMulti 
                                                value={selectedBuildingFilters} 
                                                onChange={handleBuildingFilterChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="filter-group">
                                        <div className="filter-select-wrapper">
                                            <Select 
                                                className="filter-select"
                                                placeholder="Select unit..."
                                                options={unitOption[selectedBuildingFilters?.[0]?.label] || []} 
                                                isMulti 
                                                value={selectedUnitFilters} 
                                                onChange={handleUnitFilterChange} 
                                                isDisabled={!selectedBuildingFilters?.length}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="inbox-list">
                                <ChatRoomList 
                                    onChatRoomClick={handleChatRoomClick} 
                                    onChatRoomDelete={handleChatRoomDelete} 
                                    onChatRoomRerender={triggerReRender} 
                                    searchQuery={searchQuery}
                                    filters={{
                                        buildings: selectedBuildingFilters.filter(f => f.value !== 'new'),
                                        units: selectedUnitFilters,
                                        showNewContacts: selectedBuildingFilters.some(f => f.value === 'new')
                                    }}
                                    massFlowOption={massFlowOption}
                                />
                            </div>
                            
                        </div>
                    
                        <div className={`inbox-column2 ${activeView === 'chat' ? 'active' : ''}`}>
                            {activeChatRoomDetails && (
                                <><div className="inbox-header2">
                                    {isMobile && (
                                        <button className="back-button" onClick={handleBackClick}> <IoIosArrowBack /> </button>
                                    )}
                                        <div className='main-inbox-header'>
                                        <h3>{getUserName(currentParticipantName[0])}</h3>
                                        <div className="inbox-header-button">
                                        {chatGPTFunction && (
                                            <div className="toggle-container" onClick={handleToggleChatbot}>
                                                <div className={`toggle-switch ${isChatbotActive ? 'active' : ''}`}>
                                                <div className="toggle-slider"></div>
                                                <FaRobot className="toggle-robot" />
                                                </div>
                                            </div>
                                        )}
                                            <button 
                                            className="three-dot-menu"
                                            onClick={handleProfileClick}
                                            >
                                            <BsThreeDotsVertical />
                                        </button>
                                        </div>
                                        </div>
                                    </div>
                                    <div className={`chatbot-active-bar ${!isChatbotActive ? 'chatbot-is-active' : ''}`}>
                                        <span className="icon-wrapper" role="img" aria-label="robot">
                                        <FaRobot />
                                        </span>
                                        <span className="status-text">ChatBot is active</span>
                                    </div>
                                </>
                            )}
                            <div class="chat-area">
                                <ChatAreaSection 
                                    activeChatRoomDetails={activeChatRoomDetails}
                                    {...memoizedProps}
                                />
                            </div> 

                            {/* Text area section --------------------------------------------------------------------------------------------------------- */}
                            {activeChatRoomDetails && (
                                <div className="chat-box">
                                    <div className="tabs-container"> 
                                    <button className={`tab-button ${activeTab === 'reply' ? 'active' : ''}`} onClick={() => setActiveTab('reply')}>
                                        Reply
                                    </button>
                                    <button className={`tab-button ${activeTab === 'privateNote' ? 'active' : ''}`} onClick={() => setActiveTab('privateNote')}>
                                        Private Note
                                    </button>
                                    </div>
                                    {activeTab === 'reply' && (
                                        <>
                                            <form onSubmit={(event) => {
                                                event.preventDefault();
                                                const textToSend = messageInput;
                                                if (textToSend.trim() === '') {
                                                    toast.error('Message input cannot be empty.');
                                                    return;
                                                }
                                                
                                                // Send message to different type of chatrooms
                                                if (selectedChatRoom.chatroom_type === 'whatsapp') {
                                                    sendMessageToWhatsappAndSave(textToSend, waPhoneNumberId, SystemUserToken, currentParticipantPhoneNumber, wabaId, activeChatRoomDetails, userToken)
                                                        .catch((error) => {
                                                            console.error("Failed to send message:", error);
                                                        });
                                                } else {
                                                    sendStreamHostMessage(textToSend, wabaId, activeChatRoomDetails, userToken).catch((error) => {
                                                        console.error("Failed to send message:", error);
                                                    });
                                                }

                                                setMessageInput('');
                                                if (textAreaRef.current) {
                                                    textAreaRef.current.style.height = 'initial';
                                                }
                                            }}>
                                                {commandWindow && (
                                                    <div className="command-menu" ref={commandRef}>
                                                        <ul>
                                                        {filterCommand.map((command, index) => (
                                                            <li 
                                                                key={index} 
                                                                className='command-item' 
                                                                onClick={async () => {
                                                                    setMessageInput(command.text_response);
                                                                    
                                                                    // Check if command has file_response and it's not 'null'
                                                                    if (command.file_response && command.file_response !== 'null') {
                                                                        try {
                                                                            const response = await fetch(command.file_response);
                                                                            const blob = await response.blob();
                                                                            const file = new File([blob], command.file_response?.split('/').pop(), {type: blob.type});
                                                                            
                                                                            // Create preview URL
                                                                            const previewURL = URL.createObjectURL(blob);
                                                                            setPreviewUrl(previewURL);
                                                                            setSelectedFile(file);
                                                                            
                                                                            // Clean up preview URL when component unmounts
                                                                            return () => {
                                                                                URL.revokeObjectURL(previewURL);
                                                                            };
                                                                        } catch (error) {
                                                                            console.error('Error loading file:', error);
                                                                            toast.error('Failed to load media file');
                                                                        }
                                                                    }
                                                                }}
                                                            >
                                                                {command.command}
                                                            </li>
                                                        ))}
                                                        </ul>
                                                    </div>
                                                )}
                                                {replyInfo && (
                                                    <div className="reply-preview">
                                                        <div className="reply-content">
                                                            <IoIosChatboxes className="reply-icon" />
                                                            <div className="reply-text">Reply To: {replyInfo.text || replyInfo.media_type}</div>
                                                        </div>
                                                        <button onClick={() => {setReplyInfo(null); setReplyText(null);}} className="close-reply">
                                                            <IoClose />
                                                        </button>
                                                    </div>
                                                )}
                                                
                                                <textarea ref={textAreaRef} type="text" placeholder={placeholderText} value={messageInput} onChange={(e) => handleInputChange(e, 'whatsapp')} style={{ display: audioPreviewUrl ? 'none' : 'block' ,height: textAreaHeight, maxHeight: '200px', fontSize: '1rem', cursor: isTextAreaDisabled ? 'not-allowed' : 'text' }} className='chat-box-form-input-box' inputMode="text" onInput={handleInput} disabled={isTextAreaDisabled}
                                                    onKeyDown={async(event) => {
                                                        if (event.key === 'Enter' && isMobile) {
                                                            event.preventDefault();
                                                            setMessageInput((prevInput) => prevInput + '\n');
                                                        }else if (event.key === 'Enter' && !event.shiftKey && !event.nativeEvent.isComposing && !isMobile) {
                                                            event.preventDefault();
                                                            
                                                            let messageText = '';
                                                            if (messageInput.startsWith('/')) {
                                                                const commandText = messageInput.slice(1);
                                                                const commandFound = command.find(c => c.command === commandText);
                                                                if (commandFound) {
                                                                    messageText = commandFound.text_response;
                                                                    setMessageInput(messageText);
                                                                    if (commandFound.file_response?.split('/').pop()!=='null'){
                                                                        const response = await fetch(commandFound.file_response);
                                                                        const blob = await response.blob();
                                                                        const file = new File([blob], commandFound.file_response?.split('/').pop(), {type: blob.type});
                                                                        handleFileChange({target: {files: [file]}}, activeChatRoomDetails, userToken, wabaId);
                                                                    }
                                                                    return;
                                                                } else {
                                                                    toast.error('Command not found')
                                                                    return;
                                                                }
                                                            } else {
                                                                event.target.form.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
                                                            }
                                                        }
                                                }}/>
                                            </form>
                                            <div className="attachment-button-container">
                                                <div className="div-chat-box-send-icon-symbol-button" style={{'display': audioPreviewUrl ? 'none' : 'flex'}}>
                                                    <button
                                                        className={`chat-box-send-icon-symbol-button ${isActive ? 'active' : ''}`}
                                                        onClick={handleButtonClick}
                                                        type="button"
                                                    >
                                                        <FaPlus className='chat-icon' />
                                                    </button>
                                                </div>
                                                <div className="div-chat-box-send-icon-symbol-button"  style={{'display': audioPreviewUrl ? 'none' : 'flex'}}>
                                                    <button className='chat-box-send-icon-symbol-button' onClick={() => setShowTranslateDropdown(!showTranslateDropdown)} type="button" style={{'display': translateFunction ? 'block' : 'none'}}>
                                                        <MdGTranslate className='chat-icon'/>
                                                    </button>
                                                </div>
                                                <div className="voice-recording-controls">
                                                    {!isRecording ? (
                                                        <button onClick={startRecording} className="voice-record-btn" style={{'display': audioPreviewUrl ? 'none' : 'flex'}}>
                                                            <FaMicrophone style={{color: 'black'}}/>
                                                        </button>
                                                    ) : (
                                                        <button onClick={stopRecording} className="voice-stop-btn">
                                                            <FaStop />
                                                        </button>
                                                    )}
                                                    {audioPreviewUrl && (
                                                        <div className="audio-preview">
                                                            <audio src={audioPreviewUrl} controls />
                                                            <div className='audio-control-btn'>
                                                                <button onClick={sendVoiceMessage} className="voice-send-btn">
                                                                    Send
                                                                </button>
                                                                <button onClick={() => {setAudioBlob(null); setAudioPreviewUrl(null);}} className="voice-discard-btn">
                                                                    Discard
                                                                </button>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className="emoji-picker-container" ref={emojiPickerRef} style={{'display': audioPreviewUrl ? 'none' : 'flex'}}>
                                                    <button 
                                                        className="chat-box-send-icon-symbol-button"
                                                        onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                                                    >
                                                        <FaRegSmile className="chat-icon" />
                                                    </button>
                                                    
                                                    {showEmojiPicker && (
                                                        <div className="emoji-picker-popup">
                                                            <Picker 
                                                                data={data} 
                                                                onEmojiSelect={handleEmojiSelect}
                                                                theme="light"
                                                                previewPosition="none"
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="ai-assist-button" style={{'display': audioPreviewUrl ? 'none' : 'flex'}}>
                                                    <button onClick={() => generateReply()} disabled={isGenerating}className={isGenerating ? 'loading' : ''}>
                                                        <MdAssistantPhoto className="ai-icon"/>Ai Assist
                                                    </button>
                                                </div>
                                                <div className="inbox-send-button" style={{'display': audioPreviewUrl ? 'none' : 'block'}}>
                                                    <button className='chat-box-send-icon-symbol-button' type="button" onClick={() => {
                                                            const textToSend = messageInput;
                                                            if (textToSend.trim() === '') {
                                                                toast.error('Message input cannot be empty.');
                                                                return;
                                                            }

                                                            if (selectedChatRoom.chatroom_type === 'whatsapp') {
                                                                sendMessageToWhatsappAndSave(textToSend, waPhoneNumberId, SystemUserToken, currentParticipantPhoneNumber, wabaId, activeChatRoomDetails, userToken)
                                                                    .catch((error) => {
                                                                        console.error("Failed to send message:", error);
                                                                    });
                                                            } else {
                                                                sendStreamHostMessage(textToSend, wabaId, activeChatRoomDetails, userToken).catch((error) => {
                                                                    console.error("Failed to send message:", error);
                                                                });
                                                            }
                                                            
                                                            setMessageInput('');
                                                            if (textAreaRef.current) {
                                                                textAreaRef.current.style.height = 'initial';
                                                            }
                                                        }}
                                                    >
                                                        Send
                                                    </button>
                                                </div>
                                                {showTranslateDropdown && (
                                                    <div className={`dropdown-translate-menu-chat-box ${showTranslateDropdown ? 'show' : ''}`} ref={dropdownRef}>
                                                        <ul>
                                                            <li onClick={() => handleTranslateClick('zh')}>Chinese</li>
                                                            <li onClick={() => handleTranslateClick('ko')}>Korean</li>
                                                            <li onClick={() => handleTranslateClick('ja')}>Japanese</li>
                                                            <li onClick={() => handleTranslateClick('ms')}>Malay</li>
                                                            <li onClick={() => handleTranslateClick('id')}>Indonesian</li>
                                                        </ul>
                                                    </div>
                                                )}
                                                {showDropdown && (
                                                    <div className={`dropdown-menu-chat-box ${showDropdown ? 'show' : ''}`} ref={dropdownRef}>
                                                        <ul>
                                                            {isMobile ? (
                                                                <MobileImageSelector onFileSelect={handleMobileFileSelect} />
                                                            ) : (
                                                                <>
                                                                    <li onClick={() => handleSelection('image')}><FaImage /> Images</li>
                                                                    <li onClick={() => handleSelection('video')}><IoVideocam /> Video</li>
                                                                    <li onClick={() => handleSelection('document')}><IoDocuments /> Documents</li>
                                                                </>
                                                            )}
                                                            <li onClick={() => openWhatsappTemplate()}><IoIosChatboxes /> Templates</li>
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                            <input
                                                type="file"
                                                style={{ display: 'none' }}
                                                ref={fileInputRef}
                                                onChange={(event) => handleFileChange(event, activeChatRoomDetails, userToken, wabaId)}
                                            />
                                            {previewUrl && (
                                                <div className="media-preview">
                                                    {selectedFile.type.startsWith('image/') ? (
                                                        <img src={previewUrl} alt="Preview" />
                                                    ) : selectedFile.type.startsWith('video/') ? (
                                                        <video src={previewUrl} controls />
                                                    ) : (
                                                        <div>
                                                            {getFileIcon(selectedFile.type)}
                                                            <div className="filespan-column">
                                                                <span>{selectedFile.name}</span>
                                                                <span>({(selectedFile.size / 1024).toFixed(2)} KB)</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {/* Add caption input */}
                                                    <div className="caption-input">
                                                        <textarea 
                                                            type="text" 
                                                            placeholder="Caption (Optional)" 
                                                            value={captionInput}
                                                            onChange={(e) => setCaptionInput(e.target.value)}
                                                            className="caption-textarea"
                                                        />
                                                    </div>
                                                    <div className="send-media-container">
                                                        <button onClick={() => {setSelectedFile(null); setPreviewUrl(''); setIsSendingFile(false);}}>Cancel</button>
                                                        <button onClick={handleSendFile} disabled={isSendingFile}>
                                                            {isSendingFile ? 'Sending...' : 'Send'}
                                                        </button>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    )}


                                    {/* Text area for private note */}
                                    {activeTab === 'privateNote' && (
                                        <div className="private-note-tab">
                                            <form onSubmit={() => {
                                                const textToSend = messageInput;
                                                if (textToSend.trim() === '') {
                                                    toast.error('Message input cannot be empty.');
                                                    return;
                                                }

                                                sendPrivateMessage(textToSend, wabaId, activeChatRoomDetails, userToken).catch((error) => {
                                                    console.error("Failed to send message:", error);
                                                });
                                                
                                                setMessageInput('');
                                                if (textAreaRef.current) {
                                                    textAreaRef.current.style.height = 'initial';
                                                }
                                            }}> 
                                                {tagWindow && (
                                                    <div className="command-menu">
                                                        <ul>
                                                            {filterAccountList.map((user, index) => (
                                                                <li key={index} className='command-item' onClick={() => {setMessageInput((prevMessageInput) => prevMessageInput + user.username);setTagWindow(false);}}>{user.username}</li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )}
                                                
                                                <textarea ref={textAreaRef} type="text" placeholder='Shift + Enter for new line. Start with "@" to tag users' value={messageInput} onChange={(e) => handleInputChange(e, 'private')} onKeyDown={async(event) => {
                                                        if (event.key === 'Enter' && isMobile) {
                                                            event.preventDefault();
                                                            setMessageInput((prevInput) => prevInput + '\n');
                                                        }else if (event.key === 'Enter' && !event.shiftKey && !event.nativeEvent.isComposing && !isMobile) {
                                                            event.preventDefault();
                                                            
                                                            if (messageInput.includes('@')) {
                                                                const regex = /@(\w+)/g;
                                                                const match = messageInput.match(regex);
                                                                const matchedText = match ? match[0] : ''
                                                                const userFound = secondaryAccountList.find(user => user.username === matchedText.slice(1));
                                                                if (userFound) {
                                                                    sendTagNotification(userFound);
                                                                } 
                                                            }
                                                            setTagWindow(false);
                                                            event.target.form.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));

                                                        }
                                                }}/>
                                            </form>
                                            
                                            <div className="inbox-send-button" style={{'display': 'flex', 'justifyContent': 'flex-end'}}>
                                                <button className='chat-box-send-icon-symbol-button' type="button" onClick={() => {
                                                        const textToSend = messageInput;
                                                        if (textToSend.trim() === '') {
                                                            toast.error('Message input cannot be empty.');
                                                            return;
                                                        }

                                                        if (messageInput.includes('@')) {
                                                            const regex = /@(\w+)/g;
                                                            const match = messageInput.match(regex);
                                                            const matchedText = match ? match[0] : ''
                                                            const userFound = secondaryAccountList.find(user => user.username === matchedText.slice(1));
                                                            if (userFound) {
                                                                sendTagNotification(userFound);
                                                            } 
                                                        }
                                                        setTagWindow(false);

                                                        sendPrivateMessage(textToSend, wabaId, activeChatRoomDetails, userToken).catch((error) => {
                                                            console.error("Failed to send message:", error);
                                                        });
                                                        
                                                        setMessageInput('');
                                                        if (textAreaRef.current) {
                                                            textAreaRef.current.style.height = 'initial';
                                                        }
                                                    }}
                                                >
                                                    Send
                                                </button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        {/* End of text area --------------------------------------------------------------------------------------------------------------------------------------- */}


                        <div className={`inbox-column3 ${activeView === 'profile' ? 'active' : ''}`}>
                            {selectedChatRoom && selectedChatRoom.participants && selectedChatRoom.participants.length > 0 && (
                                <>
                                    {isMobile && (
                                    <div className="inbox-header3">
                                        <button className="back-button" onClick={handleBackClick}>
                                        <IoIosArrowBack />
                                        </button>
                                        <h3>Profile</h3>
                                    </div>
                                    )}
                                    <div className="main-column3">
                                        <div className="column3-pfp">
                                            <b>{getInitial(currentParticipantName)}</b>
                                        </div>
                                    </div>
                                    <b>{getUserName(currentParticipantName[0])}</b>
                                    <div className="personal-data">
                                        <p>
                                            <LuPhone style={{marginRight:'1rem'}}/>  
                                            <div className="data-tags-container">
                                            <span className="data-tag">+{currentParticipantPhoneNumber}</span>
                                            </div>
                                        </p>
                                        <p>
                                            <LuBuilding style={{marginRight:'1rem'}}/>  
                                            <div className="data-tags-container">
                                                {editBuildings.map((building, index) => (
                                                    <span key={index} className="data-tag">
                                                        {building.template?.label}
                                                    </span>
                                                ))}
                                            </div>
                                        </p>
                                        <p>
                                            <LuDoorClosed style={{marginRight:'1rem'}}/>  
                                            <div className="data-tags-container">
                                                {editBuildings.map((building, index) => (
                                                    <span key={index} className="data-tag">
                                                        {building?.unit}
                                                    </span>
                                                ))}
                                            </div>
                                        </p>
                                        <p>
                                            <MdFlightLand style={{marginRight:'1rem'}}/>  
                                            <div className="data-tags-container">
                                                {editBuildings.map((building, index) => (
                                                    <span key={index} className="data-tag">
                                                        {formatDate(building.checkIn)}
                                                    </span>
                                                ))}
                                            </div>
                                        </p>
                                        <p>
                                            <MdFlightTakeoff style={{marginRight:'1rem'}}/>  
                                            <div className="data-tags-container">
                                                {editBuildings.map((building, index) => (
                                                    <span key={index} className="data-tag">
                                                        {formatDate(building.checkOut)}
                                                    </span>
                                                ))}
                                            </div>
                                        </p>
                                    </div>
                                    <div className="action-buttons">
                                        <button className="edit-contact-btn" onClick={handleEditInbox} >Edit</button>
                                        <button className="delete-btn" onClick={handleDeleteButton}>Delete</button>
                                    </div>

                                    <div className="personal-task-section">
                                        {clientRelatedTask.map((task) => { return(
                                            <div className='personal-task'>
                                                {task.task_type}
                                                <button className='personal-task-btn' onClick={() => removeTask(task.id)}><IoCheckmarkDoneCircle /></button>
                                            </div>
                                        )})}
                                    </div>
                                    <div className='add-task-btn'>
                                        <button onClick={() => openTodolist()}>Add task</button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                )}

                {!wabaId && 
                    <div className='inbox-no-wabaid link-no-account'>
                        <h2><span><PiLinkBreakBold /> No whatsapp activated yet</span></h2>
                    </div>
                }
            </main>

            {showDeleteConfirmation && (
                <div className="delete-confirmation-modal">
                    <div className="delete-modal">
                    <h3>Are you sure you want to delete this contact?</h3>
                    <div className="delete-modal-buttons">
                        <button className="delete-yes" onClick={handleDeleteInbox}>Yes</button>
                        <button className="delete-no" onClick={() => setShowDeleteConfirmation(false)}>No</button>
                    </div>
                    </div>
                </div>
                )}

                {showDeleteContactConfirmation && (
                <div className="delete-confirmation-modal">
                    <div className="delete-modal">
                    <h3>Are you sure you want to delete this contact?</h3>
                    <div className="delete-modal-buttons">
                        <button className="delete-yes" onClick={handleDeleteConfirm}>Yes</button>
                        <button className="delete-no" onClick={() => setShowDeleteContactConfirmation(false)}>No</button>
                    </div>
                    </div>
                </div>
                )}


            {/* ToDoList Modal Section */}
            {todolistModal && (
                <div className="template-modal">
                    <div className="template-modal-container">
                        <div className="todolist-modal-content">
                            <div className="modal-header">
                                <IoIosArrowBack className='modal-backbtn' onClick={handleCloseToDoList} size={'1.5rem'}/>
                                <h2>Add To Do List</h2>
                            </div>

                            <div className="input-container">
                                <select id='todolist-tag' value={selectedTag} onChange={(e) => setSelectedTag(e.target.value)} onClick={(e) => e.stopPropagation()}>
                                    {todolistOption.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                                <label htmlFor='todolist-tag'>To-do list tag:</label>
                            </div>
                            
                            <div className="input-container">
                                <input type="tel" id="phoneInput" placeholder="Enter phone number" value={todolistPhone} onChange={(e) => handlePhoneChange2(e)}/>
                                <label htmlFor="phoneInput">Phone number (optional)</label>
                                {phoneError && <div className="error-message">{phoneError}</div>}
                            </div>

                            <div className="input-container">
                                <textarea type='text' id='task-title' placeholder='Task title' value={todolistDescription} onChange={(evt) => setTodolistDescription(evt.target.value)}/>
                                <label htmlFor='task-title'>To-do list description</label>
                            </div>
                            
                            <button onClick={addTodolist}>Add To Do List</button>
                        </div>
                    </div>
                </div>
            )}


            {/* WhatsApp Template Modal Section */}
            {whatsappTemplateModal && (
                <div className="template-modal" style={{zIndex: '100000001'}}>
                    <div className="template-modal-container">
                        <div className="template-modal-content">
                            <div className="template-modal-header">
                                <IoIosArrowBack style={{'fontSize': '1.5rem'}} className='test-modal-icon' onClick={handleCloseWhatsappTemplate}/>
                                <h3>Choose WhatsApp Template</h3>
                            </div>

                            {/* Template Dropdown */}
                            <div className="template-selection">
                                <div className="input-container">
                                    <select id='todolist-tag' value={selectedTemplate} onChange={(e) => { handleTemplateChange2(e)}} onClick={(e) => e.stopPropagation()}>
                                        <option value="">Select a template</option>
                                        {templates.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                    <label htmlFor='todolist-tag'>Template options:</label>
                                </div>
                            </div>

                            

                            {/* Send Message Button */}
                            <button onClick={sendWhatsAppTemplate} disabled={isTemplateButtonDisabled}>Send Message</button>
                        </div>
                    </div>
                </div>
            )}




            {/*  Add contact section */}
            {isModalOpen && (
                <main className={`test-modal-main ${isModalOpen ? 'open' : ''}`}>
                    <div className='modal-container'>
                        <div className='modal'>
                            <div className='test-modal-header'>
                                <IoIosArrowBack style={{'fontSize': '1.5rem'}} className='test-modal-icon' onClick={()=>setIsModalOpen(false)}/>
                                <h2>Create new contact</h2>
                            </div>
                            <div className='test-modal-contact'>
                                <h3>Contact details</h3>
                                <div class="input-container">
                                    <input type="text" id="myInput" value={contactName} placeholder="Enter contact name" onChange={(e) => setContactName(e.target.value)}/>
                                    <label for="myInput">Enter contact name</label>
                                </div>
                                <div class="input-container">
                                    <input type="tel" id="phoneInput" placeholder="Enter phone number" value={phoneNumber} onChange={handlePhoneChange}/>
                                    <label htmlFor="phoneInput">Enter phone number</label>
                                    {phoneError && <div className="error-message">{phoneError}</div>}
                                </div>
        
                                {buildings?.map((building, index) => (
                                    <div key={index}className={`building-div ${building.visible ? 'visible' : ''}`}>
                                        <div className='building-header'>
                                            <h3>{buildings[index]['template']?.label ? buildings[index]['template']['label'] : `Building ${index + 1}`} {buildings[index]['unit'] ? buildings[index]['unit'] : ''}</h3>
                                            <MdDelete className='delete-icon' onClick={() => deleteBuilding(index, building.id)} style={{ fontSize: '1.5rem' }} />
                                        </div>
                                        <div className='building-info'>
                                            <Select className='modal-select' placeholder={'Select building'} options={massFlowOption} value={buildings[index]['template']?.value ? {'label': buildings[index]['template']?.label, 'value': buildings[index]['template']?.value} : null} onChange={(selectedOption) => handleTemplateChange(index, selectedOption)} />
                                            <Select className='modal-select' placeholder={'Select unit'} options={unitOption[buildings[index]['template']?.label]} value={buildings[index]['unit'] ? {'label': buildings[index]['unit'], 'value': buildings[index]['unit']} : null} onChange={(selectedOption) => handleUnitChange(index, selectedOption)} />
                                            <div className='test-modal-date'>
                                                <div className="checkin-container">
                                                    <div className="checkin-option">
                                                    <label htmlFor='checkin'>Check In</label>
                                                    <StyledDateTimePicker id='checkin' selected={buildings[index]['checkIn']} onChange={(date) => handleCheckInDateChange(index, date)} dateFormat="MMMM d, yyyy" placeholderText="Check in" autoComplete="off" customInput={<input autoComplete="off" />}/>
                                                    <div className='switch-div'>
                                                    </div>
                                                    <p>Send check-in details now</p>
                                                        <label class="switch">
                                                            <input type="checkbox" id="toggleButton" value={buildings[index]['sendCheckIn']} onChange={(selectedOption) => handleSendCheckIn(index, selectedOption)}/>
                                                            <span class="slider-btn"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="checkin-container">
                                                <div className="checkin-option">
                                                    <label htmlFor='checkout'>Check Out</label>
                                                    <StyledDateTimePicker id='checkout' selected={buildings[index]['checkOut']} onChange={(date) => handleCheckOutDateChange(index, date)} dateFormat="MMMM d, yyyy" placeholderText="Check out" autoComplete="off" customInput={<input autoComplete="off" />} />
                                                    <div className='switch-div'>
                                                </div>
                                                <p>Send check-out details now</p>
                                                        <label class="switch">
                                                            <input type="checkbox" id="toggleButton" value={buildings[index]['sendCheckOut']} onChange={(selectedOption) => handleSendCheckOut(index, selectedOption)}/>
                                                            <span class="slider-btn"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
        
                                <div className={'test-modal-add'} onClick={handleAddBuilding}>
                                    <IoHomeSharp style={{fontSize:'1.5rem'}}/>
                                    <p>Add building</p>
                                </div>
        
                                <AddContactButton onClick={addContactButton} disabled={isButtonDisabled} >
                                    Add contact
                                </AddContactButton>
                            </div>
                        </div>
                    </div>
                </main>
            )}

            {/* Edit contact section */}
            <EditModal isEditModalOpen={isEditModalOpen} setIsEditModalOpen={setIsEditModalOpen} editContactName={editContactName} setEditContactName={setEditContactName} editBuildings={editBuildings} massFlowOption={massFlowOption} unitOption={unitOption} handleEditTemplateChange={handleEditTemplateChange} handleEditUnitChange={handleEditUnitChange} handleEditCheckInDateChange={handleEditCheckInDateChange} handleEditSendCheckIn={handleEditSendCheckIn} handleEditCheckOutDateChange={handleEditCheckOutDateChange} handleEditSendCheckOut={handleEditSendCheckOut} deleteEditBuilding={deleteEditBuilding} handleEditAddBuilding={handleEditAddBuilding} handleEditSendCheckInAgain={handleEditSendCheckInAgain} handleEditSendCheckOutAgain={handleEditSendCheckOutAgain} editContact={editContact} isButtonDisabled={isButtonDisabled} />

        </React.Fragment>
    );
}

export default Inbox;

