import React, { useEffect, useState, useContext, useRef, useMemo, useCallback } from 'react';
import { UserContext } from "../user-context.js";
import { API } from "../api-service.js";
import './css/ChatRoomList.css';
import UserImage from '../assets/user.png';
import { useWebSocketContext } from '../pages/Websocket.js';
import { toast } from 'react-toastify';
import debounce from 'lodash/debounce';
import { format, isToday, isBefore, isAfter, parseISO, isWithinInterval } from 'date-fns';
import { last } from 'lodash';

const ContextMenu = ({ x, y, onDelete, onClose }) => {
    const menuRef = useRef(null);
  
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
          onClose();
        } 
      };
  
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [onClose]);
  
    return (
        <div ref={menuRef} style={{ top: y, left: x }} className="popup-menu">
            <button className="popup-menu-button" onClick={onDelete}>
                Delete Chatroom
            </button>
        </div>
    );
  };

const ChatRoomItem = ({ chatRoom, onChatRoomClick, onDeleteChatRoom, isDisabled, setChatRooms, isSelected , massFlowOption}) => {
    const { userToken, wabaId } = useContext(UserContext);
    const [contextMenu, setContextMenu] = useState(null);
    const [isHiding, setIsHiding] = useState(false);
    const [participantData, setParticipantData] = useState({});

    // Format the last message data from chatRoom.last_message
    const lastMessage = useMemo(() => {
        console.log("chatRoom.last_message_sender: ", chatRoom.last_message?.sender);
        if (!chatRoom.last_message) {
            return { text: '', date: '', time: '', type: '', id: '', sender: '' };
        }
    
        const date = new Date(chatRoom.last_message.timestamp);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const formattedDate = `${day}/${month}/${year}`;
        const formattedTime = date.toLocaleString('en-US', {
            hour: '2-digit', 
            minute: '2-digit'
        });
    
        // Don't add prefix here, just store the raw text
        return {
            text: chatRoom.last_message.text,
            date: formattedDate,
            time: formattedTime,
            type: chatRoom.last_message.media_type || 'text',
            id: chatRoom.last_message.id,
            sender: chatRoom.last_message.sender
        };
    }, [chatRoom.last_message, wabaId]);

    const getUserName = input => {
        const regex = /.*\d+/;
        const match = input?.match(regex);
        
        if (match) {
            const lastNumberIndex = match[0].length;
            return input.substring(lastNumberIndex).trim();
        }
        return input;
    };
    
    
    // Modify the getInitial function to use getUserName
    const getInitial = (name) => {
        if (!name) return '?';
        const actualName = getUserName(name);
        return actualName.charAt(0).toUpperCase();
    };

    const handleContextMenu = (event) => {
        event.preventDefault();
        setContextMenu({
            x: event.clientX,
            y: event.clientY,
        });
    };

    const handleDeleteChatRoom = () => {
        onDeleteChatRoom(chatRoom.id, chatRoom.participants);
        setContextMenu(null);
    };

    const getMessageDateDisplay = (messageDate) => {
        const today = new Date();
        const yesterday = new Date(new Date().setDate(today.getDate() - 1));
    
        // Split the date string into day, month, and year
        if (messageDate) {        
            const [day, month, year] = messageDate.split('/');
            // Create a new Date object using the parsed values
            const messageDateTime = new Date(`${year}-${month}-${day}`);
            
        
            // Convert to strings for comparison to ensure timezone is considered
            const todayDay = String(today.getDate()).padStart(2, '0');
            const todayMonth = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const todayYear = today.getFullYear();
            const todayStr =  `${todayDay}/${todayMonth}/${todayYear}`;
    
            // Get yesterday's date
            const yesterdayDay = String(yesterday.getDate()).padStart(2, '0');
            const yesterdayMonth = String(yesterday.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const yesterdayYear = yesterday.getFullYear();
            const yesterdayStr =  `${yesterdayDay}/${yesterdayMonth}/${yesterdayYear}`;
    
            // Extract day, month, and year
            const msgDateTimeDay = String(messageDateTime.getDate()).padStart(2, '0');
            const msgDateTimeMonth = String(messageDateTime.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const msgDateTimeYear = messageDateTime.getFullYear();
    
            // Format the date as dd/mm/yyyy
            const messageDateStr = `${msgDateTimeDay}/${msgDateTimeMonth}/${msgDateTimeYear}`;
    
            if (messageDateStr === todayStr) {
                // Message was sent today, return nothing
                return 'Today';
            } else if (messageDateStr === yesterdayStr) {
                // Message was sent yesterday
                return 'Yesterday';
            } else if (messageDateStr === 'NaN/NaN/NaN') {
                return ' ';
            } else {
                // Message was sent on another day, return formatted date
                return messageDateStr;
            }
        }
    };
    
    const handleClick = async (e) => {
        if (isDisabled) {
            e.preventDefault();
            return;
        }

        // If there are unread messages, trigger the hiding animation
        if (chatRoom.unread_count > 0) {
            setIsHiding(true);
            // Wait for animation to complete before updating the count
            setTimeout(() => {
                setChatRooms(prevRooms => 
                    prevRooms.map(room => 
                        room.id === chatRoom.id 
                            ? { ...room, unread_count: 0 }
                            : room
                    )
                );
                setIsHiding(false);
            }, 200); // Match this with CSS animation duration
        }
        onChatRoomClick(chatRoom.id, chatRoom);
    };

    // Add participant data processing
    useEffect(() => {
        const processParticipantData = async () => {
            try {
                const participantsData = {};
                for (const participant of chatRoom.participants) {
                    if (participant?.phone_number) {
                        
                        const userData = participant;

                        // Check if the values are strings that need parsing, otherwise use as is
                        const chatflow_list = typeof userData.chatflow_id === 'string' 
                            ? JSON.parse(userData.chatflow_id.replace(/'/g, '"')) 
                            : userData.chatflow_id || [];

                        const checkin_list = typeof userData.checkin_date === 'string' 
                            ? JSON.parse(userData.checkin_date.replace(/'/g, '"')) 
                            : [userData.checkin_date] || [];

                        const checkout_list = typeof userData.checkout_date === 'string' 
                            ? JSON.parse(userData.checkout_date.replace(/'/g, '"')) 
                            : [userData.checkout_date] || [];

                        const unit_list = typeof userData.unit_no === 'string' 
                            ? JSON.parse(userData.unit_no.replace(/'/g, '"')) 
                            : [userData.unit_no] || [];

                        const buildings = [];
                        // Only create building if we have valid data
                        if (unit_list.length > 0) {
                            const building = {
                                buildingName: participant.name.split(' ')[0],
                                unit: unit_list[0],
                                checkinDate: checkin_list[0],
                                checkoutDate: checkout_list[0]
                            };
                            buildings.push(building);
                        }

                        participantsData[participant?.phone_number] = {
                            ...userData,
                            buildings,
                            checkinDate: checkin_list[0],
                            checkoutDate: checkout_list[0]
                        };
                    }
                }
                setParticipantData(participantsData);
            } catch (error) {
                console.error('Error processing participant data:', error);
            }
        };

        processParticipantData();
    }, [chatRoom.participants, userToken]);

    const getStatus = (checkinDate, checkoutDate) => {
        
        if (!checkinDate || !checkoutDate) {
            return { text: "No Dates", color: "#4b5563", backgroundColor: "#e5e7eb"}; // Gray
        }
        
        const now = new Date();
        const checkin = new Date(checkinDate);
        const checkout = new Date(checkoutDate);
        
        if (isBefore(now, checkin)) {
            return { text: "Not Check In", color: "#1e40af", backgroundColor: "#bfdbfe" }; // Light blue
        }
        
        
        if (isToday(checkin)) {
            return { text: "Check In Today", color: "#065f46", backgroundColor: "#d1fae5" } ; // Green
        }
        
        
        if (isWithinInterval(now, { start: checkin, end: checkout })) {
            return { text: "Checked In", color: "#0f766e", backgroundColor: "#ccfbf1" }; // Teal
        }
        
        
        if (isToday(checkout)) {
            return { text: "Check Out Today", color: "#c2410c", backgroundColor: "#fed7aa" }; // Orange
        }
        
        
        if (isAfter(now, checkout)) {
            return { text: "Checked Out", color: "#991b1b", backgroundColor: "#fecaca" }; // Red
        }
        
        return { text: "Unknown", color: "#4b5563", backgroundColor: "#e5e7eb"}; // Gray
        
    };

    return (
        <div 
            className={`chatroom-list ${isSelected ? 'chatroom-selected' : ''}`}
            onClick={handleClick}
            onContextMenu={handleContextMenu}
            style={{ pointerEvents: isDisabled ? 'none' : 'auto' }}
        >
            <div className="inbox-item-pic">
                {chatRoom.participants.map((participant, index) => (
                    <div key={index}>
                        <b>{getInitial(participant.name)}</b>
                    </div>
                ))}
            </div>
            
            <div className="inbox-item-name">
                {chatRoom.participants.map((participant, index) => {
                    const userData = participantData[participant?.phone_number];
                    
                    const status = getStatus(userData?.checkinDate, userData?.checkoutDate);
                    return (
                        <div key={index}>
                            <div className='inbox-item-name-and-date'>
                                <h3>
                                    {getUserName(participant.name)}
                                    {chatRoom.unread_count > 0 && (
                                        <span className={`unread-badge ${isHiding ? 'hiding' : ''}`}>
                                            {chatRoom.unread_count}
                                        </span>
                                    )}
                                </h3>
                                <p className='inbox-item-last-message-timestamp'>
                                    {getMessageDateDisplay(lastMessage.date)}
                                </p>
                            </div>
                            <div className="inbox-item-last-message">
                                <p className='inbox-item-last-message-text'>
                                    {lastMessage.type !== 'text' ? 
                                        `${lastMessage.sender === 'WabaId' ? 'You: ' : 'Customer: '}${lastMessage.type}` : 
                                        `${lastMessage.sender === 'WabaId' ? 'You: ' : 'Customer: '}${lastMessage.text}`}
                                </p>
                                {lastMessage.time !== "Invalid Date" && (
                                    <p className='inbox-item-last-message-timestamp'>{lastMessage.time}</p>
                                )}
                            </div>
                            {/* Add building tags */}
                            <div className="building-tags-container">
                            {userData?.buildings?.map((building, idx) => {
                                const isValidBuilding = massFlowOption?.some(option => 
                                    option.label === building.buildingName
                                );
                                
                                return (isValidBuilding) ? (
                                    <div key={idx} className="building-tag">
                                        {building.buildingName}
                                        {building.unit && ` ${building.unit}`}
                                    </div>
                                ) : null;
                            })}
                            </div>
                            <div className="status-tag-container">
                                <div className={`status-tag`} style={{'color':status.color, 'backgroundColor': status.backgroundColor}}>
                                    {status.text}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
            {contextMenu && (
                <ContextMenu
                    x={contextMenu.x}
                    y={contextMenu.y}
                    onDelete={handleDeleteChatRoom}
                    onClose={() => setContextMenu(null)}
                />
            )}
            
        </div>
    );
};


function ChatRoomList({ onChatRoomClick, onChatRoomDelete, searchQuery, filters, massFlowOption }) { 
    const socketRef = useRef();
    const chatRoomCache = useRef(new Map());
    const [chatRooms, setChatRooms] = useState([]);
    const [fullChatRoom, setFullChatRoom] = useState([]);
    const { userToken, wabaId } = useContext(UserContext);
    const { toggleConnection } = useWebSocketContext();
    const [isLoading, setIsLoading] = useState(true);
    const [selectedChatRoomId, setSelectedChatRoomId] = useState(null);
    const BATCH_SIZE = 10; // Adjust based on your needs
    const [isRoomLoading, setIsRoomLoading] = useState(false);
    const [isLoadingMore, setIsLoadingMore] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const containerRef = useRef(null);
    const [loadedPages] = useState(new Set()); // Track which pages we've loaded
    const isLoadingRef = useRef(false); // Use ref to track loading state
    const abortControllerRef = useRef(null);

    // Add cache handling
    const CACHE_KEY = `chatrooms_${wabaId}`;
    const CACHE_EXPIRY = 5 * 60 * 1000; // 5 minutes in milliseconds

    // Load from cache on initial mount
    // useEffect(() => {
    //     const getFullChatRoom = async() => {
    //         try {
    //             const response = await API.getChatRooms(userToken, wabaId);
    //             console.log("Get chatrooms response: ", response)
    //             setFullChatRoom(response);
    //         } catch (error) { 
    //             console.error('Error', error);
    //         }
    //     }
    //     getFullChatRoom();
    // }, [userToken])

    useEffect(() => {
        const loadFromCache = () => {
            try {
                const cached = localStorage.getItem(CACHE_KEY);
                if (cached) {
                    const { data, timestamp } = JSON.parse(cached);
                    const isExpired = Date.now() - timestamp > CACHE_EXPIRY;
                    
                    if (!isExpired) {
                        console.log('Loading from cache...');
                        setChatRooms(data);
                        return true;
                    } else {
                        localStorage.removeItem(CACHE_KEY);
                    }
                }
                return false;
            } catch (error) {
                console.error('Cache error:', error);
                return false;
            }
        };

        const loadedFromCache = loadFromCache();
        if (!loadedFromCache) {
            fetchChatRooms();
        }
    }, [wabaId]);

    // Modified fetchChatRooms to prevent duplicate page loads
    const fetchChatRooms = useCallback(async (page = 1, isBackground = false) => {
        if (loadedPages.has(page) || isLoadingRef.current) {
            console.log(`⚠️ Page ${page} already loaded or loading in progress`);
            return;
        }

        // Create new AbortController for this fetch
        abortControllerRef.current = new AbortController();

        try {
            isLoadingRef.current = true;
            if (page === 1) {
                setIsLoading(true);
                loadedPages.clear();
            } else if (!isBackground) {
                setIsLoadingMore(true);
            }
            
            const response = await API.getChatRooms3(
                userToken, 
                wabaId, 
                page, 
                abortControllerRef.current.signal
            );
            
            // Update fullChatRoom by appending new results
            setFullChatRoom(prevResults => {
                // If it's the first page, initialize with response.results
                if (page === 1) {
                    return response.results || [];
                }
                // Otherwise, append new results while avoiding duplicates
                const existingIds = new Set(prevResults.map(room => room.id));
                const newResults = response.results.filter(room => !existingIds.has(room.id));
                return [...prevResults, ...newResults];
            });
            console.log("Get chatrooms 3 response: ", response)
            
            if (response && response.results) {
                loadedPages.add(page); // Mark this page as loaded

                if (page === 1) {
                    setChatRooms(response.results);
                    // Start background loading of next pages after first page is loaded
                    if (response.next) {
                        setTimeout(() => fetchChatRooms(2, true), 1000);
                    }
                } else {
                    setChatRooms(prev => [...prev, ...response.results]);
                    // Continue background loading next page if available
                    if (response.next && isBackground) {
                        setTimeout(() => fetchChatRooms(page + 1, true), 1000);
                    }
                }
                
                setHasMore(!!response.next);
                setCurrentPage(page);
                
                console.log(`✅ Page ${page} loaded successfully`);
                console.log(`📊 Items loaded: ${response.results.length}`);
                console.log(`📚 Total items: ${response.count || 0}`);
                console.log(`⏩ Has more pages: ${!!response.next}`);
            }
        } catch (error) {
            if (error.name === 'AbortError') {
                console.log('Fetch aborted');
                return;
            }
            console.error('❌ Error:', error);
            if (!isBackground) {
                toast.error('Failed to load chatrooms');
            }
        } finally {
            isLoadingRef.current = false;
            setIsLoading(false);
            if (!isBackground) {
                setIsLoadingMore(false);
            }
        }
    }, [userToken, wabaId, loadedPages]);

    // Initial fetch
    useEffect(() => {
        fetchChatRooms(1);
    }, [fetchChatRooms]);

    // Modified scroll handler with debounce
    const handleScroll = useCallback(
        debounce(() => {
            if (!containerRef.current || isLoadingRef.current || !hasMore) return;

            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            const isNearBottom = scrollHeight - scrollTop <= clientHeight + 100;

            if (isNearBottom && !loadedPages.has(currentPage + 1)) {
                fetchChatRooms(currentPage + 1);
            }
        }, 100), // 100ms debounce
    [isLoadingRef, hasMore, currentPage, fetchChatRooms, loadedPages]);

    // Add scroll listener
    useEffect(() => {
        const container = containerRef.current;
        if (container) {
            container.addEventListener('scroll', handleScroll);
            return () => container.removeEventListener('scroll', handleScroll);
        }
    }, [handleScroll]);

    // Update cache when chatrooms change
    useEffect(() => {
        if (chatRooms.length > 0) {
            const cacheData = {
                data: chatRooms,
                timestamp: Date.now()
            };
            localStorage.setItem(CACHE_KEY, JSON.stringify(cacheData));
        }
    }, [chatRooms]);

    // Clear cache on unmount or when wabaId changes
    useEffect(() => {
        return () => {
            localStorage.removeItem(CACHE_KEY);
        };
    }, [wabaId]);

    // Handle WebSocket updates with cache
    const handleWebSocketMessage = useCallback((message) => {
        if (!message.chatRoomId || !message.timestamp) return;
        
        setChatRooms(prevRooms => {
            const updatedRooms = prevRooms.map(room => 
                room.id === message.chatRoomId
                    ? {
                        ...room,
                        last_message: {
                            text: message.message,
                            timestamp: message.timestamp,
                            media_type: message.media_type || 'text',
                            id: message.messageId
                        },
                        unread_count: message.message_sender === wabaId 
                            ? (room.unread_count || 0)
                            : (room.unread_count || 0) + 1
                    }
                    : room
            );
            
            // Update cache with new data
            const cacheData = {
                data: updatedRooms,
                timestamp: Date.now()
            };
            localStorage.setItem(CACHE_KEY, JSON.stringify(cacheData));
            
            return updatedRooms;
        });
    }, [wabaId]);

    // Memoize sorted and filtered chatrooms
    const sortedChatRooms = useMemo(() => {
        const sourceData = (searchQuery === '') ? chatRooms : fullChatRoom;

        // Create a map of existing participant data to preserve it
        const existingParticipantDataMap = new Map();
        chatRooms.forEach(room => {
            room.participants.forEach(participant => {
                if (participant?.phone_number) {
                    existingParticipantDataMap.set(participant?.phone_number, participant);
                }
            });
        });

        const filtered = sourceData.filter(chatRoom => {
            // Name search filter
            const nameMatch = chatRoom.participants.some(participant =>
                participant.name.toLowerCase().includes(searchQuery.toLowerCase())
            );
            if (!nameMatch) return false;

            // Preserve participant data when switching between sources
            chatRoom.participants.forEach(participant => {
                if (participant?.phone_number) {
                    const existingData = existingParticipantDataMap.get(participant?.phone_number);
                    if (existingData) {
                        // Preserve existing data
                        participant.buildings = existingData.buildings;
                        participant.checkinDate = existingData.checkinDate;
                        participant.checkoutDate = existingData.checkoutDate;
                        participant.chatflow_id = existingData.chatflow_id;
                        participant.unit_no = existingData.unit_no;
                    }
                }
            });

            // New Contacts filter
            if (filters?.showNewContacts) {
                return !chatRoom.last_message;
            }

            // Building filter
            if (filters?.buildings?.length > 0) {
                const buildingMatch = filters.buildings.some(building => {
                    return chatRoom.participants.some(participant => {
                        const participantTags = participant.name.split(' ');
                        return participantTags.some(tag => tag === building.label);
                    });
                });
                if (!buildingMatch) return false;
            }

            // Unit filter
            if (filters?.units?.length > 0) {
                const unitMatch = filters.units.some(unit => {
                    return chatRoom.participants.some(participant => {
                        const participantTags = participant.name.split(' ');
                        return participantTags.some(tag => tag === unit.value);
                    });
                });
                if (!unitMatch) return false;
            }

            return true;
        });

        return [...filtered].sort((a, b) => {
            // If filtering for new contacts, no need to sort
            if (filters?.showNewContacts) return 0;
            
            // Your existing sort logic
            if (!a.last_message && !b.last_message) return 0;
            if (!a.last_message) return 1;
            if (!b.last_message) return -1;
            const timeA = new Date(a.last_message.timestamp).getTime();
            const timeB = new Date(b.last_message.timestamp).getTime();
            return timeB - timeA;
        });
    }, [chatRooms, fullChatRoom, searchQuery, filters]); // Add dependencies

    // WebSocket handler for real-time updates
    useEffect(() => {
        socketRef.current = new WebSocket(`wss://streamhost-django-80bc172b3b26.herokuapp.com/ws/chatroomlist/`);
        toggleConnection(false);

        const handleMessage = (event) => {
            const message = JSON.parse(event.data);
            if (!message.chatRoomId || !message.timestamp) return;
            
            if (message.message_type === 'new_chatroom') {
                // Fetch the new chatroom details
                API.getChatRooms(userToken, wabaId)
                    .then(fetchedChatRooms => {
                        const newChatRoom = fetchedChatRooms.find(room => room.id === message.chatRoomId);
                        if (newChatRoom) {
                            setChatRooms(prevRooms => {
                                // Check if the room already exists
                                const roomExists = prevRooms.some(room => room.id === newChatRoom.id);
                                if (!roomExists) {
                                    return [...prevRooms, newChatRoom];
                                }
                                return prevRooms;
                            });
                        }
                    })
                    .catch(error => console.error('Error fetching new chatroom:', error));
                return;
            }
            
            setChatRooms(prevRooms => {
                console.log(message);
                const updatedRooms = prevRooms.map(room => 
                    room.id === message.chatRoomId
                        ? {
                            ...room,
                            last_message: {
                                text: message.message,
                                timestamp: message.timestamp,
                                media_type: message.media_type || 'text',
                                id: message.messageId
                            },
                            // Increment unread count for incoming messages
                            unread_count: message.message_sender === wabaId 
                            ? (room.unread_count || 0)  // Keep current count
                            : (room.unread_count || 0) + 1  // Increment count
                        }
                        : room
                );
                
                return updatedRooms;
            });
        };

        socketRef.current.onopen = () => console.log("[chatroomlist] Connected");
        socketRef.current.onmessage = handleMessage;

        return () => {
            if (socketRef.current) {
                socketRef.current.close();
                toggleConnection(true);
            }
        };
    }, [userToken, toggleConnection]);

    // Handle delete
    const handleDeleteChatRoom = useCallback((chatRoomId, participants) => {
        if (!participants?.length) return;

        API.deleteChatRoom(participants[0].id, userToken)
            .then(() => {
                const updatedRooms = chatRooms.filter(room => room.id !== chatRoomId);
                
                setChatRooms(updatedRooms);
                toast.success("Chatroom deleted", {position: 'bottom-center'});
                onChatRoomDelete(chatRoomId);
            })
            .catch(error => {
                toast.error("Failed to delete chatroom");
                console.error('Error:', error);
            });
    }, [chatRooms, userToken, onChatRoomDelete]);

    // Modify the handleChatRoomClick to include unread count reset
    const handleChatRoomClick = useCallback(async (chatRoomId, chatRoom) => {
        if (isRoomLoading) {
            console.log('🚫 Blocked - Currently loading another chatroom');
            return;
        }

        const startTime = performance.now();
        console.log(`🕒 Starting to load chatroom ${chatRoomId} at ${new Date().toISOString()}`);

        try {
            setIsRoomLoading(true);
            // Reset unread count in the backend if needed
            if (chatRoom.unread_count > 0) {
                try {
                    await API.resetUnreadCount(chatRoomId, userToken);
                } catch (error) {
                    console.error('Failed to reset unread count:', error);
                }
            }
            setSelectedChatRoomId(chatRoomId);
            await onChatRoomClick(chatRoomId, chatRoom);
            
            const endTime = performance.now();
            const loadTime = (endTime - startTime) / 1000; // Convert to seconds
            console.log(`✅ Chatroom ${chatRoomId} loaded in ${loadTime.toFixed(2)} seconds`);
        } finally {
            setTimeout(() => {
                setIsRoomLoading(false);
            }, 1000);
        }
    }, [isRoomLoading, onChatRoomClick, userToken]);

    // Add cleanup effect
    useEffect(() => {
        return () => {
            // Abort any ongoing fetches when component unmounts
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
            // Reset loading states
            isLoadingRef.current = false;
            setIsLoading(false);
            setIsLoadingMore(false);
            // Clear loaded pages
            loadedPages.clear();
        };
    }, []);

    return (
        <div 
            className="chatroom-list-container" 
            ref={containerRef}
        >
            {isLoading ? (
                <div className="loader-container">
                    <div className="loader"></div>
                </div>
            ) : (
                <>
                    {sortedChatRooms.map(chatRoom => (
                        <ChatRoomItem 
                            key={chatRoom.id}
                            chatRoom={chatRoom}
                            onChatRoomClick={handleChatRoomClick}
                            onDeleteChatRoom={handleDeleteChatRoom}
                            isDisabled={isRoomLoading}
                            setChatRooms={setChatRooms}
                            isSelected={selectedChatRoomId === chatRoom.id}
                            massFlowOption={massFlowOption} 
                        />
                    ))}
                    {isLoadingMore && (
                        <div className="loading-more">
                            <div className="loader"></div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}

export default React.memo(ChatRoomList);