import React, { useCallback, useState, useMemo, useEffect, useContext, useRef } from 'react';
import ReactFlow, { MiniMap, Controls, Background, applyNodeChanges, applyEdgeChanges, useReactFlow, Panel, ReactFlowProvider} from 'reactflow';
import { Bounce, ToastContainer, toast} from 'react-toastify';

import { StartNode, CheckInNode, CheckOutNode, ConditionNode, MsgNode, TemplateNode, ForwardTemplateNode, MediaNode, ToDoListNode, UnitNode, EndNode } from '../components/CustomNodes.js';
import { FaBoltLightning, FaCircleStop, FaArrowLeft } from 'react-icons/fa6';
import { TiFlowMerge } from "react-icons/ti";
import { BiMessage } from "react-icons/bi";
import { LuListTodo } from "react-icons/lu";
import { AiFillHome } from "react-icons/ai";
import { MdOutlinePermMedia } from "react-icons/md";
import { TbTemplate } from "react-icons/tb";

import html2canvas from 'html2canvas';
import { useNavigate, useParams } from 'react-router-dom';
import { UserContext } from '../user-context.js';
import { API } from '../api-service.js';
import SideNav2 from '../components/Sidenav.js';

import '../components/css/CustomNodes.css'
import 'reactflow/dist/style.css';
import './css/Flow.css';


const defaultViewport = { x: 0, y: 0, zoom: 0.8 };


// Main component for the Chatbot Flow
function ChatbotFlow () {

    // Import custom nodes and handle states
    const nodeTypes = useMemo(() => ({ startNode: StartNode, checkInNode: CheckInNode, checkOutNode: CheckOutNode, conditionNode: ConditionNode, msgNode: MsgNode, templateNode: TemplateNode, forwardTemplateNode: ForwardTemplateNode, mediaNode: MediaNode, todolistNode: ToDoListNode, unitNode: UnitNode, endNode: EndNode}), []);
    const { userToken } = useContext(UserContext);
    const [unitList, setUnitList] = useState([]);
    const { chatflow_id, chatflow_name, chatflow_type } = useParams();
    const [nodes, setNodes] = useState([]);
    const [edges, setEdges] = useState([]);
    const [chatFlowName, setChatFlowName] = useState('');
    const [rfInstance, setRfInstance] = useState(null); // This is for saving/ exporting the flowchart
    const [selectedNode, setSelectedNode] = useState([]); // StreamFlow and MassFlow has different nodes. This is used to keep track of state
    const divRef = useRef(); // Keep track of div to screenshot using html2canvas


    // Setup initial nodes and edges for the flowchart
    useEffect(() => {
        // FETCH chatflow name
        setChatFlowName(chatflow_name);
        fetchNodes();
        fetchEdges();

        // Set array of nodes
        if(chatflow_type === 'streamflow') {
            setSelectedNode([
                {'Start': [<FaBoltLightning/>, addStartNode, 'side-startNode']}, 
                {'Condition': [<TiFlowMerge size={'1.25rem'}/>, addCondNode, 'side-conditionNode']}, 
                {'Send Message': [<BiMessage size={'1.25rem'}/>, addMsgNode, 'side-msgNode']}, 
                {'Media': [<MdOutlinePermMedia size={'1.25rem'}/>, addMediaNode, 'side-mediaNode']}, 
                {'Todo list': [<LuListTodo size={'1.25rem'}/>, addToDoListNode, 'side-todolistNode']}, 
                {'End': [<FaCircleStop/>, addEndNode, 'side-endNode']}
            ]);
        } else {
            // TODO: Update forward template
            setSelectedNode([
                {'Start': [<FaBoltLightning/>, addStartNode, 'side-startNode']}, 
                {'Check-in': [<FaBoltLightning/>, addCheckInNode, 'side-checkInNode']},
                {'Check-out': [<FaBoltLightning/>, addCheckOutNode, 'side-checkOutNode']},
                {'Unit No': [<AiFillHome />, addUnitNode, 'side-unitNode']},
                {'Send Template': [<TbTemplate size={'1.25rem'}/>, addTemplateNode, 'side-templateNode']}, 
                {'Forward Template': [<TbTemplate size={'1.25rem'}/>, addForwardTemplateNode, 'side-templateNode']},
                {'Todo list': [<LuListTodo size={'1.25rem'}/>, addToDoListNode, 'side-todolistNode']}, 
                {'End': [<FaCircleStop/>, addEndNode, 'side-endNode']}
            ]);
        }
    }, []);


    // 1. Handle when nodes are clicked, dragged, or removed
    const onNodesChange = useCallback(
        (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
        [setNodes]
    );

    // 2. Handle when edges are clicked dragged, or removed
    const onEdgesChange = useCallback(
        (changes) => setEdges((eds) => applyEdgeChanges(changes, eds)),
        [setEdges]
    );

    // 3. Handle when nodes are connected together
    const onConnect = useCallback(
        (connection) => {
            const newEdge = {
                id: connection.source + '-' + connection.target + '-',
                source: connection.source,
                target: connection.target,
                sourceHandle: connection.sourceHandle,
                targetHandle: connection.targetHandle,
            };
            setEdges((prevEdges) => [...prevEdges, newEdge]);
    }, [setEdges])

    // 4. Handle when user wants to save the flowchart
    const onSave = useCallback(() => {
            const validate_status = validateChatFlow();
            // Update nodes, edges and current image state to db
            html2canvas(divRef.current).then(canvas => {
                canvas.toBlob(blob => {
                    const formData = new FormData();
                    formData.append('chatflow_image', blob, 'thumbnail'+chatflow_id+chatflow_name+'.png');
                    formData.append('id', chatflow_id);
                    formData.append('token', userToken);
                    formData.append('error_status', validate_status);
                    formData.append('chatflow_name', chatFlowName);

                    // Send image to backend
                    API.updateChatFlowImage(formData)
                    .catch((error) => {
                        console.error(error);
                    });
                });
            });
            addNode(nodes);
            addEdge(edges);
            toast.success('Chatflow saved successfully!', {position: 'bottom-center'})
        }, [nodes, edges, chatFlowName]);

    const onPublish = useCallback(() => {
            const validate_status = validateChatFlow();
            // Update nodes, edges and current image state to db
            html2canvas(divRef.current).then(canvas => {
                canvas.toBlob(blob => {
                    const formData = new FormData();
                    formData.append('chatflow_image', blob, 'thumbnail'+chatflow_id+chatflow_name+'.png');
                    formData.append('id', chatflow_id);
                    formData.append('token', userToken);
                    formData.append('error_status', validate_status);
                    formData.append('chatflow_name', chatFlowName);

                    // Send image to backend
                    API.updateChatFlowImage(formData)
                    .catch((error) => {
                        console.error(error);
                    });
                });
            });
            updateChatFlow(chatFlowName)
            // Update nodes and edges to db
            addNode(nodes);
            addEdge(edges);
            toast.success('Chatflow published successfully!', {position: 'bottom-center'})
        }, [nodes, edges, chatFlowName]);
    
    
    // 5. Handle changes in node text input
    const onChange = useCallback((id, newValue) => {
        setNodes((nodes) =>
            nodes.map((node) => {
                if (node.id === id) {
                    // This is the node whose input field has changed, update its data
                    return { ...node, data: { ...node.data, 'text': newValue } };
                } else {
                    // This is not the node whose input field has changed, don't modify it
                    return node;
                }
            })
        );
    }, []);

    const fileOnChange = useCallback((id, newValue) => {
        setNodes((nodes) =>
            nodes.map((node) => {
                if (node.id === id) {
                    // This is the node whose input field has changed, update its data
                    return { ...node, data: { ...node.data, 'node_file': newValue} };
                } else {
                    // This is not the node whose input field has changed, don't modify it
                    return node;
                }
            })
        );
    }, []);

    // Handle unit change
    const unitOnChange = useCallback((type, newValue) => {
        if (type === 'add') {
            let status = true;
            setUnitList((prevUnitList) => {
                // Check if the new unit already exists in the list
                if (prevUnitList.includes(newValue)) {
                    status = false;
                    return prevUnitList;
                }
        
                // Append the new unit to the list
                return [...prevUnitList, ...(Array.isArray(newValue) ? newValue : [newValue])];
            });
    
            return status;
        } else if (type === 'remove') {
            setUnitList((prevUnitList) => {
                return prevUnitList.filter(unit => unit !== newValue);
            });
        }
    }, [])

    // 6. Handle when users click the back button
    const navigate = useNavigate();
    const onBack = () => {
        navigate('/main/chatflow');
    }
    
    // Adding nodes by clicking a button and placing them randomly in the Flow -----------------------------------------
    const addStartNode = useCallback(() => {
        const id = 'START-' + Date.now();
        const newNode= { id, type:'startNode', position:{x: Math.random() * 300, y: Math.random() * 300, },data: {'text': '', }, };
        setNodes(nodes => [...nodes, newNode]);
    }, []);

    const addCheckInNode = useCallback(() => {
        const id = 'CHECKIN-' + Date.now();
        const newNode= { id, type:'checkInNode', position:{x: Math.random() * 300, y: Math.random() * 300, }, data: {'text': '', }, };
        setNodes(nodes => [...nodes, newNode]);
    }, []);

    const addCheckOutNode = useCallback(() => {
        const id = 'CHECKOUT-' + Date.now();
        const newNode= { id, type:'checkOutNode', position:{x: Math.random() * 300, y: Math.random() * 300,  }, data: {'text': '', }, };
        setNodes(nodes => [...nodes, newNode]);
    }, []);

    const addCondNode = useCallback(() => {
        const id = 'COND-' + Date.now();
        const newNode= { id, type:'conditionNode', position:{x: Math.random() * 300, y: Math.random() * 300, }, data: {'text': '', onChange: onChange} };
        setNodes(nodes => [...nodes, newNode]);
    }, []);

    const addMsgNode = useCallback(() => {
        const id = 'MSG-' + Date.now();
        const newNode= { id, type:'msgNode', position:{x: Math.random() * 300, y: Math.random() * 300, }, data: {'text': '', onChange: onChange}};
        setNodes(nodes => [...nodes, newNode]);
    }, []);

    const addTemplateNode = useCallback(() => {
        const id = 'TEMPLATE-' + Date.now();
        const newNode= { id, type:'templateNode', position:{x: Math.random() * 300, y: Math.random() * 300, }, data: {'text': '', onChange: onChange}};
        setNodes(nodes => [...nodes, newNode]);
    }, []);

    const addForwardTemplateNode = useCallback(() => {
        const id = 'FORWARD-' + Date.now();
        const newNode= { id, type:'forwardTemplateNode', position:{x: Math.random() * 300, y: Math.random() * 300, }, data: {'text': '', onChange: onChange}};
        setNodes(nodes => [...nodes, newNode]);
    }, []);
    
    const addMediaNode = useCallback(() => {
        const id = 'MEDIA-' + Date.now();
        const newNode= { id, type:'mediaNode', position:{x: Math.random() * 300, y: Math.random() * 300, }, data: {'text': '', fileOnChange: fileOnChange}};
        setNodes(nodes => [...nodes, newNode]);
    }, []);

    const addToDoListNode = useCallback(() => {
        const id = 'TODO-' + Date.now();
        const newNode= { id, type:'todolistNode', position:{x: Math.random() * 300, y: Math.random() * 300, }, data: {'text': '', onChange: onChange}};
        setNodes(nodes => [...nodes, newNode]);
    }, []);

    const addUnitNode = useCallback(() => {
        const id = 'UNIT-' + Date.now();
        const newNode= { id, type:'unitNode', position:{x: Math.random() * 300, y: Math.random() * 300,}, data: {'text': '', onChange: onChange, unitOnChange: unitOnChange}};
        setNodes(nodes => [...nodes, newNode]);
    }, []);

    const addEndNode = useCallback(() => {
        const id = 'END-' + Date.now();
        const newNode= { id, type:'endNode', position:{x: Math.random() * 300, y: Math.random() * 300}, data: {'text': '', }, };
        setNodes(nodes => [...nodes, newNode]);
    }, []);
    // ----------------------------------------------------------------------------------------------------------------


    // API Code FETCH, POST and PATCH ------------------------------------------------------------------------------------
    // FETCH Requests
    const fetchNodes = async() => {
        try {
            if (userToken) {
                const initialNodes = await API.getNodes({'token': userToken, 'id': chatflow_id});
                const formattedNodes = initialNodes.map(node => ({
                    id: node.node_id,
                    type: node.node_type,
                    position: { x: node.position_x, y: node.position_y },
                    data: { text: node.text, node_file: node.node_file, onChange: onChange, fileOnChange: fileOnChange, unitOnChange: unitOnChange},
                }));
                setNodes(formattedNodes);
            }
        }catch(error) {
            console.error(error);
            throw error;
        }
    }

    const fetchEdges = async() => {
        try {
            if (userToken) {
                const initialEdges = await API.getEdges({'token': userToken, 'id': chatflow_id});
                const formattedEdges = initialEdges.map(edge => ({
                    id: edge.edge_id,
                    source: edge.source,
                    target: edge.target,
                    sourceHandle: edge.sourceHandle,
                    targetHandle: edge.targetHandle,
                }));
                setEdges(formattedEdges);
            }
        } catch(error) {
            console.error(error);
            throw error;
        }
    }


    // POST Requests
    const addNode = async(nodeList) => {
        if (userToken) {

            // Get list of nodes that exists in the database
            const initialNodes = await API.getNodes({'token': userToken, 'id': chatflow_id});
            const formattedNodes = initialNodes.map(node => ({
                id: node.id,
                node_id: node.node_id,
                type: node.node_type,
                position: { x: node.position_x, y: node.position_y },
                data: { text: node.text, node_file: node.node_file },
            }));

            // Check each node one by one for changes
            for (const node of nodeList) {
                try {
                    // Check if node already exists in the database
                    const nodeExists = formattedNodes.find(dbNode => dbNode['node_id'] === node['id']);
                    if (nodeExists) {
                        // Check if theres any change in position_x, position_y, or text attribute
                        if (nodeExists['position']['x'] !== node['position']['x'] || nodeExists['position']['y'] !== node['position']['y'] || nodeExists['data']['text'] !== node['data']['text'] || nodeExists['data']['node_file'] !== node['data']['node_file']) {
                            
                            // Update node if there's any change (Use form as there is a file attribute)
                            const formData = new FormData();
                            formData.append('token', userToken);
                            formData.append('id', nodeExists['id']);
                            formData.append('node_id', node['id']);
                            formData.append('position_x', node['position']['x']);
                            formData.append('position_y', node['position']['y']);
                            formData.append('text', node['data']['text'] || '');
                            formData.append('node_file', node['data']['node_file'] || 'null');
                            const response = await API.updateNode(formData);
                        }
                    }else {
                        // Add node if node does not exist in the database
                        const formData = new FormData();
                        formData.append('token', userToken);
                        formData.append('chatflow_id',  chatflow_id);
                        formData.append('node_id', node['id']);
                        formData.append('position_x', node['position']['x']);
                        formData.append('position_y', node['position']['y']);
                        formData.append('text', node['data']['text'] || '');
                        formData.append('node_file', node['data']['node_file'] || 'null');
                        formData.append('node_type', node['type']);
                        const response = await API.addNode(formData);
                    }
                } catch(error){
                    console.error(error);
                    throw error;
                }
            }

            // After updating all nodes, Check if theres any node that was deleted
            const deletedNodes = formattedNodes.filter(dbNode => !nodeList.find(node => node['id'] === dbNode['node_id']));
            for (const deletedNode of deletedNodes) {
                try {
                    const response = await API.deleteNode({'token': userToken, 'id': deletedNode['id']});
                } catch(error) {
                    console.error(error);
                    throw error;
                }
            }
        } 
    }


    const addEdge = async(edgeList) => {
        if (userToken) {
            const initialEdges = await API.getEdges({'token': userToken, 'id': chatflow_id});
            const formattedEdges = initialEdges.map(edge => ({
                id: edge.id,
                edge_id: edge.edge_id,
                source: edge.source,
                target: edge.target,
                sourceHandle: edge.sourceHandle,
                targetHandle: edge.targetHandle,
            }));
            for (const edge of edgeList) {
                try {
                    const edgeExists = formattedEdges.find(dbEdge => dbEdge['edge_id'] === edge['id']);
                    if (edgeExists) {
                        if (edgeExists['source'] !== edge['source'] || edgeExists['target'] !== edge['target'] || edgeExists['sourceHandle'] !== edge['sourceHandle'] || edgeExists['targetHandle'] !== edge['targetHandle']) {
                            const edge_id = edge['source'] + '-' + edge['target'];
                            const response = await API.updateEdge({
                                'token': userToken,
                                'id': edgeExists['id'],
                                'edge_id': edge_id,
                                'source': edge['source'],
                                'target': edge['target'],
                                'sourceHandle': edge['sourceHandle'],
                                'targetHandle': edge['targetHandle'],
                            });
                        }
                        
                    }else {
                        const response = await API.addEdge({
                            'token': userToken,
                            'edge_id': edge['id'],
                            'source': edge['source'],
                            'target': edge['target'],
                            'sourceHandle': edge['sourceHandle'],
                            'targetHandle': edge['targetHandle'],
                            'chatflow_id': chatflow_id
                        });
                    }
                } catch(error) {
                    console.error(error);
                    throw error;
                }
            }
            const deletedEdges = formattedEdges.filter(dbEdge => !edgeList.find(edge => edge['id'] === dbEdge['edge_id']));

            // If no edge has been deleted
            if (!deletedEdges) {
                return;
            }
            for (const deletedEdge of deletedEdges) {
                try {
                    const response = await API.deleteEdge({'token': userToken, 'id': deletedEdge['id']});
                } catch(error) {
                    console.error(error);
                    throw error;
                }
            }
        }
    }

    // PATCH Requests
    const updateChatFlow = async(chatFlowName) => {
        if (userToken) {
            // Set all other chatflow publish_state to false before setting this one to true
            try {
                const chatFlowList = await API.getChatFlowList(userToken);

                const streamFlow = chatFlowList.filter(chatflow => chatflow.type === 'streamflow');

                const publishedChatFlow = streamFlow.find(chatflow => chatflow.publish_state === true);

                if (publishedChatFlow) {
                    const unpublishedData = {
                        'token': userToken,
                        'id': publishedChatFlow.id,
                        'chatflow_name': publishedChatFlow.chatflow_name,
                        'publish_state': false
                    }
                    await API.updateChatFlow(unpublishedData);
                }
            } catch(error) {
                console.error(error);
                throw error;
            }
            
            const now = new Date();
            const nowISOString = now.toISOString();
            const nowFormatted = nowISOString.replace('T', ' ').replace('Z', '+00:00');
            const data = {
                'token': userToken,
                'id': chatflow_id,
                'chatflow_name': chatFlowName,
                'publish_date': nowFormatted,
                'publish_state': true,
            }
            await API.updateChatFlow(data);
        }
    }
    // ----------------------------------------------------------------------------------------------------------------


    // Chatflow validation and error handling -------------------------------------------------------------------------
    const validateChatFlow = () => {
        // 1. Check if there are any nodes and edges
        if (nodes.length === 0 || edges.length === 0) {
            toast.error('Chatflow must have at least one node and one edge!');
            return false;
        }

        // 2. There must be at least one end node
        const endNodes = nodes.filter(node => node.type === 'endNode');
        if (endNodes.length < 1) {
            toast.error('There must be at least one end node!');
            return false;
        }

        // Build adjecency edge for easier graph traversal
        const adjacencyEdge = {};
        let adjecencyValue = [];
        edges.forEach(node => {
            if (!adjacencyEdge[node.source]) {
                adjacencyEdge[node.source] = [];
            }
            adjacencyEdge[node.source].push(node.target);
            adjecencyValue.push(node.target);
        });

        // Build node types for easier graph traversal
        const nodeTypeList = {};
        for (let i = 0; i < nodes.length; i++) {
            const node = nodes[i];
            nodeTypeList[node.id] = node.type;

            let isValid = true;
            // Check if nodes are connected (Validation is done here to prevent multiple loops for the same data)
            if ((!adjacencyEdge[node.id] && nodeTypeList[node.id] !== 'endNode') || (adjecencyValue.includes(node.id) && !(nodeTypeList[node.id] !== 'startNode'))) {
                toast.error('All nodes must be connected!');
                isValid = false;
            }
            // Check if there are nodes that do not consist of any text
            if (nodeTypeList[node.id] === 'msgNode' || nodeTypeList[node.id] === 'todolistNode' || nodeTypeList[node.id] === 'unitNode' || nodeTypeList[node.id] === 'conditionNode') {
                if (node.data.text === '') {
                    toast.error('All nodes must have text!');
                    isValid = false;
                }
            }
            // Check if there are nodes that do not consist of any file
            if (nodeTypeList[node.id] === 'mediaNode') {
                if (!node.data.node_file) {
                    toast.error('All media nodes must have a file!');
                    isValid = false;
                }
            }
            if (!isValid) {
                return false;
            }
        }
       
        // For StreamFlow
        if (chatflow_type === 'streamflow') {
            const startNodes = nodes.filter(node => node.type === 'startNode');

            // 1. Check there must be one and only one start node
            if (startNodes.length !== 1) {
                toast.error('There must be one and only one start node!');
                return false;
            }


        } else if (chatflow_type === 'massflow') {
            // For MassFlow
            const checkInNodes = nodes.filter(node => node.type === 'checkInNode');
            const checkOutNodes = nodes.filter(node => node.type === 'checkOutNode');
            const startNodes = nodes.filter(node => node.type === 'startNode');

            // Check for start node
            if (startNodes.length !== 1) {
                toast.error('There must be one and only one start node!');
                return false;
            }

            // 1. Check there must be one and only one check in start node and check out start node
            if (checkInNodes.length < 1 && checkOutNodes.length < 1) {
                toast.error('There must be atleast one check in start node and check out start node!');
                return false;
            }

            if (checkInNodes.length != checkOutNodes.length) {
                toast.error('Check in and check out nodes must be equal!');
                return false;
            }

            // 2. Check if start Node leads to unit node
            const startNode = startNodes[0].id;
            const startNext = adjacencyEdge[startNode];
            startNext.forEach(node => {
                if (nodeTypeList[node] !== 'unitNode') {
                    toast.error('Start node must lead to a unit node!');
                    return false;
                }
            })
        }

        // If everything is fine
        return true 
    }
    // ----------------------------------------------------------------------------------------------------------------

    return (
        <div className='flow-content'>

            <div className='flow-topBar'>
                <div className='flow-head'>
                    <button className='flow-back' onClick= { onBack }><FaArrowLeft/></button>
                    <input type='text' value={chatFlowName} onChange={(evt)=>setChatFlowName(evt.target.value)}/>
                </div>
                <div className='flow-save'>
                    <button onClick={onSave}>Save draft</button>
                    <button onClick={onPublish} style={{ display: chatflow_type === 'massflow' ? 'none' : 'inline-block' }}>Publish</button>
                </div>
            </div>

            <div className='flow-container' ref={divRef}>
                <ReactFlow nodeTypes={nodeTypes} nodes={nodes} edges={edges} defaultEdgeOptions= {{animated: true}}  onNodesChange={onNodesChange} 
                    onEdgesChange={onEdgesChange} onConnect={onConnect} onInit={setRfInstance} defaultViewport={defaultViewport}>
                    <Background variant='dots'/>
                    <MiniMap zoomable pannable/>
                </ReactFlow>
                
                <div className='flow-sidebar'>
                    <h2>Add Nodes</h2>
                    <div className='sidebar-components'>
                        {selectedNode.map((node, index) => {
                            const nodeKey = Object.keys(node)[0];
                            const nodeValue = node[nodeKey];
                            return(
                                <button className='flow-sidebtn' key={index} id={nodeValue[2]} onClick={nodeValue[1]}> {nodeValue[0]} <b>{nodeKey}</b></button>
                            )
                        })}
                    </div>
                </div>
            </div>     
            <ToastContainer position='bottom-center' autoClose={5000} hideProgreeBar={false} closeOnClick pauseOnFocusLoss draggable rtl={false} pauseOnHover theme='colored' transition={Bounce} />   
        </div>
        
    );
}


function StreamFlow() {
    return(
        <div style={{width: '100dvw', height: '100dvh'}}>
            <SideNav2/>
            <ReactFlowProvider>
                <ChatbotFlow/>
            </ReactFlowProvider>
        </div>
    )
}


export default StreamFlow;