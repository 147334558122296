import React, { useState, useEffect, useRef, useContext } from 'react';
import { Handle, Position, useReactFlow } from 'reactflow';
import Select from 'react-select';
import { toast } from "react-toastify";
import { UserContext } from '../user-context.js';

import { FaBoltLightning, FaCircleStop } from 'react-icons/fa6';
import { IoIosArrowBack } from "react-icons/io";
import { TiFlowMerge } from "react-icons/ti";
import { TbTemplate } from "react-icons/tb";
import { BiMessage } from "react-icons/bi";
import { LuListTodo } from "react-icons/lu";
import { AiFillHome } from "react-icons/ai";
import { MdOutlinePermMedia, MdCancel } from "react-icons/md";

import './css/CustomNodes.css';

function CheckInNode({ id, data, isConnectable, xPos, yPos }){

    const [isHovered, setIsHovered] = useState(false);
    const instance = useReactFlow();


    const findPosition = (nodes, edges, baseX, baseY) => {
        const unitNodes = nodes.filter(node => node.type === 'unitNode');
        
        // Find nodes not connected to checkout
        const unconnectedNodes = unitNodes.filter(unitNode => {
            return edges.some(edge => 
                edge.source === 'CHECKIN' && 
                edge.target === unitNode.id
            );
        });

        return  {x: baseX + 300, y: baseY + 200 * (unconnectedNodes.length)};

    };

    const findCheckOutUnitNodes = (nodes, edges) => {
        // Get unit nodes
        const unitNodes = nodes.filter(node => node.type === 'unitNode');
        
        // Find nodes not connected to checkout
        const unconnectedNodes = unitNodes.filter(unitNode => {
            return !edges.some(edge => 
                edge.source === 'CHECKIN' && 
                edge.target === unitNode.id
            );
        });
        
        return unconnectedNodes;
    };

    const handleClick = (e) => {
        e.stopPropagation();
        const nodes = instance.getNodes();
        const edges = instance.getEdges();

        const nodeId = 'UNIT-' + Date.now();
        // Find available position
        const position = findPosition(nodes, edges, xPos, yPos);
        
        const newNode = { id: nodeId, type: 'unitNode', position: position, data: {'text': '', onChange: data.onChange, unitOnChange: data.unitOnChange}};
        const edgeId = `${id}-${nodeId}`;
        const newEdge = {id: edgeId, source: id, target: nodeId, sourceHandle: 'checkInSource', targetHandle: 'unitTarget'};

        // Find and update checkout node position and unit node checkout positions
        const checkOutUnitNodes = findCheckOutUnitNodes(nodes, edges);
        const checkoutNode = nodes.find(node => node.id === 'CHECKOUT');
        if (checkoutNode) {
            checkoutNode.position.y = position.y + 200; // Offset checkout node below new node
        }

        const updatedUnitNodes = checkOutUnitNodes.map((node, index) => ({...node, position: { x: node.position.x, y: position.y + 200 * index },}));

        data.setNodes(nodes => {
            // Update positions of unconnected unit nodes
            const updatedNodes = nodes.map(node => {
                if (node.id === 'CHECKOUT') {
                    return {...node, position: { x: node.position.x, y: position.y + 200 }};
                }
                // Check if node is in unconnected nodes
                if (updatedUnitNodes.some(unNode => unNode.id === node.id)) {
                    const nodeIndex = updatedUnitNodes.findIndex(unNode => unNode.id === node.id);
                    return {
                        ...node, 
                        position: { 
                            x: node.position.x, 
                            y: position.y + 200 * (nodeIndex + 1)
                        }
                    };
                }
                return node;
            });
        
            // Add new node to updated nodes
            return [...updatedNodes, newNode];
        });
        data.setEdges(edges => [...edges, newEdge]);
    };
    return (
        <div className='startNode' onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} style={{ position: 'relative' }}>
            <div className="startNode-body">
                <FaBoltLightning /> <b>Check-in</b>
                <button onClick={handleClick} className={`addNodeBtn ${isHovered ? 'visible' : ''}`}>
                    +
                </button>
            </div>
            <Handle type='source' position={Position.Right} id='checkInSource' isConnectable={isConnectable} style={{opacity: 0}}/>
        </div>
    )
}

function CheckOutNode({ id, data, isConnectable, xPos, yPos }){
    const [isHovered, setIsHovered] = useState(false);
    const instance = useReactFlow();


    const findAvailablePosition = (nodes, baseX, baseY) => {
        const occupied = new Set();
        nodes.forEach(node => {
            const posKey = `${node.position.x},${node.position.y}`;
            occupied.add(posKey);
        });
    
        let verticalOffset = 0;
        const horizontalOffset = 300;
        const spacingIncrement = 200;
    
        while (true) {
            const newX = baseX + horizontalOffset;
            const newY = baseY + verticalOffset;
            const posKey = `${newX},${newY}`;
            
            if (!occupied.has(posKey)) {
                return { x: newX, y: newY };
            }
            verticalOffset += spacingIncrement;
        }
    };

    const handleClick = (e) => {
        e.stopPropagation();
        const nodes = instance.getNodes();
        const nodeId = 'UNIT-' + Date.now();
        
        // Find available position
        const position = findAvailablePosition(nodes, xPos, yPos);
        
        const newNode = { id: nodeId, type: 'unitNode', position: position, data: {'text': '', onChange: data.onChange, unitOnChange: data.unitOnChange}};
        const edgeId = `${id}-${nodeId}`;
        const newEdge = {id: edgeId, source: id, target: nodeId, sourceHandle: 'checkOutSource', targetHandle: 'unitTarget'};

        data.setNodes(nodes => [...nodes, newNode]);
        data.setEdges(edges => [...edges, newEdge]);
    };
    return (
        <div className='startNode' onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} style={{ position: 'relative' }}>
            <div className="startNode-body">
                <FaBoltLightning /> <b>Check-out</b>
                <button onClick={handleClick} className={`addNodeBtn ${isHovered ? 'visible' : ''}`}>
                    +
                </button>
            </div>
            <Handle type='source' position={Position.Right} id='checkInSource' isConnectable={isConnectable} style={{opacity: 0}}/>
        </div>
    )
}

function UnitNode({id, data, isConnectable }){

    const [isAddNewUnit, setIsAddNewUnit] = useState(false);
    const [unit, setUnit] = useState('');
    const [isHovered, setIsHovered] = useState(false);
    const [unitList, setUnitList] = useState([]);
    
    useEffect(() => {
        setUnitList(JSON.parse(data.text || '[]'));
        data.unitOnChange('add', JSON.parse(data.text || '[]'))
    }, []);

    const handleAddUnit = () => {
        if (unit === '') {
            toast.error('Unit number cannot be empty');
            return;
        }

        if(data.unitOnChange('add', unit)){
            setUnitList([...unitList, unit]);
            data.onChange(id, JSON.stringify([...unitList, unit]));
        } else {
            toast.error('Unit number already exists');
        }

        setUnit('');
        setIsAddNewUnit(false);
    }

    const handleDeleteUnit = (unit) => {
        const newUnitList = unitList.filter((item) => item !== unit);
        setUnitList(newUnitList);
        data.unitOnChange('remove', unit);
        data.onChange(id, JSON.stringify(newUnitList));
    }

    const handleClick = () => {

    }

    return (
        <div className='unitNode node' onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
            <div className='unitNode-head'>
                <AiFillHome/><b>Unit No</b>
            </div>

            <div className='unitNode-body'>
                <div className='unit-grid'>
                    {unitList.map((unit, index) => {
                        return (
                            <div className='unit-card' key={index}>
                                {unit}
                                <MdCancel className='cancel-active' onClick={() => handleDeleteUnit(unit)}/>
                            </div>
                        )
                    })}
                </div>

                <div className={`add-unit-input ${isAddNewUnit ? 'unit-active' : ''}`}>
                    <div className='add-unit-input-header'>
                        <IoIosArrowBack onClick={() => setIsAddNewUnit(false)} style={{fontSize: '1rem'}}/>
                        <h3>Add new unit</h3>
                    </div>
                    <div className='input-container'>
                        <input id="unitNode" placeholder='Enter unit number' value={unit} name="unitNode" onChange={(evt)=>setUnit(evt.target.value)} className="nodrag" />
                        <label htmlFor="unitNode">Enter unit number</label>
                    </div>
                    <button className='add-unit-button' onClick={() => handleAddUnit()}>
                        Add new unit
                    </button>
                </div>
                <div className='add-unit-div'>
                    <button className='add-unit-card' onClick={() => setIsAddNewUnit(true)} style={{'display': isAddNewUnit ? 'none': 'block'}}>
                        Add new units
                    </button>
                </div>
            </div>
            <button onClick={handleClick} className={`addNodeBtn ${isHovered ? 'visible' : ''}`}>
                +
            </button>
            
            <Handle type='target' position={Position.Left} id='unitTarget' isConnectable={isConnectable}/>
            <Handle type='source' position={Position.Right} id='unitMatch' isConnectable={isConnectable}/>
        </div>
    )
}


function TemplateNode({id, data, isConnectable }){
    
    const { wabaId, userId, SystemUserToken } = useContext(UserContext);
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);

    useEffect(() => {
        const getWhatsAppTemplate = async() => {

            // Set default template and set selected template to value obtained from database
            const response = await fetch('https://graph.facebook.com/v20.0/'+ wabaId +'/message_templates?limit=250&fields=name&status=APPROVED', {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + SystemUserToken,
                }
            })
            const resp = await response.json();
            const templateList = resp.data?.map((template) => {
                return {
                    'value': template.name,
                    'label': template.name,
                }
            })
            setTemplates(templateList);
            setSelectedTemplate(templateList?.find((item) => item.value === data.text.match(/^[^{]*/)[0]));
        }

        getWhatsAppTemplate();
    }, [userId]);


    const handleTemplateChange = (evt) => {
        setSelectedTemplate(evt);
        data.onChange(id, evt.value);
    }


    return (
        <div className='msgNode node'>
            <div className='msgNode-head'>
                <TbTemplate /><b>WhatsApp Template</b>
            </div>

            <div className='todolistNode-body'>
                <Select options={templates} value={selectedTemplate} onChange={(evt) => handleTemplateChange(evt)} className="nodrag"/>
            </div>

            <Handle type='target' position={Position.Top} id='templateTarget' isConnectable={isConnectable}/>
            <Handle type='source' position={Position.Bottom} id='templateMatch' isConnectable={isConnectable}/>
        </div>
    )
}

function ToDoListNode({id, data, isConnectable }){

    const todolistType = [{value: "Extensions", label: "Extensions"}, {value: "Inquiries", label: "Inquiries"}, {value: "NoWhatsApp", label: "NoWhatsApp"}];
    const [selectedList, setSelectedList] = useState(todolistType.find((item) => item.value === data.text));

    return (
        <div className='todolistNode node'>
            <div className='todolistNode-head'>
                <LuListTodo/><b>To do list</b>
            </div>

            <div className='todolistNode-body'>
                <Select options={todolistType} value={selectedList} onChange={(evt) => {setSelectedList(evt); data.onChange(id, evt.value)}} className="nodrag"/>
            </div>
            <Handle type='target' position={Position.Top} id='todolistTarget' isConnectable={isConnectable}/>
            <Handle type='source' position={Position.Bottom} id='todolistMatch' isConnectable={isConnectable}/>
        </div>
    )
}

function EndNode({ data, isConnectable }){
    return (
        <div className='endNode node'>
            <FaCircleStop/> <b>END</b>
            <Handle type='target' position={Position.Top} id='endNode' isConnectable={isConnectable}/>
        </div>
    )
}


export {CheckInNode, CheckOutNode, TemplateNode, ToDoListNode, UnitNode, EndNode};