import React, { useEffect, useState, useContext, useRef } from "react";
import { UserContext } from "../user-context";
import Sidenav from "../components/Sidenav";
import { API } from "../api-service";
import './css/Notifications.css';
import {Helmet} from 'react-helmet';

function Notifications() {
    const { userToken, wabaId, profileId} = useContext(UserContext);
    const [notifications, setNotifications] = useState([]);
    const [newNotifications, setNewNotifications] = useState([]);
    const [toggleNotification, setToggleNotification] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [buildingsData, setBuildingsData] = useState({});
    const socket = useRef(null);
    
    const fetchBuildingAndUnit = async () => {
        try {
            const chatFlowList = await API.getChatFlowList(userToken);
            const massFlowBuildings = chatFlowList.filter(chatflow => chatflow.type === 'massflow');
            
            const buildingsWithUnits = {};
            
            await Promise.all(massFlowBuildings.map(async building => {
                try {
                    const nodes = await API.getNodes({ 'token': userToken, 'id': building.id });
                    const unitNodes = nodes?.filter(node => node.node_type === 'unitNode');
                    
                    const units = unitNodes.reduce((acc, node) => {
                        const nodeTextArray = JSON.parse(node.text);
                        return [...acc, ...nodeTextArray];
                    }, []);

                    buildingsWithUnits[building.id] = {
                        buildingName: building.chatflow_name,
                        units: units
                    };
                } catch (error) {
                    console.error(`Error fetching units for building ${building.chatflow_name}:`, error);
                }
            }));

            setBuildingsData(buildingsWithUnits);
            return buildingsWithUnits;
        } catch (error) {
            console.error('Error fetching building data:', error);
            return {};
        }
    };

    useEffect(() => {
        const fetchNotifications = async () => {
            setIsLoading(true);
            try {
                const notifs = await API.getNotifications(userToken, wabaId);
                const buildings = await fetchBuildingAndUnit();

                const enhancedNotifications = notifs.map(notification => {
                    // Safely access chatflow_id from sender
                    let chatflowIds = notification.message?.sender?.chatflow_id || [];
                    if (!Array.isArray(chatflowIds)) {
                        try {
                            chatflowIds = JSON.parse(chatflowIds);
                        } catch {
                            chatflowIds = [];
                        }
                    }

                    const buildingInfo = chatflowIds.map(id => {
                        const building = buildings[id];
                        if (building) {
                            return {
                                buildingName: building.buildingName,
                                unit: notification.message?.sender?.unit_no || []
                            };
                        }
                        return null;
                    }).filter(Boolean);

                    return {
                        ...notification,
                        message: {
                            ...notification.message,
                            id: notification.id,
                            timestamp: formatTimestampToDateAnd12Hour(notification.message.timestamp),
                            buildings: buildingInfo,
                            profile: {
                                checkinDates: notification.message?.sender?.checkin_datetime || [],
                                checkoutDates: notification.message?.sender?.checkout_datetime || []
                            }
                        }
                    };
                });

                setNotifications(enhancedNotifications);
            } catch (error) {
                console.error('Error:', error);
            } finally {
                setIsLoading(false);
            }
        };

        if (userToken && wabaId) {
            fetchNotifications();
        }
    }, [userToken, wabaId]);

    const formatDateRange = (checkinDates, checkoutDates) => {
        if (!checkinDates?.length || !checkoutDates?.length) return '';
        
        const latestCheckin = new Date(checkinDates[checkinDates.length - 1]);
        const latestCheckout = new Date(checkoutDates[checkoutDates.length - 1]);
        
        const formatDate = (date) => {
            return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}`;
        };

        return `${formatDate(latestCheckin)}-${formatDate(latestCheckout)}`;
    };

    function formatTimestampToDateAnd12Hour(timeString) {
        const date = new Date(timeString);
        return date.toLocaleString('en-US', { 
            month: 'numeric', 
            day: 'numeric', 
            year: 'numeric', 
            hour: '2-digit', 
            minute: '2-digit', 
            hour12: true 
        });
    }

    function formatTimestampTo12Hour(timeString) {
        const date = new Date(timeString);
        return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
    }
    

    const getUserName = input => {
        const regex = /.*\d+/;
        const match = input?.match(regex);
        
        if (match) {
            const lastNumberIndex = match[0].length;
            return input.substring(lastNumberIndex).trim();
        }
        return input;
    };

    // Get first letter for avatar
    const getInitial = (name) => {
        if (!name) return '?';
        const actualName = getUserName(name);
        return actualName.charAt(0).toUpperCase();
    };

    useEffect(() => {
        API.getNotifications(userToken, wabaId)
            .then((notifications) => {
                const formattedNotifications = notifications.map(notification => {
                const formattedTimestamp = formatTimestampToDateAnd12Hour(notification.message.timestamp);
                return { ...notification, message: { ...notification.message, id:notification.id, timestamp: formattedTimestamp } };
            });
                setNotifications(formattedNotifications);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
      }, [userToken, wabaId]);



    useEffect(() => {
        if (!toggleNotification) {
            const wabaIdLink = localStorage.getItem('waba_id')
            socket.current = new WebSocket(`wss://streamhost-django-80bc172b3b26.herokuapp.com/ws/notifications/${profileId}/`);
            setToggleNotification(true);
        }
        
        // console.log("notification websocket ", socket.current);
        if (socket.current) {
            socket.current.onmessage = (event) => {
                
                const messageData = JSON.parse(event.data);
                console.log(messageData)
                const newNotification = {
                    clientWabaId: messageData.clientWabaId,
                    message: messageData.message,
                    name: messageData.message_sender,
                };
                setNewNotifications(currentNotifications => [...currentNotifications, newNotification]);
            };
        }

        // Cleanup function to close the WebSocket connection
        return () => {
            if (socket.current) {
                socket.current.close();
                console.log("WebSocket closed");
                setToggleNotification(false);
                socket.current = null;
            }
        };
    }, []); // This effect does not depend on `socket` directly

    
    const handleDeleteSelected = async() => {
        const notification = await API.getNotifications(userToken, wabaId);
        console.log("notification ", notification);
        notification.map(async (notif) => {
            await API.deleteNotification({'token': userToken, 'id': notif.id});
        })
      
        setNewNotifications([]);
        setNotifications([]);
    };

    const [expandedUsers, setExpandedUsers] = useState({});

    const normalizedNewNotifications = newNotifications.map((notification, index) => ({
        message: {
            sender: {
                name: notification?.name,
                profilePicture: notification.profilePicture
            },
            text: notification.message,
            timestamp: new Date().toLocaleTimeString('en-US', { 
                month: 'numeric', 
                day: 'numeric', 
                year: 'numeric', 
                hour: '2-digit', 
                minute: '2-digit', 
                hour12: true 
            })
            
        },
        isNew: true,
        index: notifications.length + index
    }));

    const allNotifications = [...normalizedNewNotifications.reverse(), ...notifications];

    

    const toggleExpand = (userName) => {
        setExpandedUsers(prev => ({
            ...prev,
            [userName]: !prev[userName]
        }));
    };

    const groupNotificationsByDate = (notifications) => {
        const today = new Date().setHours(0, 0, 0, 0);
        const yesterday = new Date(today - 86400000).setHours(0, 0, 0, 0);
      
        return notifications.reduce((acc, notification) => {
          const notificationDate = new Date(notification.message.timestamp).setHours(0, 0, 0, 0);
          
          if (notificationDate === today) {
            acc.Today = acc.Today || [];
            acc.Today.push(notification);
          } else if (notificationDate === yesterday) {
            acc.Yesterday = acc.Yesterday || [];
            acc.Yesterday.push(notification);
          } else {
            const dateString = new Date(notificationDate).toLocaleDateString();
            acc[dateString] = acc[dateString] || [];
            acc[dateString].push(notification);
          }
          
          return acc;
        }, {});
      };

    const groupedNotifications = groupNotificationsByDate(allNotifications);

    const renderNotificationGroup = (date, dateNotifications) => {
        return (
          <div key={date} className="notification-date-group">
            <h2 className="notification-date-header">{date}</h2>
            {Object.entries(groupNotificationsByUser(dateNotifications)).map(([userName, userNotifs]) => 
              renderUserNotifications(userName, userNotifs)
            )}
          </div>
        );
      };

    const groupNotificationsByUser = (notifications) => {
    return notifications.reduce((acc, notification) => {
        const userName = notification?.message?.sender?.name;
        acc[userName] = acc[userName] || [];
        acc[userName].push(notification);
        return acc;
    }, {});
    };

    const renderUserNotifications = (userName, userNotifs) => {
        const isExpanded = expandedUsers[userName] || false;
        const visibleNotifications = isExpanded ? userNotifs : userNotifs.slice(0, 10);
    
        function parseTimeToMinutes(timeStr) {
            const [time, period] = timeStr.split(' ');
            let [hours, minutes] = time.split(':').map(Number);
            if (period === 'PM' && hours < 12) hours += 12;
            if (period === 'AM' && hours === 12) hours = 0;
            return hours * 60 + minutes;
        }
    
        visibleNotifications.sort((a, b) => {
            const minutesA = parseTimeToMinutes(a.message.timestamp);
            const minutesB = parseTimeToMinutes(b.message.timestamp);
            return minutesA - minutesB;
        });
    
        return (
            <div key={userName} className="notification-group">
                <div className={`notification-stack ${isExpanded ? 'expanded' : ''}`}>
                    {visibleNotifications.map((notification, index) => {
                        const profile = notification.message.profile;
                        const buildings = notification.message.buildings;
                        const dateRange = profile ? 
                            formatDateRange(profile.checkinDates, profile.checkoutDates) : '';
                        
                        return (
                            <div
                                key={index}
                                className="notification-bar"
                                style={{
                                    zIndex: userNotifs.length - index,
                                    top: isExpanded ? 'auto' : index < 5 ? `${index * 9}px` : '45px',
                                    transform: isExpanded ? 'none' : index < 5 ? `scale(${1 - index * 0.02})` : 'scale(0.9)',
                                    opacity: isExpanded || index < 5 ? 1 : 0,
                                }}
                                onClick={() => toggleExpand(userName)}
                            >
                                <div className="notification-avatar">
                                    {getInitial(userName)}
                                </div>
                                <div className="notification-content-wrapper">
                                    <div className="notification-header">
                                        <h2 className="notification-user">{getUserName(userName)}</h2>
                                        <span className="notification-time">
                                            {formatTimestampTo12Hour(notification.message.timestamp)}
                                        </span>
                                    </div>
                                    <p className="notification-message">{notification.message.text}</p>
                                    {buildings && buildings.length > 0 && (
                                        <div className="notification-tags">
                                            {buildings.map((building, i) => (
                                                <span key={i} className="tag building-tag-noti">
                                                     {`${building.buildingName} ${building.unit}`}
                                                </span>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                    {!isExpanded && userNotifs.length > 1 && (
                        <div className="notification-counter">
                            {userNotifs.length}
                        </div>
                    )}
                </div>
            </div>
        );
    };


    return (
        <React.Fragment>
            <Helmet>
                <title>StreamHost | Notification Page</title>
            </Helmet>
            <Sidenav />
            <div className='notifications-container'>
                <div className='notifications-header'>
                    <h1>Notifications</h1>
                    <div className='notifications-actions'>
                        <button className="mark-all-read-button" onClick={handleDeleteSelected}>
                            Mark all as read
                        </button>
                    </div>
                </div>
                {isLoading ? (
                    <div className="loader-container">
                        <div className="loader"></div>
                    </div>
                ) : Object.keys(groupedNotifications).length === 0 ? (
                    <div className='notification-no-message'>
                        <h2><span>No notifications</span></h2>
                    </div>
                ) : (
                    Object.entries(groupedNotifications)
                        .sort(([dateA], [dateB]) => {
                            if (dateA === 'Today') return -1;
                            if (dateB === 'Today') return 1;
                            if (dateA === 'Yesterday') return -1;
                            if (dateB === 'Yesterday') return 1;
                            return new Date(dateB) - new Date(dateA);
                        })
                        .map(([date, notifications]) => renderNotificationGroup(date, notifications))
                )}
            </div>
        </React.Fragment>
    );
}

export default Notifications;
