
import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import App from "./pages/App";
import {Auth} from "./pages/auth/Login";
import { ResetPassword, Activate, ForgotPassword } from "./pages/auth/Auth";
import Link from "./pages/Link";
import ChatFlow2 from './pages/ChatFlow2';
import { Settings, General, QuickResponse, PlansAndBilling, PasswordAndSecurity, WhatsAppTemplate, Chatbot, NotificationAndAccountSettings } from "./pages/Settings";
import Broadcast from './pages/Broadcast';
import Inbox from "./pages/Inbox";
import ChatFlowList from './pages/ChatFlowList';
import StreamFlow from './pages/StreamFlow';
import ToDoList from './pages/ToDoList';
import PrivacyPolicy from "./pages/PrivacyPolicy";
import { Payment, PaymentSuccess }from './pages/Payment';
import Contacts from "./pages/Contacts";
import Notification from "./pages/Notifications";
import FAQ from './pages/FAQ';
import Error from './pages/Error';
import { ContactSales } from './pages/ContactSales';

import Posts from './pages/Posts';
import PostDetail from './pages/PostDetail';
import PostDetail2 from './pages/PostDetail2';
import AuthorPosts from './pages/AuthorPosts';
import CategoryPosts from './pages/CategoryPosts';

import { StreamHostInbox } from './sub_pages/StreamhostInbox';
import { SubLogin } from './sub_pages/sublogin';

import { createHashRouter, RouterProvider } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Dashboard from "./pages/Dashboard";
import { UserProvider } from "./user-context";
import Pricing from "./pages/Pricing";
import { CookiesProvider } from 'react-cookie';
import { WebSocketProvider } from './pages/Websocket';

import { ErrorBoundary } from "react-error-boundary";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';



// Handle Error thrown to the app (Mainly to prevent app from crashing)
function CustomFallback({ error, resetErrorBoundary }) {
    console.error('Caught error:', error);

    // Reset immediately after the error
    setTimeout(resetErrorBoundary, 0); 
    return null;
}

function RouteWithErrorBoundary({ element }) {
    const [key, setKey] = useState(0);
    return (
        <ErrorBoundary FallbackComponent={CustomFallback} onReset={() => {setKey((prevKey) => prevKey + 1);}} resetKeys={[key]}>
            {element}
        </ErrorBoundary>
    );
}


// Create router for different pages in app
const router = createHashRouter([
    { path: "/", element: <RouteWithErrorBoundary element={<App />} /> },
    { path: "/pricing", element: <RouteWithErrorBoundary element={<Pricing />} /> },
    { path: "/privacypolicy", element: <RouteWithErrorBoundary element={<PrivacyPolicy />} /> },
    { path: "/FAQ", element: <RouteWithErrorBoundary element={<FAQ />} /> },
    { path: "/contact_sales", element: <RouteWithErrorBoundary element={<ContactSales />} /> },
    { path: "/Error", element: <RouteWithErrorBoundary element={<Error />} /> },  
    { path: "/Posts", element: <RouteWithErrorBoundary element={<Posts />} /> },  
    { path: "/PostDetail", element: <RouteWithErrorBoundary element={<PostDetail />} /> }, 
    { path: "/PostDetail2", element: <RouteWithErrorBoundary element={<PostDetail2 />} /> },   
    { path: "/AuthorPosts", element: <RouteWithErrorBoundary element={<AuthorPosts />} /> },  
    { path: "/CategoryPosts/:category", element: <RouteWithErrorBoundary element={<CategoryPosts />} /> },   
    
    { path: "/payment", element: <RouteWithErrorBoundary element={<Payment />} /> },
    { path: "/payment/success/:session_id", element: <RouteWithErrorBoundary element={<PaymentSuccess />} /> },
    
    { path: "/login", element: <RouteWithErrorBoundary element={<Auth />} /> },
    { path: "/forgot-password", element: <RouteWithErrorBoundary element={<ForgotPassword />} /> }, 
    { path: "/password/reset/confirm/:uid/:token", element: <RouteWithErrorBoundary element={<ResetPassword />} /> },
    { path: "/activate/:uid/:token", element: <RouteWithErrorBoundary element={<Activate />} /> },

    { path: "/main/link", element: <RouteWithErrorBoundary element={<Link />} /> },
    { path: "/main/inbox/:phone_number?", element: <RouteWithErrorBoundary element={<Inbox />} /> },
    { path: "/main/dashboard", element: <RouteWithErrorBoundary element={<Dashboard />} /> },
    { path: "/main/notifications", element: <RouteWithErrorBoundary element={<Notification />}/> },
    { path: "/main/contact", element: <RouteWithErrorBoundary element={<Contacts />} /> },
    { path: "/main/chatflow", element: <RouteWithErrorBoundary element={<ChatFlowList />} /> },
    { path: "/main/streamflow/:chatflow_id/:chatflow_name/:chatflow_type", element: <RouteWithErrorBoundary element={<StreamFlow />} /> },
    { path: "/main/massflow/:chatflow_id/:chatflow_name/:chatflow_type", element: <RouteWithErrorBoundary element={<ChatFlow2 />} /> },
    { path: "/main/todolist", element: <RouteWithErrorBoundary element={<ToDoList />} /> },
    { path: "/main/broadcast", element: <RouteWithErrorBoundary element={<Broadcast />} /> },
    { path: "/main/settings", element: <RouteWithErrorBoundary element={<Settings />} /> },
    { path: "/main/settings/general", element: <RouteWithErrorBoundary element={<General />} /> },
    { path: "/main/settings/quick-response", element: <RouteWithErrorBoundary element={<QuickResponse />} /> },
    { path: "/main/settings/whatsapp-template", element: <RouteWithErrorBoundary element={<WhatsAppTemplate />} /> },
    { path: "/main/settings/plans-and-billing", element: <RouteWithErrorBoundary element={<PlansAndBilling />} /> },
    { path: "/main/settings/password-and-security", element: <RouteWithErrorBoundary element={<PasswordAndSecurity />} /> },
    { path: "/main/settings/chatbot", element: <RouteWithErrorBoundary element={<Chatbot />} /> },
    { path: "/main/settings/notification-account-settings", element: <RouteWithErrorBoundary element={<NotificationAndAccountSettings />} /> },

    { path: "/sub/streamhost-inbox/:user_id/:phone_number/:wabaId", element: <RouteWithErrorBoundary element={<StreamHostInbox/>} /> },
    { path: "/sub/login/:direct_link/:waba_id", element: <RouteWithErrorBoundary element={<SubLogin/>} /> },

    // Catch-all route for 404 errors
    { path: "*", element: <RouteWithErrorBoundary element={<Error />} /> }
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    // Removed React.StrictMode
        <GoogleOAuthProvider clientId="8799379593-mrrjmk5rbq6o6sqhm7pui599cc7iioa7.apps.googleusercontent.com">
            <CookiesProvider>
                <UserProvider>
                    <WebSocketProvider> {/* Wrap RouterProvider with WebSocketProvider */}
                        <RouterProvider router={router} />
                    </WebSocketProvider>
                </UserProvider>
            </CookiesProvider>
        </GoogleOAuthProvider>
);

// Register the service worker for PWA support and push notifications
serviceWorkerRegistration.register();